"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeSelector = void 0;
const initiatives_1 = require("@ui/initiatives");
const GearLinkSection_1 = require("./GearLinkSection");
const filters_1 = require("@periscopes/general/filters");
const _hooks_1 = require("@hooks");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
exports.InitiativeSelector = (0, react_1.memo)(({ onChange, value }) => {
    const { data, request } = (0, _hooks_1.useInitiatives)(true);
    const [open, setOpen] = (0, react_1.useState)();
    const options = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = data.results.map((initiative) => ({
            label: initiative.title,
            value: initiative.id
        }))) !== null && _a !== void 0 ? _a : [];
    }, [data === null || data === void 0 ? void 0 : data.results]);
    const onSearch = (0, react_1.useCallback)((query) => {
        request(Object.assign(Object.assign({}, _hooks_1.useInitiatives.defaultRequest), { title: query }));
    }, [request]);
    const handleOnFinish = (0, react_1.useCallback)((initiative) => {
        setOpen(false);
        setTimeout(() => setOpen(undefined));
        onChange({
            label: initiative.initiativeTitle,
            value: initiative.initiativeId
        });
    }, [onChange]);
    return (react_1.default.createElement(GearLinkSection_1.GearLinkSection, { route: sonar_ts_constants_1.FULL_ROUTES.INITIATIVES },
        react_1.default.createElement(filters_1.OptionFilter, { dropdownRender: (menu) => react_1.default.createElement(react_1.default.Fragment, null,
                menu,
                react_1.default.createElement(initiatives_1.CreateInitiative, { onFinish: handleOnFinish, source: 'card' })), labelInValue: true, onChange: onChange, onSearch: onSearch, open: open, options: options, placeholder: sonar_ts_constants_1.COPY_GENERIC.SELECT(sonar_ts_constants_1.COPY_SPECIFIC.INITIATIVE), value: value })));
});
