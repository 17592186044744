"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTemplateMetadataTab = void 0;
require("./EmailTemplateMetadataTab.scss");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const _hooks_1 = require("@hooks");
const metadata_1 = require("@periscopes/general/metadata");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const EmailTemplateMetadataTab = ({ index, sonarObjectId }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { loading, metadata } = (0, _hooks_1.usePeriscopeMetadata)(sonarObjectId, sonar_ts_types_1.SonarObjectType.SalesforceEmailTemplate);
    const metadataEntries = (0, react_1.useMemo)(() => {
        if (!metadata) {
            return [];
        }
        const entries = [
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.TEMPLATE_TYPE,
                type: 'string',
                value: (0, sonar_ts_lib_1.capitalize)(metadata.templateType)
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.AVAILABLE_FOR_USE,
                type: 'boolean',
                value: metadata.availableForUse
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.FOLDER,
                type: 'string',
                value: metadata.folder
            }
        ];
        return entries;
    }, [metadata]);
    if (loading) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    const subjectCode = (_b = (_a = metadata === null || metadata === void 0 ? void 0 : metadata.subject) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : '';
    const subjectRefs = (_d = (_c = metadata === null || metadata === void 0 ? void 0 : metadata.subject) === null || _c === void 0 ? void 0 : _c.codeReferences) !== null && _d !== void 0 ? _d : [];
    const bodyCode = (_f = (_e = metadata === null || metadata === void 0 ? void 0 : metadata.body) === null || _e === void 0 ? void 0 : _e.code) !== null && _f !== void 0 ? _f : '';
    const bodyRefs = (_h = (_g = metadata === null || metadata === void 0 ? void 0 : metadata.body) === null || _g === void 0 ? void 0 : _g.codeReferences) !== null && _h !== void 0 ? _h : [];
    return (react_1.default.createElement("div", { className: 'email-template-metadata' },
        react_1.default.createElement(metadata_1.MetadataContainer, { entries: metadataEntries, title: sonar_ts_constants_1.COPY_SPECIFIC.EMAIL_TEMPLATE_INFORMATION }),
        react_1.default.createElement("b", null, sonar_ts_constants_1.COPY_SPECIFIC.SUBJECT),
        react_1.default.createElement(general_1.CodeHighlight, { code: subjectCode, codeReferences: subjectRefs, index: index }),
        react_1.default.createElement("b", null, sonar_ts_constants_1.COPY_SPECIFIC.BODY_PLAIN_TEXT_PREVIEW),
        react_1.default.createElement(general_1.CodeHighlight, { code: bodyCode, codeReferences: bodyRefs, index: index })));
};
exports.EmailTemplateMetadataTab = EmailTemplateMetadataTab;
