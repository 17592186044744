"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupAuditTrailGraph = void 0;
require("./SetupAuditTrailGraph.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const hooks_1 = require("@lib/hooks");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const text_1 = require("@ui/text");
const sonar_core_1 = require("@sonar-software/sonar-core");
const antd_1 = require("antd");
const general_1 = require("@ui/general");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const classificationColors = {
    [sonar_core_1.ClassificationLevel.Critical]: '#cf1322',
    [sonar_core_1.ClassificationLevel.High]: '#d46b08',
    [sonar_core_1.ClassificationLevel.Low]: '#722ed1',
    [sonar_core_1.ClassificationLevel.Medium]: '#fadb14',
    [sonar_core_1.ClassificationLevel.None]: '#bfbfbf'
};
const sortedClassifications = [
    sonar_core_1.ClassificationLevel.Critical,
    sonar_core_1.ClassificationLevel.High,
    sonar_core_1.ClassificationLevel.Medium,
    sonar_core_1.ClassificationLevel.Low,
    sonar_core_1.ClassificationLevel.None
];
const getLegendText = (name, value) => `${name === 'null' ? sonar_ts_constants_1.COPY_SPECIFIC.NO_CLASSIFICATION : name} (${value})`;
const defaultParams = {
    start: (0, sonar_ts_lib_1.getPastDate)(7)
};
const useAuditTrailGraphData = (0, hooks_1.createAsyncStateWithPreloadHook)({
    defaultRequest: defaultParams,
    loadAction: sonar_core_1.coreActions.requestGetCoreDataSecuritySetupAuditTrailGraph,
    selector: (state) => state.core.dataSecurity.setupAuditTrail.graph.get,
    useTransform: (result) => result.data
});
const titleStart = `${sonar_ts_constants_1.COPY_SPECIFIC.CHANGES} (`;
const titleEnd = ')';
const SetupAuditTrailGraph = ({ classificationsFilter, dateRange, onClassificationFilterChange }) => {
    var _a;
    const previousRange = (0, _hooks_1.usePrevious)(dateRange, dateRange);
    const [hoverClassification, setHoverClassification] = (0, react_1.useState)(null);
    const data = useAuditTrailGraphData(true, Object.assign(Object.assign({}, defaultParams), dateRange));
    const loadData = (0, _hooks_1.useAction)(useAuditTrailGraphData.loadAction);
    const isLoading = (0, _hooks_1.useSonarSelector)(useAuditTrailGraphData.selectors.isRequested);
    const totalChanges = (_a = data === null || data === void 0 ? void 0 : data.changeCount) !== null && _a !== void 0 ? _a : 0;
    const title = isLoading ?
        react_1.default.createElement("div", { className: 'graph-title' },
            titleStart,
            react_1.default.createElement(general_1.LoadingDots, null),
            titleEnd) :
        `${titleStart}${totalChanges}${titleEnd}`;
    (0, react_1.useEffect)(() => {
        if (dateRange.end !== (previousRange === null || previousRange === void 0 ? void 0 : previousRange.end) || dateRange.start !== (previousRange === null || previousRange === void 0 ? void 0 : previousRange.start)) {
            loadData(dateRange);
        }
    }, [dateRange, loadData, previousRange === null || previousRange === void 0 ? void 0 : previousRange.end, previousRange === null || previousRange === void 0 ? void 0 : previousRange.start]);
    const classificationTotalLookup = (0, react_1.useMemo)(() => {
        if (!data) {
            return {};
        }
        return data.classifications.reduce((prev, next) => {
            prev[next.classification] = next.total;
            return prev;
        }, {});
    }, [data]);
    const handleClick = (classification) => {
        if (classificationsFilter.includes(classification)) {
            onClassificationFilterChange(classificationsFilter.filter((x) => x !== classification));
        }
        else {
            onClassificationFilterChange([...classificationsFilter, classification]);
        }
    };
    const isClassificationActive = (classification) => {
        if (hoverClassification) {
            if (classification === hoverClassification) {
                return true;
            }
            return classificationsFilter.length && classificationsFilter.includes(classification);
        }
        return !classificationsFilter.length || classificationsFilter.includes(classification);
    };
    return (react_1.default.createElement(general_1.SonarCard, { className: 'setup-audit-trail-graph', loading: isLoading, title: title, titleClassName: 'graph-title' },
        react_1.default.createElement(antd_1.Flex, { gap: 16, vertical: true },
            react_1.default.createElement(antd_1.Flex, { align: 'center' }, sortedClassifications.map((classification) => {
                const total = classificationTotalLookup[classification];
                if (!total) {
                    return null;
                }
                return (react_1.default.createElement(antd_1.Tag, { className: 'graph-segment', color: classificationColors[classification], key: classification, onClick: () => handleClick(classification), onMouseEnter: () => setHoverClassification(classification), onMouseLeave: () => setHoverClassification(null), style: {
                        opacity: isClassificationActive(classification) ? 1 : 0.4,
                        width: `${totalChanges ? (total / totalChanges) * 100 : 0}%`
                    } }));
            })),
            react_1.default.createElement(antd_1.Flex, { align: 'center', className: 'graph-legend', gap: 16 }, sortedClassifications.map((classification) => {
                const total = classificationTotalLookup[classification];
                return (react_1.default.createElement(antd_1.Flex, { align: 'center', key: classification, style: { opacity: isClassificationActive(classification) ? 1 : 0.4 } },
                    react_1.default.createElement(antd_1.Tag, { color: classificationColors[classification] }),
                    react_1.default.createElement(text_1.SonarText, null, getLegendText(classification, total !== null && total !== void 0 ? total : 0))));
            })))));
};
exports.SetupAuditTrailGraph = SetupAuditTrailGraph;
