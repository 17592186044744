"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldScopeHeader = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./FieldScopeHeader.scss");
const tags_1 = require("@ui/tags");
const antd_1 = require("antd");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const records_1 = require("@ui/records");
const _hooks_1 = require("@hooks");
const FieldScopeHeader = ({ data, index }) => {
    const { isActive, relatedCounts, sonarObjectId, versionNumber } = data;
    const { linkedIntegrations, internalName, parentDisplayName, parentInternalName, parentSonarObjectId, rawSalesforceType } = (0, _hooks_1.useSonarObjectReference)(sonarObjectId);
    const { openPeriscope, updatePeriscope } = (0, _hooks_1.usePeriscopeActions)();
    (0, react_1.useEffect)(() => {
        var _a, _b;
        updatePeriscope({
            periscopeIndex: index,
            subType: (_b = (_a = data.type) !== null && _a !== void 0 ? _a : rawSalesforceType) !== null && _b !== void 0 ? _b : ''
        });
    }, [updatePeriscope, data.type, rawSalesforceType, index]);
    const handleParentClick = (0, react_1.useCallback)(() => {
        openPeriscope({
            id: parentSonarObjectId !== null && parentSonarObjectId !== void 0 ? parentSonarObjectId : 0,
            name: parentDisplayName !== null && parentDisplayName !== void 0 ? parentDisplayName : '',
            openLeft: true,
            openedFromIndex: index,
            type: sonar_ts_types_1.SonarObjectType.SalesforceEntity
        });
    }, [openPeriscope, parentSonarObjectId, parentDisplayName, index]);
    return (react_1.default.createElement("div", { className: 'field-scope-header' },
        react_1.default.createElement(antd_1.Space, { size: Number(css.size8) },
            react_1.default.createElement(records_1.SonarRecordNameSection, { isActive: isActive, name: internalName !== null && internalName !== void 0 ? internalName : '', onClickParent: handleParentClick, parentName: parentInternalName !== null && parentInternalName !== void 0 ? parentInternalName : '', removeBold: true, showArrow: true, versionNumber: versionNumber }),
            react_1.default.createElement(records_1.SonarRecordRelationships, { relatedOrgs: linkedIntegrations })),
        react_1.default.createElement(antd_1.Space, { rootClassName: 'record-references', size: Number(css.size4) },
            react_1.default.createElement(records_1.SonarRecordReferences, { parentName: parentDisplayName !== null && parentDisplayName !== void 0 ? parentDisplayName : '', relatedCounts: relatedCounts, sonarObjectId: sonarObjectId }),
            react_1.default.createElement(tags_1.SonarTagManager, { sonarObjectId: sonarObjectId }))));
};
exports.FieldScopeHeader = FieldScopeHeader;
