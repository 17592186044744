"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OidcProviderSetup = void 0;
require("./OidcProviderSetup.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const notifications_1 = require("@ui/notifications");
const selectors_1 = require("@lib/selectors");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const oidc_providers_1 = require("./oidc-providers");
const sonar_security_1 = require("@sonar-software/sonar-security");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getOidcConfigurationSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.oidcConfigurations.byCustomer.byId.get);
const deleteOidcConfigurationSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.oidcConfigurations.byCustomer.byId.delete);
const updateOidcConfigurationSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.oidcConfigurations.byCustomer.byId.post);
const OidcProviderSetup = (props) => {
    const { customerId, id, shardName, type = 'none' } = props;
    const [isErrored, setIsErrored] = (0, react_1.useState)(false);
    const oidcConfiguration = (0, _hooks_1.useSonarSelector)(getOidcConfigurationSelectors.data);
    const oidcConfigurationIsRequested = (0, _hooks_1.useSonarSelector)(getOidcConfigurationSelectors.isRequested);
    const getOidcConfiguration = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestGetSecurityOidcConfigurationsByCustomerById);
    const oidcConfigurationIsDeleting = (0, _hooks_1.useSonarSelector)(deleteOidcConfigurationSelectors.isRequested);
    const oidcConfigurationIsDeleted = (0, _hooks_1.useSonarSelector)(deleteOidcConfigurationSelectors.isReceived);
    const deleteOidcConfiguration = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestDeleteAdminOidcConfigurationsByCustomerById);
    const oidcConfigurationIsUpdating = (0, _hooks_1.useSonarSelector)(updateOidcConfigurationSelectors.isRequested);
    const oidcConfigurationIsUpdated = (0, _hooks_1.useSonarSelector)(updateOidcConfigurationSelectors.isReceived);
    const oidcConfigurationUpdateIsErrored = (0, _hooks_1.useSonarSelector)(updateOidcConfigurationSelectors.isErrored);
    const oidcConfigurationUpdateIsFailed = (0, _hooks_1.useSonarSelector)(updateOidcConfigurationSelectors.isFailed);
    const updateOidcConfiguration = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestPostAdminOidcConfigurationsByCustomerById);
    const isLoading = oidcConfigurationIsRequested || oidcConfigurationIsDeleting || oidcConfigurationIsUpdating;
    const [displayedProvider, setDisplayedProvider] = (0, react_1.useState)(type);
    const [isEnteringNewData, setIsEnteringNewData] = (0, react_1.useState)(false);
    const handleProviderSelected = (0, react_1.useCallback)((provider) => {
        setDisplayedProvider(provider);
        setIsEnteringNewData(true);
    }, [setDisplayedProvider]);
    const handleProviderCleared = (0, react_1.useCallback)(() => {
        setDisplayedProvider('none');
        setIsEnteringNewData(false);
    }, [setDisplayedProvider, setIsEnteringNewData]);
    const handleProviderDeleted = (0, react_1.useCallback)(() => {
        deleteOidcConfiguration({
            customerId,
            shardName
        });
    }, [deleteOidcConfiguration, customerId, shardName]);
    const handleProviderUpdated = (0, react_1.useCallback)((configuration) => {
        updateOidcConfiguration({
            configuration,
            customerId,
            shardName
        });
    }, [updateOidcConfiguration, customerId, shardName]);
    const refreshOidcConfiguration = (0, react_1.useCallback)(() => {
        if (customerId) {
            getOidcConfiguration({
                customerId: customerId,
                shardName: shardName
            });
        }
    }, [getOidcConfiguration, customerId, shardName]);
    (0, react_1.useEffect)(() => {
        refreshOidcConfiguration();
    }, [refreshOidcConfiguration]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (oidcConfiguration) {
            setIsEnteringNewData(false);
            setIsErrored(false);
            setDisplayedProvider((_a = oidcConfiguration === null || oidcConfiguration === void 0 ? void 0 : oidcConfiguration.provider) !== null && _a !== void 0 ? _a : 'none');
        }
        else {
            setIsEnteringNewData(true);
            setIsErrored(false);
            setDisplayedProvider('none');
        }
    }, [oidcConfiguration]);
    (0, react_1.useEffect)(() => {
        if (oidcConfigurationIsDeleted || oidcConfigurationIsUpdated) {
            setIsEnteringNewData(false);
            setIsErrored(false);
            refreshOidcConfiguration();
        }
    }, [oidcConfigurationIsDeleted, oidcConfigurationIsUpdated, refreshOidcConfiguration, customerId, shardName]);
    (0, react_1.useEffect)(() => {
        if (oidcConfigurationUpdateIsErrored || oidcConfigurationUpdateIsFailed) {
            setIsErrored(true);
        }
    }, [setIsErrored, oidcConfigurationUpdateIsErrored, oidcConfigurationUpdateIsFailed]);
    return (react_1.default.createElement("div", { className: 'oidc-provider-setup', id: id },
        react_1.default.createElement("div", { className: 'h2' }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.OIDC_CONFIGURATON),
        react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: isLoading },
            displayedProvider === 'none' &&
                react_1.default.createElement(oidc_providers_1.OidcProviderSetupSelector, { onProviderSelected: handleProviderSelected }),
            displayedProvider === 'okta' &&
                react_1.default.createElement(oidc_providers_1.OktaOidcProviderSetup, { isEnteringNewData: isEnteringNewData, isErrored: isErrored, oidcConfiguration: oidcConfiguration, onCleared: handleProviderCleared, onDeleted: handleProviderDeleted, onUpdated: handleProviderUpdated }),
            displayedProvider === 'azure' &&
                react_1.default.createElement(oidc_providers_1.AzureOidcProviderSetup, { isEnteringNewData: isEnteringNewData, isErrored: isErrored, oidcConfiguration: oidcConfiguration, onCleared: handleProviderCleared, onDeleted: handleProviderDeleted, onUpdated: handleProviderUpdated }),
            displayedProvider === 'google' &&
                react_1.default.createElement(oidc_providers_1.GoogleOidcProviderSetup, { isEnteringNewData: isEnteringNewData, isErrored: isErrored, oidcConfiguration: oidcConfiguration, onCleared: handleProviderCleared, onDeleted: handleProviderDeleted, onUpdated: handleProviderUpdated }),
            displayedProvider === 'onelogin' &&
                react_1.default.createElement(oidc_providers_1.OneLoginOidcProviderSetup, { isEnteringNewData: isEnteringNewData, isErrored: isErrored, oidcConfiguration: oidcConfiguration, onCleared: handleProviderCleared, onDeleted: handleProviderDeleted, onUpdated: handleProviderUpdated })),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.UPDATE_OIDC_CONFIGURATION_ERRORED, failed: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.UPDATE_OIDC_CONFIGURATION_FAILED, stateSelector: updateOidcConfigurationSelectors.state }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.DELETE_OIDC_CONFIG_ERRORED, failed: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.DELETE_OIDC_CONFIG_FAILED, stateSelector: deleteOidcConfigurationSelectors.state })));
};
exports.OidcProviderSetup = OidcProviderSetup;
