"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateDataSecurityApp = useUpdateDataSecurityApp;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useAsyncState_1 = require("../useAsyncState");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.applications.byId.patch);
function useUpdateDataSecurityApp() {
    const { isIdle, isReceived, isRequested } = (0, useAsyncState_1.useAsyncState)(selectors);
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPatchCoreDataSecurityApplicationsById);
    const reset = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetPatchCoreDataSecurityApplicationsById);
    return {
        isIdle,
        isReceived,
        isRequested,
        request,
        reset
    };
}
useUpdateDataSecurityApp.selectors = selectors;
