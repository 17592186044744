"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessStatistics = exports.getDataSecurityAccessStatisticsSelector = void 0;
require("./AccessStatistics.scss");
const AccessStatisticsLegend_1 = require("./AccessStatisticsLegend");
const AccessStatisticsRow_1 = require("./AccessStatisticsRow");
const AccessStatisticsSection_1 = require("./AccessStatisticsSection");
const ApplicationAccessStat_1 = require("./ApplicationAccessStat");
const selectors_1 = require("@lib/selectors");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const features_1 = require("@ui/features");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_if_1 = require("react-if");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getMaxRequestCount = (items) => { var _a; return (_a = items === null || items === void 0 ? void 0 : items.reduce((acc, cur) => Math.max(acc, cur.requestCount), 0)) !== null && _a !== void 0 ? _a : 0; };
exports.getDataSecurityAccessStatisticsSelector = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.accessStatistics.get);
const AccessStatistics = ({ applicationId, viewParams }) => {
    var _a, _b, _c;
    const previousDateRange = (0, _hooks_1.usePrevious)(viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange);
    const previousAppGroup = (0, _hooks_1.usePrevious)(viewParams === null || viewParams === void 0 ? void 0 : viewParams.appGroup);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(exports.getDataSecurityAccessStatisticsSelector);
    const applicationColors = (0, _hooks_1.useDataSecurityAppColors)((_a = data === null || data === void 0 ? void 0 : data.applications) === null || _a === void 0 ? void 0 : _a.map((app) => app.applicationId));
    const getDataSecurityAccessStatistics = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityAccessStatistics);
    const hasFieldClassifications = (0, react_1.useMemo)(() => !(data === null || data === void 0 ? void 0 : data.fields.every((field) => field.dataSensitivityLevelId === sonar_core_1.DataSensitivityLevelId.None)), [data === null || data === void 0 ? void 0 : data.fields]);
    const classifications = (0, react_1.useMemo)(() => data === null || data === void 0 ? void 0 : data.fields.reduce((counts, field) => (Object.assign(Object.assign({}, counts), { [field.dataSensitivityLevelId]: true })), {}), [data === null || data === void 0 ? void 0 : data.fields]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (!(viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange.start) ||
            (viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange.end) !== (previousDateRange === null || previousDateRange === void 0 ? void 0 : previousDateRange.end) ||
            (viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange.start) !== (previousDateRange === null || previousDateRange === void 0 ? void 0 : previousDateRange.start) ||
            (!applicationId && (viewParams === null || viewParams === void 0 ? void 0 : viewParams.appGroup) !== previousAppGroup)) {
            getDataSecurityAccessStatistics({
                applicationId,
                end: viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange.end,
                stakeholderGroupId: viewParams === null || viewParams === void 0 ? void 0 : viewParams.appGroup,
                start: (_a = viewParams === null || viewParams === void 0 ? void 0 : viewParams.dateRange.start) !== null && _a !== void 0 ? _a : (0, sonar_ts_lib_1.getPastDate)(7)
            });
        }
    }, [applicationId, viewParams, getDataSecurityAccessStatistics, previousDateRange, previousAppGroup]);
    return (react_1.default.createElement(features_1.FeatureBoundary, { feature: sonar_core_1.FeatureFlag.DataSecurityDashboard },
        react_1.default.createElement(general_1.SonarCard, { className: 'access-statistics', loading: isRequested, title: sonar_ts_constants_1.DATA_SECURITY_DASHBOARD.ACCESS_STATISTICS, titleClassName: 'h2', useSpinnerLoading: true },
            react_1.default.createElement("div", { className: 'access-statistics-table' },
                react_1.default.createElement(AccessStatisticsSection_1.AccessStatisticsSection, { isEmpty: !(data === null || data === void 0 ? void 0 : data.fields.length), section: 'fields' }, data === null || data === void 0 ? void 0 :
                    data.fields.map((field) => react_1.default.createElement(AccessStatisticsRow_1.AccessStatisticsRow, { colorName: hasFieldClassifications ?
                            sonar_core_1.DATA_SENSITIVITY_COLORS[field.dataSensitivityLevelId] : 'blue', count: field.requestCount, id: field.sonarObjectId, key: field.sonarObjectId, max: getMaxRequestCount(data.fields), name: field.fieldName, type: sonar_ts_types_1.SonarObjectType.SalesforceField })),
                    react_1.default.createElement(react_if_1.When, { condition: hasFieldClassifications },
                        react_1.default.createElement(AccessStatisticsLegend_1.AccessStatisticsLegend, { classifications: classifications }))),
                react_1.default.createElement(AccessStatisticsSection_1.AccessStatisticsSection, { isEmpty: !(data === null || data === void 0 ? void 0 : data.entities.length), section: 'entities' }, data === null || data === void 0 ? void 0 : data.entities.map((entity) => react_1.default.createElement(AccessStatisticsRow_1.AccessStatisticsRow, { colorName: 'blue', count: entity.requestCount, id: entity.sonarObjectId, key: entity.sonarObjectId, max: getMaxRequestCount(data.entities), name: entity.entityName, type: sonar_ts_types_1.SonarObjectType.SalesforceEntity }))),
                react_1.default.createElement(AccessStatisticsSection_1.AccessStatisticsSection, { hide: Boolean(applicationId), isEmpty: !((_b = data === null || data === void 0 ? void 0 : data.applications) === null || _b === void 0 ? void 0 : _b.length), section: 'applications' }, (_c = data === null || data === void 0 ? void 0 : data.applications) === null || _c === void 0 ? void 0 : _c.map((application) => {
                    var _a;
                    return react_1.default.createElement(ApplicationAccessStat_1.ApplicationAccessStat, { color: (_a = applicationColors[application.applicationId]) !== null && _a !== void 0 ? _a : '', count: application.requestCount, id: application.applicationId, key: application.applicationId, max: getMaxRequestCount(data === null || data === void 0 ? void 0 : data.applications) });
                }))))));
};
exports.AccessStatistics = AccessStatistics;
