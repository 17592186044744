"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppStatusTable = void 0;
require("./AppStatusTable.scss");
const AppStatusMessage_1 = require("./AppStatusMessage");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const AppStatusTableColumns_1 = require("./AppStatusTableColumns");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const sonar_core_1 = require("@sonar-software/sonar-core");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getRowKey = (result) => `${result.applicationName}-${result.applicationLabel}`;
const dataSecurityAppStatusSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.appStaus.get);
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: { applicationId: true }
});
const AppStatusTable = ({ applicationId }) => {
    var _a, _b;
    const getAppStatus = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityAppStatus);
    const [firstLoad, setFirstLoad] = (0, react_1.useState)(true);
    const previousApplicationId = (0, _hooks_1.usePrevious)(applicationId);
    const applicationIdChanged = applicationId !== previousApplicationId;
    const isEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataSecurityApplicationStatus);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(dataSecurityAppStatusSelectors);
    const statusLink = Number.isInteger(applicationId) ? (_a = data === null || data === void 0 ? void 0 : data.results[0]) === null || _a === void 0 ? void 0 : _a.statusPageUrl : undefined;
    const statusMessage = Number.isInteger(applicationId) ? (_b = data === null || data === void 0 ? void 0 : data.results[0]) === null || _b === void 0 ? void 0 : _b.errorMessage : undefined;
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            applicationId,
            count: 10
        },
        onUpdate: () => {
            getAppStatus(convertToDql(queryParams));
        }
    });
    (0, _hooks_1.useOnMount)(() => {
        setFirstLoad(false);
        getAppStatus(convertToDql(queryParams));
    });
    const columns = (0, react_1.useMemo)(() => (0, AppStatusTableColumns_1.getColumns)(!Number.isInteger(applicationId)), [applicationId]);
    if (!isEnabled) {
        return null;
    }
    return (react_1.default.createElement(general_1.SonarCard, { className: 'app-status-table', loading: firstLoad || applicationIdChanged, noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.APPLICATION_STATUS, titleClassName: 'h2', useSpinnerLoading: true },
        react_1.default.createElement("div", { className: 'table-container' },
            react_1.default.createElement(PaginatedTable_1.PaginatedTable, { autohidePagination: true, columns: columns, defaultPageSize: 10, id: 'app-status-table', loading: isRequested, onChange: updateParams, resource: data, rowKey: getRowKey, showSizeChanger: false, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection })),
        react_1.default.createElement(AppStatusMessage_1.AppStatusMessage, { link: statusLink, message: statusMessage })));
};
exports.AppStatusTable = AppStatusTable;
