"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedDataDictionaryTable = void 0;
require("./DataDictionaryTable.scss");
require("./SharedDataDictionaryTable.scss");
const tables_1 = require("@ui/tables");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const general_1 = require("@ui/general");
const text_1 = require("@ui/text");
const SortedBy_1 = require("@ui/table-modifier/SortedBy");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const react_1 = __importStar(require("react"));
const rowKey = (entry) => entry.sonarObjectId;
const SharedDataDictionaryTable = ({ loading, resource, updateQuery }) => {
    const { schema, sortOptions } = (0, react_1.useMemo)(() => {
        var _a;
        const results = (0, lib_1.parseMetadataFromResponse)(resource === null || resource === void 0 ? void 0 : resource.schema, () => true, atob((_a = resource === null || resource === void 0 ? void 0 : resource.appliedColumns) !== null && _a !== void 0 ? _a : ''));
        return {
            schema: results.schema,
            sortOptions: results.sortOptions
        };
    }, [resource]);
    const sortQueryParams = (0, react_1.useMemo)(() => {
        var _a;
        return (0, lib_1.translateFilterAndOrderingToQueryParams)([], sortOptions, '', (0, lib_1.getAppliedOrdering)((_a = resource === null || resource === void 0 ? void 0 : resource.appliedOrderingAndFilters) !== null && _a !== void 0 ? _a : ''));
    }, [resource === null || resource === void 0 ? void 0 : resource.appliedOrderingAndFilters, sortOptions]);
    const header = resource ? `${resource.organizationName} - ${resource.viewName}` : '';
    const handleSortUpdate = (0, react_1.useCallback)((update) => {
        updateQuery({
            key: '',
            ordering: (0, lib_1.translateOrderQueryParamsToDynamicQueryLanguage)(update),
            paginationDirection: sonar_ts_types_1.PaginationDirection.Forward
        });
    }, [updateQuery]);
    const handleFilterByName = (0, react_1.useCallback)((name) => {
        updateQuery({
            filterByName: name
        });
    }, [updateQuery]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(antd_1.Space, { className: 'shared-view-header' },
            react_1.default.createElement(text_1.SonarText, { size: 16, weight: 'semibold' }, header),
            resource &&
                react_1.default.createElement(SortedBy_1.SortedBySelect, { defaultValue: sortQueryParams.sortDefault || sortOptions[0], onChange: handleSortUpdate, queryParams: sortQueryParams, sortOptions: sortOptions })),
        react_1.default.createElement(general_1.SearchInput, { className: 'filter-by-name', id: 'filterByName', onChange: handleFilterByName, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(sonar_ts_constants_1.COPY_SPECIFIC.NAME) }),
        react_1.default.createElement(tables_1.PaginatedTable, { className: 'data-dictionary-table shared-data-dictionary-table no-click', columns: schema, defaultPageSize: 10, loading: loading, onChange: updateQuery, resource: resource, rowKey: rowKey, scroll: {
                scrollToFirstRowOnChange: true,
                x: (resource === null || resource === void 0 ? void 0 : resource.results.length) ? 'max-content' : 4000
            } })));
};
exports.SharedDataDictionaryTable = SharedDataDictionaryTable;
