"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApexAutomationList = void 0;
const ApexAutomationSearch_1 = require("./ApexAutomationSearch");
const _1 = require(".");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const ApexAutomationList = ({ parentIndex, uri }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { loading, resource, updateParams } = (0, _hooks_1.useQueryResource)({ uri });
    const renderList = (0, react_1.useCallback)((items) => items === null || items === void 0 ? void 0 : items.map((item) => {
        const { displayName, sonarObjectId, sonarObjectType } = item;
        const handleClick = () => {
            openPeriscope({
                id: sonarObjectId,
                name: displayName,
                openedFromIndex: parentIndex,
                type: sonarObjectType
            });
        };
        return (react_1.default.createElement(_1.PeriscopeApexClass, { apex: item, key: sonarObjectId, onApexClick: handleClick, sonarObjectType: sonarObjectType }));
    }), [openPeriscope, parentIndex]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ApexAutomationSearch_1.ApexAutomationSearch, { onChange: updateParams }),
        react_1.default.createElement(__1.PeriscopeList, { list: renderList, loading: loading, results: resource !== null && resource !== void 0 ? resource : [] })));
};
exports.ApexAutomationList = ApexAutomationList;
