"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateTask = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const general_1 = require("@ui/general");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const CreateTask = ({ initiativeId, onFinish }) => {
    const { isProcessing, isSuccessful, request, reset, result } = (0, _hooks_1.useCreateInitiativeTask)(initiativeId);
    const [taskName, setTaskName] = (0, react_1.useState)('');
    const onFinishRef = (0, react_1.useRef)(onFinish);
    onFinishRef.current = onFinish;
    const handleOnSubmit = (0, react_1.useCallback)((name) => {
        setTaskName(name);
        request({ name });
    }, [request]);
    (0, react_1.useEffect)(() => {
        if (isSuccessful && result) {
            onFinishRef.current(result.initiativeTaskId, taskName);
            reset();
        }
    }, [isSuccessful, reset, result, taskName]);
    return react_1.default.createElement(general_1.QuickAdd, { onSubmit: handleOnSubmit, processing: isProcessing, typeLabel: sonar_ts_constants_1.COPY_SPECIFIC.TASK });
};
exports.CreateTask = CreateTask;
