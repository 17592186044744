"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeV3PaginatedList = PeriscopeV3PaginatedList;
require("./PeriscopeV3PaginatedList.scss");
const KeyPagination_1 = require("@ui/KeyPagination");
const _1 = require(".");
const records_1 = require("@ui/records");
const react_1 = __importStar(require("react"));
function PeriscopeV3PaginatedList({ loading, onChange, queryParams, renderListItem, resource }) {
    var _a, _b;
    const scrollableListRef = (0, react_1.useRef)(null);
    const renderList = (0, react_1.useCallback)((items) => items.map((item, childIndex) => {
        if (renderListItem) {
            return renderListItem(item, childIndex);
        }
        const castItem = item;
        return (react_1.default.createElement(records_1.SonarRecord, { key: castItem.sonarObjectId, name: castItem.displayName, sonarObjectId: castItem.sonarObjectId }));
    }), [renderListItem]);
    const results = (_a = resource === null || resource === void 0 ? void 0 : resource.results) !== null && _a !== void 0 ? _a : [];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(_1.PeriscopeList, { list: renderList, loading: loading, ref: scrollableListRef, results: results }),
        react_1.default.createElement(KeyPagination_1.KeyPagination, { defaultPageSize: (_b = queryParams.count) !== null && _b !== void 0 ? _b : 20, listRef: scrollableListRef, onChange: onChange, resource: resource })));
}
