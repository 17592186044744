"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeferredRender = void 0;
const _hooks_1 = require("@hooks");
const react_1 = require("react");
const DeferredRender = ({ children, delay }) => {
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    (0, _hooks_1.useOnMount)(() => {
        setTimeout(() => setIsReady(true), delay);
    });
    return isReady ? children : null;
};
exports.DeferredRender = DeferredRender;
