"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceUserFilter = void 0;
const FilterComponent_1 = require("./FilterComponent");
const popups_1 = require("@lib/popups");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const filters_1 = require("@periscopes/general/filters");
const _hooks_1 = require("@hooks");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const SalesforceUserFilter = (props) => {
    const { format, id, label, onChange, value } = props;
    const users = (0, _hooks_1.useSalesforceUsers)();
    const options = (0, react_1.useMemo)(() => {
        const userMap = users === null || users === void 0 ? void 0 : users.map((user) => ({
            label: user.name,
            value: user.username
        }));
        userMap === null || userMap === void 0 ? void 0 : userMap.unshift({
            label: sonar_ts_constants_1.COPY_SPECIFIC.NO_LABELED(label),
            value: 'null'
        });
        return userMap !== null && userMap !== void 0 ? userMap : [];
    }, [label, users]);
    return (react_1.default.createElement(FilterComponent_1.FilterComponent, Object.assign({}, props, { tagProps: {
            value: options
                .filter((option) => value === null || value === void 0 ? void 0 : value.includes(option.value))
                .map((option) => option.label)
                .join(', ')
        } }),
        react_1.default.createElement(filters_1.OptionFilter, { className: 'filter', getPopupContainer: popups_1.getDirectParent, id: (0, lib_1.getFilterId)(id), loading: !options, maxTagCount: 'responsive', mode: 'multiple', onChange: (usernames) => {
                onChange({
                    [id]: {
                        format,
                        value: usernames
                    }
                });
            }, options: options !== null && options !== void 0 ? options : [], placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(label), showArrow: true, value: value })));
};
exports.SalesforceUserFilter = SalesforceUserFilter;
