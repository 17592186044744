"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSecurityAppName = void 0;
var DataSecurityAppName;
(function (DataSecurityAppName) {
    DataSecurityAppName["ActOn"] = "ActOn";
    DataSecurityAppName["AddressTools"] = "AddressTools";
    DataSecurityAppName["AdobeSign"] = "Adobe Sign";
    DataSecurityAppName["Adroll"] = "Adroll";
    DataSecurityAppName["AdminAgentsAI"] = "Admin Agents AI";
    DataSecurityAppName["AdWords"] = "AdWords Conversion Import";
    DataSecurityAppName["Aeon2"] = "Aeon2";
    DataSecurityAppName["AffectLayer"] = "AffectLayer oAuth";
    DataSecurityAppName["AgathaAnswers"] = "Agatha Answers";
    DataSecurityAppName["AgileVizArt"] = "AgileVizArt";
    DataSecurityAppName["AgileVizArtChatter"] = "AgileVizArt Chatter";
    DataSecurityAppName["Aha"] = "Aha! Integration Oauth";
    DataSecurityAppName["AIPlatformAuth"] = "AI Platform Auth";
    DataSecurityAppName["Airbyte"] = "Airbyte";
    DataSecurityAppName["Airfocus"] = "Airfocus";
    DataSecurityAppName["Airtable"] = "Airtable";
    DataSecurityAppName["AlienVault"] = "AlienVault";
    DataSecurityAppName["Allbound"] = "Allbound";
    DataSecurityAppName["Alpharun"] = "Alpharun";
    DataSecurityAppName["Altify"] = "Altify";
    DataSecurityAppName["Alyce"] = "Alyce Connected App";
    DataSecurityAppName["AmazonAppFlow"] = "Amazon AppFlow";
    DataSecurityAppName["AmazonAppFlowEmbedded"] = "Amazon AppFlow Embedded Login App";
    DataSecurityAppName["AmazonConnectProd"] = "amazonconnectprod Connected App for RestAPI OAuth";
    DataSecurityAppName["AmazonGlue"] = "Amazon Glue";
    DataSecurityAppName["Ambassador"] = "Ambassador";
    DataSecurityAppName["Ambition"] = "Ambition";
    DataSecurityAppName["Amplemarket"] = "Amplemarket";
    DataSecurityAppName["AnalyticsDataImport"] = "Analytics Data Import";
    DataSecurityAppName["AnalyticsForiOS"] = "Analytics for iOS";
    DataSecurityAppName["AnecdotesAI"] = "Anecdotes_ai";
    DataSecurityAppName["AnecdotesIntegration"] = "Anecdotes Integration";
    DataSecurityAppName["ApexGuru"] = "apexguru_c2c_Production";
    DataSecurityAppName["Apollo"] = "Apollo";
    DataSecurityAppName["Appcues"] = "Appcues";
    DataSecurityAppName["AppExchange"] = "AppExchange";
    DataSecurityAppName["AppExchangePublishing"] = "AppExchange Publishing";
    DataSecurityAppName["AppFrontier"] = "AppFrontier";
    DataSecurityAppName["AppOmni"] = "AppOmni";
    DataSecurityAppName["AppXAPI"] = "Appx_api";
    DataSecurityAppName["Aptrinsic"] = "Aptrinsic";
    DataSecurityAppName["ArcticWolf"] = "Arctic Wolf";
    DataSecurityAppName["Armatic"] = "Armatic";
    DataSecurityAppName["ArticleQuality"] = "Article Quality";
    DataSecurityAppName["AscendixSearch"] = "Ascendix Search";
    DataSecurityAppName["AskAI"] = "Ask.AI";
    DataSecurityAppName["AskNicely"] = "AskNicely";
    DataSecurityAppName["AtlanConnector"] = "Atlan Connector";
    DataSecurityAppName["AtlanDataManagement"] = "Atlan Data Management";
    DataSecurityAppName["AtlassianLinks"] = "Atlassian Links";
    DataSecurityAppName["Atrium"] = "Atrium";
    DataSecurityAppName["Auction"] = "Auction";
    DataSecurityAppName["Avature"] = "Avature";
    DataSecurityAppName["Aviator"] = "Aviator/DA";
    DataSecurityAppName["AwesomeTableConnector"] = "Awesome Table Connector";
    DataSecurityAppName["AWS"] = "AWS";
    DataSecurityAppName["AzureDataFactory"] = "Azure DataFactory";
    DataSecurityAppName["AzureLogicApps"] = "Azure Logic Apps";
    DataSecurityAppName["AzurePurview"] = "Azure Purview";
    DataSecurityAppName["BackupAndRestore"] = "Backup_and_Restore";
    DataSecurityAppName["Base"] = "Base";
    DataSecurityAppName["BenefitFlow"] = "BenefitFlow";
    DataSecurityAppName["BenefitFlowIntegration"] = "BenefitFlow Integration";
    DataSecurityAppName["Berry"] = "Berry";
    DataSecurityAppName["Beynd"] = "Beynd";
    DataSecurityAppName["BIUser"] = "BI User";
    DataSecurityAppName["Bizible"] = "Bizible";
    DataSecurityAppName["BizzaboEventManagement"] = "bizzabo";
    DataSecurityAppName["Blackthorn"] = "Blackthorn | Connected App";
    DataSecurityAppName["Blissfully"] = "Blissfully";
    DataSecurityAppName["Bluebirds"] = "Bluebirds";
    DataSecurityAppName["BlueCanvas"] = "tools.bluecanvase.io";
    DataSecurityAppName["BlueCanvasCPQ"] = "cpq.bluecanvas.io";
    DataSecurityAppName["Board"] = "Board";
    DataSecurityAppName["Bombora"] = "Bombora";
    DataSecurityAppName["Boomi"] = "Boomi";
    DataSecurityAppName["BoomiSlash"] = "Boomi/";
    DataSecurityAppName["Brainshark"] = "Brainshark";
    DataSecurityAppName["BriefingSource"] = "BriefingSource";
    DataSecurityAppName["Brightback"] = "Brightback";
    DataSecurityAppName["BrightFunnel"] = "BrightFunnel";
    DataSecurityAppName["Browser"] = "Browser";
    DataSecurityAppName["BulkObjectCreator"] = "Bulk Object Creator";
    DataSecurityAppName["Cabal"] = "Cabal";
    DataSecurityAppName["Calendly"] = "Calendly";
    DataSecurityAppName["CallRail"] = "CallRail Salesforce Integration";
    DataSecurityAppName["Capchase"] = "Capchase Integration";
    DataSecurityAppName["CaptivateIQ"] = "CaptivateIQ";
    DataSecurityAppName["CarrierDownloads"] = "Carrier Downloads";
    DataSecurityAppName["Catalyst"] = "Catalyst";
    DataSecurityAppName["CCCGamification"] = "CCC Gamification";
    DataSecurityAppName["CDataConnector"] = "CData Connector";
    DataSecurityAppName["Celigo"] = "Celigo";
    DataSecurityAppName["Celigo2"] = "Celigo 2";
    DataSecurityAppName["CeligoNetsuite"] = "Celigo Netsuite";
    DataSecurityAppName["CeligoNetSuitePackage"] = "Celigo NetSuite Package Integration App Production";
    DataSecurityAppName["Cellarstone"] = "Cellarstone/";
    DataSecurityAppName["Celoxis"] = "Celoxis";
    DataSecurityAppName["CEMDigitalExperience"] = "CEM-DE";
    DataSecurityAppName["Census"] = "Census";
    DataSecurityAppName["Certain"] = "Certain";
    DataSecurityAppName["Chameleon"] = "Chameleon";
    DataSecurityAppName["ChameleonIO"] = "chameleon.io";
    DataSecurityAppName["Channeled"] = "Channeled";
    DataSecurityAppName["ChargeBee"] = "ChargeBee for salesforce";
    DataSecurityAppName["Chatbots"] = "Chatbots";
    DataSecurityAppName["Checkout"] = "Checkout";
    DataSecurityAppName["ChiliPiper"] = "Chili Piper";
    DataSecurityAppName["ChiliPiperSalesAcceleration"] = "Chili Piper - Sales Acceleration 3.0";
    DataSecurityAppName["ChurnZero"] = "Churn Zero";
    DataSecurityAppName["ChurnZero2"] = "ChurnZero";
    DataSecurityAppName["ChurnZeroApp"] = "ChurnZeroApp";
    DataSecurityAppName["ChurnZeroConnectedApp"] = "ChurnZeroConnectedApp_SonarSoftware";
    DataSecurityAppName["CirrusInsight"] = "Cirrus Insight";
    DataSecurityAppName["CirrusInsightServices"] = "Cirrus Insight - Services";
    DataSecurityAppName["Clari"] = "Clari";
    DataSecurityAppName["ClariCopilot"] = "Clari Copilot";
    DataSecurityAppName["ClariWebProd"] = "Clari Web Prod";
    DataSecurityAppName["Clarius"] = "Clarius App";
    DataSecurityAppName["Clay"] = "Clay";
    DataSecurityAppName["Clearbit"] = "Clearbit";
    DataSecurityAppName["ClearbitSalesforceSync"] = "Clearbit Salesforce Sync";
    DataSecurityAppName["ClickDeploy"] = "ClickDeploy.io";
    DataSecurityAppName["ClickUp"] = "ClickUp";
    DataSecurityAppName["ClientPortal"] = "Client Portal";
    DataSecurityAppName["ClientSuccess"] = "ClientSuccess";
    DataSecurityAppName["ClientSupportSite"] = "Client Support Site";
    DataSecurityAppName["CloseFactor"] = "Close Factor";
    DataSecurityAppName["CloseFactorDev"] = "Close Factor Dev";
    DataSecurityAppName["ClosePlan"] = "ClosePlan";
    DataSecurityAppName["Cloud4JReportBuilder"] = "Cloud4J Report Builder";
    DataSecurityAppName["CloudAnswers"] = "CloudAnswers Report Sender Pluse";
    DataSecurityAppName["Cloudingo"] = "Cloudingo";
    DataSecurityAppName["CloudingoAgent"] = "Cloudingo Agent";
    DataSecurityAppName["CoachEm"] = "CoachEm Connected App";
    DataSecurityAppName["Coefficient"] = "Coefficient";
    DataSecurityAppName["Cognisim"] = "Cognism";
    DataSecurityAppName["CognisimChrome"] = "Cognism Chrome";
    DataSecurityAppName["Collibra"] = "Collibra";
    DataSecurityAppName["CommonRoom"] = "Common Room Data Sync";
    DataSecurityAppName["CommVault"] = "CommVault";
    DataSecurityAppName["Composer"] = "Composer";
    DataSecurityAppName["ComposerLightning"] = "Composer Lightning";
    DataSecurityAppName["Concur"] = "Concur";
    DataSecurityAppName["ConcurConnector"] = "Concur Connector (US2)";
    DataSecurityAppName["ConfigWorkbookTool"] = "Config Workbook Tool";
    DataSecurityAppName["Conga"] = "Conga";
    DataSecurityAppName["CongaApprovals"] = "Conga Approvals";
    DataSecurityAppName["CongaCLM"] = "Conga CLM";
    DataSecurityAppName["CongaComposer"] = "Conga Composer";
    DataSecurityAppName["CongaPlatform"] = "Conga Platform";
    DataSecurityAppName["CongaPlatformServices"] = "Conga Platform Services";
    DataSecurityAppName["CongaSchedule"] = "Conga Schedule";
    DataSecurityAppName["CongaSign"] = "Conga Sign";
    DataSecurityAppName["ConnectAndSell"] = "ConnectAndSell";
    DataSecurityAppName["ConstantContact"] = "Constant Contact";
    DataSecurityAppName["ContactClean"] = "Contact Clean";
    DataSecurityAppName["ContactOut"] = "ContactOut";
    DataSecurityAppName["ContractBilling"] = "ContractBilling";
    DataSecurityAppName["Conversica"] = "Conversica";
    DataSecurityAppName["Conveyor"] = "Conveyor";
    DataSecurityAppName["Copado"] = "Copado";
    DataSecurityAppName["CopadoDevOps"] = "Copado DevOps (N.A.)";
    DataSecurityAppName["CopadoAIService"] = "Copado GPT AI Service";
    DataSecurityAppName["CopadoRoboticTesting"] = "Copado Robotic Testing";
    DataSecurityAppName["Correlated"] = "Correlated Labs Salesforce Connection";
    DataSecurityAppName["CozyRoc"] = "CozyRoc/SSIS/";
    DataSecurityAppName["CPOMS"] = "CPOMS";
    DataSecurityAppName["Crayon"] = "Crayon";
    DataSecurityAppName["CrayonImpact"] = "Crayon Impact Connector";
    DataSecurityAppName["CRMPerks"] = "CRM Perks";
    DataSecurityAppName["Crossbeam"] = "Crossbeam";
    DataSecurityAppName["CrossbeamSalesforce"] = "Crossbeam Salesforce App";
    DataSecurityAppName["CrossbeamSalesforceOverlaps"] = "Crossbeam Salesforce Overlaps Push";
    DataSecurityAppName["Crowdvocate"] = "Crowdvocate";
    DataSecurityAppName["Crunchbase"] = "Crunchbase";
    DataSecurityAppName["CTUCloneThisUser"] = "CTU: Clone This User";
    DataSecurityAppName["CustomOrganizationIntegration"] = "customOrgToOrgIntegration";
    DataSecurityAppName["Customer360DataPlatform"] = "Customer 360 Data Platform";
    DataSecurityAppName["CustomerDashboard"] = "CustomerDashboard";
    DataSecurityAppName["CustomerPortal"] = "Customer Portal Solutions";
    DataSecurityAppName["Cvent"] = "Cvent";
    DataSecurityAppName["DataAdminUser"] = "DataAdmin User";
    DataSecurityAppName["DataAnalytics"] = "Data Analytics";
    DataSecurityAppName["Databox"] = "Databox";
    DataSecurityAppName["DataChef"] = "Data Chef";
    DataSecurityAppName["DataChef2"] = "DataChef";
    DataSecurityAppName["DataConnector"] = "Data connector for Salesforce";
    DataSecurityAppName["DataDog"] = "datadog";
    DataSecurityAppName["DataDotCom"] = "Data.com";
    DataSecurityAppName["DataFox"] = "DataFox";
    DataSecurityAppName["Dataiku"] = "Dataiku Salesforce Connecto";
    DataSecurityAppName["DataImporter"] = "DataImporter.io";
    DataSecurityAppName["DataLineage"] = "Data Lineage App";
    DataSecurityAppName["DataLoader"] = "Data Loader";
    DataSecurityAppName["Dataloader"] = "Dataloader";
    DataSecurityAppName["DataLoaderBulk"] = "Dataloader Bulk";
    DataSecurityAppName["DataLoaderBulkUI"] = "DataLoaderBulkUI/";
    DataSecurityAppName["DataLoaderIO"] = "dataloader.io_v2";
    DataSecurityAppName["DataLoaderPartner"] = "Dataloader Partner";
    DataSecurityAppName["DataLoaderPartnerBatch"] = "DataLoaderPartnerBatch/";
    DataSecurityAppName["DataMask"] = "Data Mask";
    DataSecurityAppName["DBAmp"] = "DBAmp";
    DataSecurityAppName["DBHoovers"] = "D&B Hoovers PerUser OAuth";
    DataSecurityAppName["DBSync"] = "DBSync";
    DataSecurityAppName["DCMIntegration"] = "DCMIntegration";
    DataSecurityAppName["Dealhub"] = "Dealhub";
    DataSecurityAppName["DeetoInbound"] = "Deeto Inbound";
    DataSecurityAppName["DefHC"] = "DefHC";
    DataSecurityAppName["Delighted"] = "Delighted";
    DataSecurityAppName["Demandbase"] = "Demandbase";
    DataSecurityAppName["DemandbaseABM"] = "Demandbase ABM";
    DataSecurityAppName["DemandbaseInc"] = "Demandbase, Inc";
    DataSecurityAppName["DemandTools"] = "DemandTools";
    DataSecurityAppName["DemandToolsV"] = "DemandTools V";
    DataSecurityAppName["DemandTools26"] = "DemandTools26";
    DataSecurityAppName["Deployment"] = "Deployment";
    DataSecurityAppName["DeskDotCom"] = "Desk.com";
    DataSecurityAppName["DevOpsCenter"] = "DevOps Center";
    DataSecurityAppName["Dialpad"] = "Dialpad";
    DataSecurityAppName["DialpadMeetings"] = "Dialpad Meetings";
    DataSecurityAppName["Dice"] = "DICE inContact Integration";
    DataSecurityAppName["DiscoverOrg"] = "DiscoverOrg";
    DataSecurityAppName["DiscoveryDataLink"] = "Discovery DataLink";
    DataSecurityAppName["DiscoveryDataMarketLink"] = "Discovery Data MarketLink Settings";
    DataSecurityAppName["DistrEngineNC2"] = "Distr. Engine - NC2";
    DataSecurityAppName["DMS"] = "Document Management System";
    DataSecurityAppName["DNBConnect"] = "DNB_Connect";
    DataSecurityAppName["DocSend"] = "DocSend";
    DataSecurityAppName["DocuFreeMailroom"] = "Docufree Mailroom";
    DataSecurityAppName["Docusign"] = "Docusign";
    DataSecurityAppName["DocuSign"] = "DocuSign";
    DataSecurityAppName["DocuSignConnect"] = "DocuSign/Connect/";
    DataSecurityAppName["DocuSignConnectSalesforce"] = "DocuSign Connect for Salesforce";
    DataSecurityAppName["Domo"] = "Domo Prod";
    DataSecurityAppName["Dooly"] = "Dooly";
    DataSecurityAppName["Drift"] = "Drift";
    DataSecurityAppName["DriftQA"] = "Drift QA";
    DataSecurityAppName["Dropbox"] = "Dropbox DocSend";
    DataSecurityAppName["DSAppointments"] = "DS Appointments";
    DataSecurityAppName["DunAndBradstreet"] = "Dun & Bradstreet";
    DataSecurityAppName["DuplicateCheck"] = "Duplicate Check";
    DataSecurityAppName["DuplicateCheckSalesforce"] = "Duplicate Check for Salesforce";
    DataSecurityAppName["Ebsta"] = "Ebsta";
    DataSecurityAppName["EditQuotas"] = "Edit Quotas";
    DataSecurityAppName["EFile"] = "E_File";
    DataSecurityAppName["EKLA"] = "EKLA";
    DataSecurityAppName["Elements"] = "Elements";
    DataSecurityAppName["ElfBrowser"] = "elf browser";
    DataSecurityAppName["enosiX"] = "enosiX";
    DataSecurityAppName["EnosixSDK"] = "Enosix SDK";
    DataSecurityAppName["enosixSurface"] = "enosix Surface";
    DataSecurityAppName["EquilarExecAtlas"] = "Equilar ExecAtlas";
    DataSecurityAppName["EventMonitoring"] = "Event Monitoring";
    DataSecurityAppName["EverAfter"] = "EverAfter AI";
    DataSecurityAppName["EverywhereTokenAuth"] = "EverywhereTokenAuth";
    DataSecurityAppName["Exalate"] = "Exalate App";
    DataSecurityAppName["ExecVision"] = "ExecVision";
    DataSecurityAppName["ExploriumConnector"] = "Explorium Salesforce Connector";
    DataSecurityAppName["Expresiv"] = "Expresiv";
    DataSecurityAppName["Fathom"] = "Fathom";
    DataSecurityAppName["FetchOpportunities"] = "FetchOpps";
    DataSecurityAppName["FieldCreator"] = "Field Creator";
    DataSecurityAppName["FieldFootprint"] = "Field Footprint";
    DataSecurityAppName["Fifteenfive"] = "15five";
    DataSecurityAppName["FinancialForce"] = "FinancialForce";
    DataSecurityAppName["FinancialForceERP"] = "FinancialForce ERP";
    DataSecurityAppName["FinancialForceInv"] = "FinancialForce Inv.";
    DataSecurityAppName["Fintastic"] = "fintastic";
    DataSecurityAppName["Five9"] = "Five9";
    DataSecurityAppName["Fivetran"] = "Fivetran";
    DataSecurityAppName["FivetranDataLoader"] = "Fivetran Data Loader";
    DataSecurityAppName["Fleetio"] = "FleetioSupport Connected App for RestAPI OAuth";
    DataSecurityAppName["Flosum"] = "Flosum Backup Org Auth";
    DataSecurityAppName["FlosumApplication"] = "FlosumApplication_Puzzel";
    DataSecurityAppName["FlowActionsBasePack"] = "FlowActionsBasePack";
    DataSecurityAppName["FlowAssist"] = "Flow Assist";
    DataSecurityAppName["Fluint"] = "Fluint.io";
    DataSecurityAppName["FluxCapacity"] = "Flux Capacity";
    DataSecurityAppName["Folderize"] = "Folderize";
    DataSecurityAppName["Foresight"] = "Foresight";
    DataSecurityAppName["FormAssembly"] = "FormAssembly";
    DataSecurityAppName["Formstack"] = "Formstack";
    DataSecurityAppName["Foundation"] = "Portal REST - Foundation AI";
    DataSecurityAppName["Freshdesk"] = "Freshdesk - Your Social Helpdesk";
    DataSecurityAppName["Front"] = "Front";
    DataSecurityAppName["FullCircle"] = "Full Circle";
    DataSecurityAppName["FundFormation"] = "Fund Formation";
    DataSecurityAppName["Funnel"] = "Funnel";
    DataSecurityAppName["G2Crowd"] = "G2Crowd";
    DataSecurityAppName["G2Crowd2"] = "G2 Crowd";
    DataSecurityAppName["G2Track"] = "G2 Track";
    DataSecurityAppName["Gainsight"] = "Gainsight";
    DataSecurityAppName["GainsightApp"] = "Gainsight App";
    DataSecurityAppName["Gcom"] = "gcom";
    DataSecurityAppName["GConnector"] = "G-Connector for Salesforce";
    DataSecurityAppName["Gearset"] = "Gearset";
    DataSecurityAppName["GearsetDeploy"] = "Gearset Deploy";
    DataSecurityAppName["Geckoboard"] = "Geckoboard";
    DataSecurityAppName["Genesys"] = "Genesys";
    DataSecurityAppName["Geopointe"] = "Geopointe";
    DataSecurityAppName["Gepeto"] = "Gepeto Integration";
    DataSecurityAppName["GetFeedback"] = "GetFeedback";
    DataSecurityAppName["Getint"] = "GetintTest3";
    DataSecurityAppName["Glean"] = "Glean Search";
    DataSecurityAppName["Goldcast"] = "Goldcast";
    DataSecurityAppName["Gong"] = "Gong";
    DataSecurityAppName["GongCanvas"] = "Gong Canvas App";
    DataSecurityAppName["GongDealsCanvas"] = "Gong Deals Canvas App";
    DataSecurityAppName["GongIntegration"] = "Gong Integration App";
    DataSecurityAppName["GongUserConnection"] = "Gong.io User Connection";
    DataSecurityAppName["Google"] = "Google";
    DataSecurityAppName["GoogleAds"] = "Google Ads";
    DataSecurityAppName["GoogleAdsDataManagerAuth1"] = "Google Ads Data Manager Auth App 1";
    DataSecurityAppName["GoogleAdsDataManagerAuth2"] = "Google Ads Data Manager Auth App 2";
    DataSecurityAppName["GoogleAdsDataManagerAuth3"] = "Google Ads Data Manager Auth App 3";
    DataSecurityAppName["GoogleAdWords"] = "GoogleAdWords";
    DataSecurityAppName["GoogleAdWords2"] = "Google AdWords";
    DataSecurityAppName["GoogleAdWordsConversionImport"] = "Google AdWords Conversion Import";
    DataSecurityAppName["GoogleDataStudioConnector"] = "Google Data Studio Connector for Salesforce";
    DataSecurityAppName["GoToGroup"] = "GoToGroup/";
    DataSecurityAppName["GPVIntegration"] = "GPV Integration";
    DataSecurityAppName["Grafana"] = "Grafana";
    DataSecurityAppName["GrafanaAPITesting"] = "Grafana API Testing";
    DataSecurityAppName["GrafanaCom"] = "Grafana Com";
    DataSecurityAppName["GrafanaOps"] = "ops.grafana-ops.net";
    DataSecurityAppName["Grain"] = "Grain";
    DataSecurityAppName["GRC"] = "GRC to Salesforce";
    DataSecurityAppName["GridBuddy"] = "GridBuddy";
    DataSecurityAppName["Groove"] = "Groove";
    DataSecurityAppName["GrooveDevelopment"] = "Groove Development";
    DataSecurityAppName["GrowBIDashboard"] = "Grow BI Dashboard";
    DataSecurityAppName["GuideCX"] = "GuideCX";
    DataSecurityAppName["Guru"] = "Guru";
    DataSecurityAppName["HackerOne"] = "HackerOne";
    DataSecurityAppName["HappySoup"] = "HappySoup.io";
    DataSecurityAppName["HeadlightDataIngest"] = "Headlight Data Ingest";
    DataSecurityAppName["Heap"] = "Heap";
    DataSecurityAppName["HeapIntegration"] = "Heap SFDC Integration";
    DataSecurityAppName["HelloSign"] = "HelloSign";
    DataSecurityAppName["Heroku"] = "Heroku Connect";
    DataSecurityAppName["Heymarket"] = "Heymarket";
    DataSecurityAppName["HGConnect"] = "HG Connect";
    DataSecurityAppName["HGData"] = "HG Data";
    DataSecurityAppName["Highspot"] = "Highspot";
    DataSecurityAppName["Hightouch"] = "Hightouch";
    DataSecurityAppName["HippoVideo"] = "HippoVideo Oauth Client Production";
    DataSecurityAppName["HockeyStack"] = "HockeyStack";
    DataSecurityAppName["HookConnector"] = "Hook Connector";
    DataSecurityAppName["HubConnect"] = "HubConnect for OAuth Prod";
    DataSecurityAppName["HubIntegration"] = "Hub Integration";
    DataSecurityAppName["Hubspot"] = "Hubspot";
    DataSecurityAppName["ICapture"] = "iCapture";
    DataSecurityAppName["IEnterprises"] = "iEnterprises";
    DataSecurityAppName["IEnterprisesEmail"] = "iEnterprises Email Connector";
    DataSecurityAppName["Impartner"] = "Impartner";
    DataSecurityAppName["ImpartnerMDF"] = "Impartner MDF";
    DataSecurityAppName["Incoming"] = "Incoming SMS 360";
    DataSecurityAppName["InflProductionRotated"] = "Infl Production Rotated";
    DataSecurityAppName["Informatica"] = "Informatica";
    DataSecurityAppName["InMoment"] = "InMoment";
    DataSecurityAppName["InSided"] = "inSided";
    DataSecurityAppName["Insights"] = "Insights";
    DataSecurityAppName["InsightSquared"] = "InsightSquared Platform";
    DataSecurityAppName["Instapage"] = "Instapage";
    DataSecurityAppName["Insycle"] = "Insycle";
    DataSecurityAppName["IntacctAdvanced"] = "Intacct Advanced";
    DataSecurityAppName["IntacctConnect"] = "Intacct Connect";
    DataSecurityAppName["IntacctContract"] = "Intacct Contract";
    DataSecurityAppName["Intail"] = "Intail.aio";
    DataSecurityAppName["Intake"] = "Intake Rest API";
    DataSecurityAppName["IntegratorIo"] = "Integrator.io";
    DataSecurityAppName["Integrator_IO"] = "Integrator_io";
    DataSecurityAppName["Intercom"] = "Intercom";
    DataSecurityAppName["IntercombySalesforce"] = "Salesforce by Intercom";
    DataSecurityAppName["Intricately"] = "Intricately Record View";
    DataSecurityAppName["Introhive"] = "Introhive";
    DataSecurityAppName["IntrohiveNA2"] = "Introhive (NA2)";
    DataSecurityAppName["Ironclad"] = "Ironclad";
    DataSecurityAppName["JenkinsBuildServer"] = "Jenkins Build Server";
    DataSecurityAppName["Jetstream"] = "Jetstream";
    DataSecurityAppName["JetstreamAuth"] = "Jetstream Auth";
    DataSecurityAppName["Jira"] = "Jira";
    DataSecurityAppName["JiraIntegration"] = "Jira Integration";
    DataSecurityAppName["JiraSalesforce"] = "Salesforce & JIRA Cloud Connector";
    DataSecurityAppName["JotForm"] = "JotForm";
    DataSecurityAppName["JWT"] = "JWT";
    DataSecurityAppName["Khoros"] = "Khoros";
    DataSecurityAppName["Kimble"] = "Kimble";
    DataSecurityAppName["KingswaySoft"] = "KingswaySoft/SSISIntegrationToolkit/";
    DataSecurityAppName["Kixie"] = "Kixie";
    DataSecurityAppName["Klaus"] = "Klaus";
    DataSecurityAppName["Kochava"] = "Kochava Integration";
    DataSecurityAppName["Koncert"] = "Koncert Platform";
    DataSecurityAppName["KotlikovBot"] = "KotlikovBot";
    DataSecurityAppName["LaunchPad"] = "LaunchPad";
    DataSecurityAppName["Lavendar"] = "Lavendar Salesforce API Access";
    DataSecurityAppName["LavendarNew"] = "Lavendar Salesforce API Access_New";
    DataSecurityAppName["LavendarSFDC"] = "Lavendar SFDC";
    DataSecurityAppName["Leadfeeder"] = "Leadfeeder";
    DataSecurityAppName["LeadIQ"] = "LeadIQ";
    DataSecurityAppName["LeanData"] = "LeanData";
    DataSecurityAppName["LeanDataOL"] = "LeanDataOL";
    DataSecurityAppName["LearnUpon"] = "LearnUpon";
    DataSecurityAppName["LightningConfiguration"] = "Lightning Configuration";
    DataSecurityAppName["LightningObjectCreator"] = "Lightning Object Creator";
    DataSecurityAppName["LightningReadiness"] = "Lightning Readiness";
    DataSecurityAppName["LightningSharing"] = "LightningSharing";
    DataSecurityAppName["Linen"] = "Linen";
    DataSecurityAppName["LinenIntegration"] = "Linen Integration";
    DataSecurityAppName["LinkedIn"] = "LinkedIn";
    DataSecurityAppName["LinkedInSalesNavigator"] = "LinkedIn Sales Navigator";
    DataSecurityAppName["LinkedInSalesNavigatorWriteback"] = "LinkedIn Sales Navigator Writeback";
    DataSecurityAppName["LinkPoint360"] = "LinkPoint360";
    DataSecurityAppName["LinkSquares"] = "LinkSquares";
    DataSecurityAppName["LinqProduction"] = "Linq Production";
    DataSecurityAppName["LiveChat"] = "LiveChat";
    DataSecurityAppName["LivePerson"] = "LivePerson";
    DataSecurityAppName["LMSILT"] = "LMS ILT";
    DataSecurityAppName["LMSLearning"] = "LMS Learning";
    DataSecurityAppName["LoadAgent"] = "LoadAgentApp";
    DataSecurityAppName["LocalDevelopmentConnection"] = "Local Development Connection";
    DataSecurityAppName["LookupHelper"] = "Lookup Helper";
    DataSecurityAppName["Loop"] = "Loop";
    DataSecurityAppName["Loopio"] = "Loopio";
    DataSecurityAppName["Lucidchart"] = "Lucidchart";
    DataSecurityAppName["Lusha"] = "Lusha Push to Salesforce";
    DataSecurityAppName["LWC"] = "LWC SOQL Builder";
    DataSecurityAppName["MadCap"] = "MadCap";
    DataSecurityAppName["MadCapConnect"] = "MadCap Connect";
    DataSecurityAppName["MadKudu"] = "MadKudu Connect";
    DataSecurityAppName["MadKuduConnectedApp"] = "MadKudu_Connected_App";
    DataSecurityAppName["MaestroQA"] = "MaestroQA";
    DataSecurityAppName["Magentrix"] = "Magentrix";
    DataSecurityAppName["MagentrixSiteSalesforce"] = "Magentrix Site for Salesforce";
    DataSecurityAppName["MagicMover"] = "Magic Mover";
    DataSecurityAppName["Mailchimp"] = "Mailchimp";
    DataSecurityAppName["Make"] = "Make";
    DataSecurityAppName["Malbek"] = "MalbekConnectorApp";
    DataSecurityAppName["Map"] = "Map";
    DataSecurityAppName["MapAnything"] = "MapAnything";
    DataSecurityAppName["MarketingAnalytics"] = "Marketing Analytics";
    DataSecurityAppName["MarketingCloud"] = "Marketing Cloud";
    DataSecurityAppName["MarketingCloudIntegration"] = "Marketing Cloud Integration";
    DataSecurityAppName["Marketo"] = "Marketo";
    DataSecurityAppName["MarketoLeadMgm"] = "Marketo Lead Mgm";
    DataSecurityAppName["MarketoNew"] = "New Marketo App";
    DataSecurityAppName["MarketoOAuth"] = "SE_Marketo_OAuth_Connector";
    DataSecurityAppName["MatchMyEmail"] = "Match My Email";
    DataSecurityAppName["Matik"] = "Matik";
    DataSecurityAppName["MavenTools"] = "Maven Tools";
    DataSecurityAppName["Maxio"] = "Maxio";
    DataSecurityAppName["MaxioPlatformIntegration"] = "Maxio Platform Integration";
    DataSecurityAppName["MDComp"] = "MD Comp";
    DataSecurityAppName["MedicaRecordService"] = "MedicalRecordService";
    DataSecurityAppName["Merge"] = "Merge";
    DataSecurityAppName["MergeTest"] = "Merge Test App";
    DataSecurityAppName["MetadataSF"] = "Metadata SF App";
    DataSecurityAppName["Metallic"] = "Metallic Salesforce Backup and Recovery";
    DataSecurityAppName["MicrosoftCloudAppSecurity"] = "Microsoft Cloud App Security";
    DataSecurityAppName["MicrosoftCloudAppSecurityUSW2"] = "Microsoft_Cloud_App_Security_USW2";
    DataSecurityAppName["MicrosoftConnectors"] = "Microsoft Connectors";
    DataSecurityAppName["MicrosoftGraphConnectors"] = "Microsoft Graph Connectors";
    DataSecurityAppName["MicrosoftPowerPlatform"] = "Microsoft Power Platform";
    DataSecurityAppName["MicrosoftPowerQuery"] = "Microsoft Power Query";
    DataSecurityAppName["MicrosoftPowerQueryRefresh"] = "Microsoft Power Query Refresh";
    DataSecurityAppName["MilestonesPM"] = "Milestones PM+";
    DataSecurityAppName["Mindtickle"] = "Enable Us by Mindtickle";
    DataSecurityAppName["MindtickleOAuth"] = "Mindtickle Salesforce OAuth";
    DataSecurityAppName["Minoa"] = "Minoa";
    DataSecurityAppName["MinoaMerge"] = "Minoa-Merge";
    DataSecurityAppName["MissionControl"] = "Mission Control";
    DataSecurityAppName["Modigie"] = "Modigie";
    DataSecurityAppName["Momentum"] = "Momentum Production";
    DataSecurityAppName["Monday"] = "Monday";
    DataSecurityAppName["MondayDotCom"] = "Monday.com";
    DataSecurityAppName["Mosaic"] = "Mosaic";
    DataSecurityAppName["MovableInk"] = "Movable Ink: Anubis Data Extractor";
    DataSecurityAppName["MSCI"] = "MSCI";
    DataSecurityAppName["MSCIAnalytics"] = "MSCI Analytics API Permissioning";
    DataSecurityAppName["MSCIApps"] = "MSCI Apps Permissioning";
    DataSecurityAppName["MSCIEntitlements"] = "OneMSCI Entitlement Platform\u00A0";
    DataSecurityAppName["MSCIIAM"] = "MSCI IAM API";
    DataSecurityAppName["MSCIOne"] = "MSCI ONE API";
    DataSecurityAppName["MSCIService"] = "MSCI API Service";
    DataSecurityAppName["MTIQuery"] = "MTI Query";
    DataSecurityAppName["Mulesoft"] = "Mulesoft";
    DataSecurityAppName["MulesoftAPI"] = "Mulesoft API Access Connect Platform";
    DataSecurityAppName["MulesoftPlatform"] = "Mulesoft Platform";
    DataSecurityAppName["Mutiny"] = "Mutiny";
    DataSecurityAppName["MultiViewCalendar"] = "Multi-View Calendar";
    DataSecurityAppName["myViews"] = "myViews";
    DataSecurityAppName["NavigateButton"] = "NavigateButton";
    DataSecurityAppName["nCino"] = "nCino";
    DataSecurityAppName["Netchex"] = "NetchexProduction Connected App for RestAPI OAuth";
    DataSecurityAppName["NetForm"] = "NetForum API User";
    DataSecurityAppName["Netskope"] = "Netskope Introspection for Salesforce";
    DataSecurityAppName["NetSuiteConnector"] = "NetSuite Connector";
    DataSecurityAppName["NewRelic"] = "newrelic explorer";
    DataSecurityAppName["NewVoicMedia"] = "NewVoiceMedia";
    DataSecurityAppName["NewVoicMediaAPI"] = "NewVoiceMedia API";
    DataSecurityAppName["Nexus"] = "NexusContactService";
    DataSecurityAppName["NICEinContact"] = "NICE inContact";
    DataSecurityAppName["Nightfall"] = "NightfallDLP";
    DataSecurityAppName["NintexDocGen"] = "Nintex DocGen for Salesforce";
    DataSecurityAppName["NPSP"] = "NPSP";
    DataSecurityAppName["Nooks"] = "Nooks";
    DataSecurityAppName["NRAInvoicesBatch"] = "NRA Invoices Batch";
    DataSecurityAppName["Nue"] = "Nue Revenue Builder";
    DataSecurityAppName["Octoboard"] = "OCTOBOARD";
    DataSecurityAppName["OIQIntegraion"] = "OIQ_Integration";
    DataSecurityAppName["Okta"] = "Okta";
    DataSecurityAppName["OktaImports"] = "Okta Imports";
    DataSecurityAppName["OktaIntegration"] = "Okta Integration";
    DataSecurityAppName["OktaProvisioning"] = "Okta Provisioning";
    DataSecurityAppName["OktaSCIM"] = "Okta SCIM";
    DataSecurityAppName["OktaWorkflows"] = "Okta Workflows";
    DataSecurityAppName["OktaSFDCProvisioning"] = "Okta_SFDC_Provisioning";
    DataSecurityAppName["Oktopost"] = "Oktopost";
    DataSecurityAppName["Oneflow"] = "Oneflow";
    DataSecurityAppName["OneClickUpdate"] = "One Click Update";
    DataSecurityAppName["OneMSCIEntitlementPlatform"] = "OneMSCI Entitlement Platform";
    DataSecurityAppName["OneTrust"] = "OneTrust";
    DataSecurityAppName["OnRamp"] = "OnRamp v3";
    DataSecurityAppName["OpenpriseCloud"] = "Openprise Cloud";
    DataSecurityAppName["OperationsSchedulingAutomation"] = "Operations Scheduling Automation";
    DataSecurityAppName["OrcaPlus"] = "Orca+";
    DataSecurityAppName["OrchestrateSetup"] = "OrchestrateSetup";
    DataSecurityAppName["OrgChartPlus"] = "OrgChartPlus";
    DataSecurityAppName["OrigamiClaims"] = "origami_claims";
    DataSecurityAppName["Orum"] = "Orum";
    DataSecurityAppName["OtterAI"] = "Otter AI";
    DataSecurityAppName["OutlookIntegrationRiva"] = "Outlook Integration (Riva)";
    DataSecurityAppName["Outreach"] = "Outreach";
    DataSecurityAppName["OutreachLivechat"] = "Outreach/Livechat";
    DataSecurityAppName["OwnForSalesforce"] = "Own for Salesforce";
    DataSecurityAppName["OwnForSalesforceMetadata"] = "Own for Salesforce Metadata";
    DataSecurityAppName["OwnForSalesforceSandbox"] = "Own for Salesforce Sandbox";
    DataSecurityAppName["OwnBackup"] = "OwnBackup";
    DataSecurityAppName["OwnBackupForSalesforce"] = "OwnBackup for Salesforce";
    DataSecurityAppName["PagerDuty"] = "PagerDuty";
    DataSecurityAppName["PandaDoc"] = "PandaDoc";
    DataSecurityAppName["PandaDocOAuth2"] = "PandaDoc OAuth2";
    DataSecurityAppName["PardotSSO"] = "Pardot SSO";
    DataSecurityAppName["PardotSecure"] = "Pardot_to_SF_Integration_Secure_Connected_App";
    DataSecurityAppName["PartnerFleet"] = "Partner Fleet";
    DataSecurityAppName["PartnerSignup"] = "Partner Signup";
    DataSecurityAppName["PartnerSuccessConnect"] = "PartnerSuccessConnect";
    DataSecurityAppName["PartnerTap"] = "PartnerTap";
    DataSecurityAppName["PatternDataDocumentExport"] = "Pattern Data Document Export";
    DataSecurityAppName["Pendo"] = "Pendo";
    DataSecurityAppName["PendoIntegration"] = "Pendo Salesforce Integration";
    DataSecurityAppName["Pardot"] = "Pardot";
    DataSecurityAppName["PardotAPI"] = "Pardot API";
    DataSecurityAppName["PeopleAI"] = "PeopleAI";
    DataSecurityAppName["PeopleDotAI"] = "People.ai";
    DataSecurityAppName["PeopleImport"] = "PeopleImport";
    DataSecurityAppName["Performio"] = "Performio";
    DataSecurityAppName["PermComparator"] = "PermComparator";
    DataSecurityAppName["Pigment"] = "Pigment";
    DataSecurityAppName["Pipe17"] = "Pipe17";
    DataSecurityAppName["PitchBook"] = "PitchBook";
    DataSecurityAppName["PlanhatSandboxes"] = "Planhat Sandboxes";
    DataSecurityAppName["PlanhatUS"] = "Planhat US";
    DataSecurityAppName["Planthat"] = "Planthat";
    DataSecurityAppName["PolarisLicense"] = "Polaris License App";
    DataSecurityAppName["Polytomic"] = "Polytomic";
    DataSecurityAppName["Popl"] = "Popl";
    DataSecurityAppName["PortalIntegration"] = "Portal Integration";
    DataSecurityAppName["Postal"] = "Postal.io";
    DataSecurityAppName["Postman"] = "Salesforce APIs Collection for Postman";
    DataSecurityAppName["PowerAutomation"] = "Power Automation";
    DataSecurityAppName["PowerAutomationUser"] = "Power Automation User";
    DataSecurityAppName["PowerBI"] = "PowerBI";
    DataSecurityAppName["PowerSpaceBI"] = "Power BI";
    DataSecurityAppName["PowerOfUsHub"] = "Power of Us Hub";
    DataSecurityAppName["ProdlyDeployment"] = "Prodly Deployment";
    DataSecurityAppName["ProdoscoreLive"] = "Prodoscore_Live";
    DataSecurityAppName["Productboard"] = "Productboard";
    DataSecurityAppName["Productiv"] = "Productiv";
    DataSecurityAppName["ProgressiveIntegration"] = "Progressive Integration";
    DataSecurityAppName["Prospect"] = "Prospect";
    DataSecurityAppName["Propel"] = "Propel";
    DataSecurityAppName["Qualified"] = "Qualified";
    DataSecurityAppName["Qualtrics"] = "Qualtrics";
    DataSecurityAppName["QualtricsIntegration"] = "Qualtrics Integration";
    DataSecurityAppName["QualtricsSurvey"] = "Qualtrics Survey";
    DataSecurityAppName["QuickBasePipelines"] = "Quick Base Pipelines";
    DataSecurityAppName["QuickbaseSync"] = "Quickbase Sync";
    DataSecurityAppName["Quip"] = "Quip";
    DataSecurityAppName["QuotaPath"] = "QuotaPath";
    DataSecurityAppName["Qwilr"] = "Qwilr";
    DataSecurityAppName["RallySupportManager"] = "Rally Support Manager";
    DataSecurityAppName["Rampmetrics"] = "rampmetrics";
    DataSecurityAppName["RaptorCommunitySSO"] = "RaptorCommunitySSO";
    DataSecurityAppName["Rattle"] = "Rattle";
    DataSecurityAppName["RCCCAPI"] = "RC CC API";
    DataSecurityAppName["Reachdesk"] = "Reachdesk";
    DataSecurityAppName["ReferenceEdge"] = "ReferenceEdge";
    DataSecurityAppName["Reveal"] = "Reveal";
    DataSecurityAppName["ReadAI"] = "Read AI";
    DataSecurityAppName["Receptive"] = "Receptive";
    DataSecurityAppName["RegieAI"] = "Regie AI";
    DataSecurityAppName["Responsive"] = "Responsive(Formerly RFPIO)";
    DataSecurityAppName["Retail"] = "Retain";
    DataSecurityAppName["Retool"] = "Retool";
    DataSecurityAppName["RevenueGrid"] = "Revenue Grid for Salesforce";
    DataSecurityAppName["RevenueInbox"] = "Revenue Inbox (EDHC)";
    DataSecurityAppName["RevSend"] = "RevSend";
    DataSecurityAppName["RightRevRevRec"] = "RightRev Rev Rec";
    DataSecurityAppName["RingDNA"] = "RingDNA";
    DataSecurityAppName["RingLead"] = "RingLead";
    DataSecurityAppName["Rippling"] = "Rippling";
    DataSecurityAppName["Riva"] = "Riva";
    DataSecurityAppName["RivaForSalesforce"] = "Riva for Salesforce - Connected App";
    DataSecurityAppName["RLAccess"] = "RL Access";
    DataSecurityAppName["RMGAPI"] = "RMG API";
    DataSecurityAppName["RMKRViewCentral"] = "RMKR ViewCentral";
    DataSecurityAppName["Rocketlane"] = "Rocketlane";
    DataSecurityAppName["RocketReachSalesForceSync"] = "RocketReach SalesForce Sync";
    DataSecurityAppName["RollupHelper"] = "Rollup Helper";
    DataSecurityAppName["RollupSummary"] = "Rollup Summary";
    DataSecurityAppName["RubyRevenueBuilder"] = "Ruby Revenue Builder";
    DataSecurityAppName["S4G"] = "S4G";
    DataSecurityAppName["SaaSWorks"] = "SaaSWorks";
    DataSecurityAppName["Savebase"] = "Safebase";
    DataSecurityAppName["SailpointIntegration"] = "Sailpoint Integration";
    DataSecurityAppName["SalesCloud"] = "Sales Cloud Einstein Readiness Assessor App";
    DataSecurityAppName["SalesforceAdvancedSearch"] = "Salesforce Advanced Search";
    DataSecurityAppName["SalesforceAPIForDashboard"] = "SalesforceAPI For Dashboard";
    DataSecurityAppName["SalesforceForAndroid"] = "Salesforce for Android";
    DataSecurityAppName["SalesforceBackend"] = "Salesforce Backend";
    DataSecurityAppName["SalesforceBilling"] = "Salesforce Billing";
    DataSecurityAppName["SalesforceChangeSetHelper"] = "Salesforce Change Set Helper";
    DataSecurityAppName["SalesforceChatter"] = "Salesforce Chatter";
    DataSecurityAppName["SalesforceCLI"] = "Salesforce CLI";
    DataSecurityAppName["SalesforceComplianceSite"] = "Salesforce Compliance Site";
    DataSecurityAppName["SalesforceConnector"] = "Salesforce Connector";
    DataSecurityAppName["SalesforceDevelopers"] = "Salesforce Developers";
    DataSecurityAppName["SalesforceDevTools"] = "Salesforce DevTools";
    DataSecurityAppName["SalesforceDigitalTbid"] = "tbid.digital.salesforce.com";
    DataSecurityAppName["SalesforceFieldServiceForAndroid"] = "Salesforce Field Service for Android";
    DataSecurityAppName["SalesforceFieldServiceForiOS"] = "Salesforce Field Service for iOS";
    DataSecurityAppName["SalesforceFunctions"] = "Salesforce Functions";
    DataSecurityAppName["SalesforceHelp"] = "Salesforce Help";
    DataSecurityAppName["SalesforceInputTool"] = "Salesforce Input Tool";
    DataSecurityAppName["SalesforceInspectorreloaded"] = "Salesforce Inspector reloaded";
    DataSecurityAppName["SalesforceIQ"] = "SalesforceIQ";
    DataSecurityAppName["SalesforceIQInbox"] = "SalesforceIQ Inbox";
    DataSecurityAppName["SalesforceForiOS"] = "Salesforce for iOS";
    DataSecurityAppName["SalesforceMaps"] = "Salesforce Maps";
    DataSecurityAppName["SalesforceMarketingCloud"] = "Salesforce Marketing Cloud";
    DataSecurityAppName["SalesforceOrgDoctor"] = "Salesforce Org Doctor";
    DataSecurityAppName["SalesforceforOutlook"] = "Salesforce for Outlook";
    DataSecurityAppName["SalesforcePartnerCommunity"] = "Salesforce Partner Community";
    DataSecurityAppName["SalesforceProductboardIntegration"] = "Salesforce Productboard Integration";
    DataSecurityAppName["SalesforceSecurityAndComplianceSite"] = "Salesforce Security & Compliance Site";
    DataSecurityAppName["SalesforceSwitch"] = "Salesforce Switch";
    DataSecurityAppName["SalesHoodPlugin"] = "SalesHood Plugin";
    DataSecurityAppName["SalesInsights"] = "Sales Insights";
    DataSecurityAppName["Salesloft"] = "Salesloft";
    DataSecurityAppName["SalesLoftQA"] = "SalesLoft QA";
    DataSecurityAppName["SalesQ"] = "SalesQ";
    DataSecurityAppName["SalesScreen"] = "SalesScreen";
    DataSecurityAppName["SalesSpaceScreen"] = "Sales Screen";
    DataSecurityAppName["Saleswhale"] = "Saleswhale";
    DataSecurityAppName["Salto"] = "Salto";
    DataSecurityAppName["ScanBizCardsBusinessCardReader"] = "ScanBizCards Business Card Reader";
    DataSecurityAppName["Scratchpad"] = "Scratchpad";
    DataSecurityAppName["SearchUnify"] = "SearchUnify";
    DataSecurityAppName["SeamlessAI"] = "Seamless.AI";
    DataSecurityAppName["SeaMonster"] = "SeaMonster";
    DataSecurityAppName["Secoda"] = "Secoda";
    DataSecurityAppName["Segment"] = "Segment";
    DataSecurityAppName["Seismic"] = "Seismic";
    DataSecurityAppName["SeismicOAUTH"] = "Seismic OAUTH";
    DataSecurityAppName["SeismicBusinessDataEnrichment"] = "Seismic Business Data Enrichment";
    DataSecurityAppName["Sendoso"] = "Sendoso";
    DataSecurityAppName["Sentinel"] = "Sentinel";
    DataSecurityAppName["ServiceCloudArticleInt"] = "Service Cloud Article Int";
    DataSecurityAppName["SEToolsSFDC"] = "SETools SFDC";
    DataSecurityAppName["SFAdminConApps"] = "SF Admin Con Apps";
    DataSecurityAppName["SFAgileAccelerator"] = "SF Agile Accelerator";
    DataSecurityAppName["SFChatterApps"] = "SF Chatter Apps";
    DataSecurityAppName["SFCommunities"] = "SF Communities";
    DataSecurityAppName["SFConnectedApps"] = "SF Connected Apps";
    DataSecurityAppName["SFDev"] = "SFDev";
    DataSecurityAppName["SFExplorer"] = "SF Explorer";
    DataSecurityAppName["sflowsalesforce"] = "sflow_salesforce";
    DataSecurityAppName["SFSync"] = "SFSync";
    DataSecurityAppName["SFUserAPI"] = "SF User API";
    DataSecurityAppName["SFDCAnalyticsHeadlessBrowserService"] = "SfdcAnalyticsHeadlessBrowserService";
    DataSecurityAppName["SFDCChannelOrder"] = "SFDC Channel Order";
    DataSecurityAppName["SFDCSiqActivityPlatform"] = "SfdcSiqActivityPlatform";
    DataSecurityAppName["SFDCSIQActivitySyncEngine"] = "SfdcSIQActivitySyncEngine";
    DataSecurityAppName["SFDCSIQCloudActivity"] = "SfdcSIQCloudActivity";
    DataSecurityAppName["SFDCSIQEngagement"] = "SfdcSIQEngagement";
    DataSecurityAppName["ShoptechConnectedApp"] = "ShoptechConnectedApp";
    DataSecurityAppName["Showpad"] = "Showpad";
    DataSecurityAppName["ShowpadSalesforceIntegration"] = "Showpad Salesforce Integration";
    DataSecurityAppName["Sifdata"] = "Sifdata";
    DataSecurityAppName["Sigstr"] = "Sigstr";
    DataSecurityAppName["SimbaTechnologies"] = "SimbaTechnologies";
    DataSecurityAppName["SimbaTechnologiesDriver"] = "SimbaTechnologies/ODBCDriver/";
    DataSecurityAppName["Simpplr"] = "Simpplr";
    DataSecurityAppName["SixSense"] = "6sense";
    DataSecurityAppName["SixSenseOauth"] = "sfdc_6sense_oauth_app";
    DataSecurityAppName["Skilljar"] = "Skilljar";
    DataSecurityAppName["SkilljarAPI"] = "Skilljar API";
    DataSecurityAppName["Slack"] = "Slack";
    DataSecurityAppName["Slapdash"] = "Slapdash";
    DataSecurityAppName["SmartlingKnowledge"] = "Smartling Knowledge";
    DataSecurityAppName["SMSMagicUS"] = "SMS Magic US";
    DataSecurityAppName["SNforSFDC"] = "SN for SFDC";
    DataSecurityAppName["Sonar"] = "Sonar";
    DataSecurityAppName["SonarDevelopment"] = "Sonar Development";
    DataSecurityAppName["SonarStaging"] = "Sonar Staging";
    DataSecurityAppName["SoqlX"] = "SoqlX";
    DataSecurityAppName["SpanningBackup"] = "SpanningBackup";
    DataSecurityAppName["Spekit"] = "Spekit";
    DataSecurityAppName["Spiff"] = "Spiff";
    DataSecurityAppName["Splash"] = "Splash";
    DataSecurityAppName["SplashSalesforceIntegration"] = "Splash Salesforce Integration";
    DataSecurityAppName["SPMPackages"] = "SPM Packages";
    DataSecurityAppName["Spoton"] = "Spoton Signup";
    DataSecurityAppName["SpringCM"] = "SpringCM_OAuth";
    DataSecurityAppName["Squivr"] = "Squivr";
    DataSecurityAppName["StackMoxie"] = "Stack Moxie";
    DataSecurityAppName["StackMoxieRTM"] = "Stack Moxie RTM";
    DataSecurityAppName["Staffingsurvey"] = "Staffing survey";
    DataSecurityAppName["StaircaseAI"] = "Staircase AI";
    DataSecurityAppName["StarSan"] = "StarSan";
    DataSecurityAppName["StartDeliver"] = "StartDeliver";
    DataSecurityAppName["SteelbrickCPQ"] = "Steelbrick CPQ";
    DataSecurityAppName["Stitch"] = "Stitch";
    DataSecurityAppName["StullerAPI"] = "Stuller API";
    DataSecurityAppName["Suger"] = "Suger";
    DataSecurityAppName["SumologicEventMonitoring"] = "Sumologic Event Monitoring";
    DataSecurityAppName["Superhuman"] = "Superhuman";
    DataSecurityAppName["Supermetrics"] = "Supermetrics";
    DataSecurityAppName["Surfboard"] = "Surfboard";
    DataSecurityAppName["Surfe"] = "Surfe";
    DataSecurityAppName["SurveyMonkey"] = "SurveyMonkey";
    DataSecurityAppName["SurveyMonkeySalesforce"] = "SurveyMonkey for Salesforce";
    DataSecurityAppName["SWATELA"] = "SWAT ELA";
    DataSecurityAppName["Sweep"] = "Sweep";
    DataSecurityAppName["SwitchGrowth"] = "Switch Growth";
    DataSecurityAppName["Swoogo"] = "Swoogo";
    DataSecurityAppName["Tableau"] = "Tableau";
    DataSecurityAppName["TableauDesktop"] = "Tableau Desktop";
    DataSecurityAppName["TableauID"] = "TableauID";
    DataSecurityAppName["TableauOnline"] = "Tableau Online";
    DataSecurityAppName["TableauOnlineSalesforce"] = "Tableau Online for Salesforce";
    DataSecurityAppName["TableauTackleRefreshToken"] = "TackleRefreshToken";
    DataSecurityAppName["TableauServer"] = "Tableau Server";
    DataSecurityAppName["TableauViz"] = "Tableau Viz";
    DataSecurityAppName["Talkdesk"] = "Talkdesk";
    DataSecurityAppName["Taralex"] = "Taralex";
    DataSecurityAppName["Taralex2"] = "Taralex/";
    DataSecurityAppName["Taskray"] = "Taskray";
    DataSecurityAppName["TeableauOnline"] = "Teableau Online";
    DataSecurityAppName["TeamPages"] = "TeamPages Integration";
    DataSecurityAppName["TechAccelerator"] = "TechAccelerator Portal";
    DataSecurityAppName["TechTargetPriority"] = "TechTarget Priority";
    DataSecurityAppName["TemplateVizArt"] = "Template VizArt";
    DataSecurityAppName["Terminus"] = "Terminus";
    DataSecurityAppName["TerminusMy"] = "MyTerminus";
    DataSecurityAppName["Tesorio"] = "Tesorio Client";
    DataSecurityAppName["ThenaRequests"] = "thena_requests";
    DataSecurityAppName["Thnks"] = "Thnks";
    DataSecurityAppName["ThoughtIndustries"] = "Thought Industries";
    DataSecurityAppName["Tibco"] = "TIBCO";
    DataSecurityAppName["TibcoScribe"] = "Tibco Scribe";
    DataSecurityAppName["TibcoScribeOnline"] = "TIBCO Scribe\u00AC\u00C6 Online Connector for Salesforce";
    DataSecurityAppName["Tonkean"] = "Tonkean";
    DataSecurityAppName["Totango"] = "Totango";
    DataSecurityAppName["TractionGuest"] = "TractionGuest";
    DataSecurityAppName["TractionHierarchies"] = "Traction Hierarchies";
    DataSecurityAppName["Trailblazer"] = "Trailblazer.me";
    DataSecurityAppName["Trailhead"] = "Trailhead";
    DataSecurityAppName["TrailheadConnected"] = "Trailhead Connected App";
    DataSecurityAppName["Trayio"] = "Tray.io";
    DataSecurityAppName["Treehouse"] = "treehouse/resellerview/";
    DataSecurityAppName["Truly"] = "Truly";
    DataSecurityAppName["TrustRadius"] = "TrustRadius";
    DataSecurityAppName["TryProspect"] = "TryProspect";
    DataSecurityAppName["Twitter"] = "Twitter";
    DataSecurityAppName["UIPath"] = "UIPath";
    DataSecurityAppName["UIPathProdVM1"] = "UIPath ProdVM1";
    DataSecurityAppName["UIPathProdVM2"] = "UIPath ProdVM2";
    DataSecurityAppName["UIPathProdVM3"] = "UIPath ProdVM3";
    DataSecurityAppName["UIPathProdVM4"] = "UIPath ProdVM4";
    DataSecurityAppName["UIPathProdVM5"] = "UIPath ProdVM5";
    DataSecurityAppName["UIPathProdVM6"] = "UIPath ProdVM6";
    DataSecurityAppName["UIPathProdVM7"] = "UIPath ProdVM7";
    DataSecurityAppName["UIPathProdVM8"] = "UIPath ProdVM8";
    DataSecurityAppName["UIPathTestVM1"] = "UIPath TestVM1";
    DataSecurityAppName["UKG"] = "UKG Clock In Status Integration";
    DataSecurityAppName["UniqueEntry"] = "Unique Entry";
    DataSecurityAppName["Unify"] = "Unify";
    DataSecurityAppName["Unleash"] = "Unleash";
    DataSecurityAppName["UserGems"] = "UserGems";
    DataSecurityAppName["UserVoice"] = "UserVoice";
    DataSecurityAppName["Valooto"] = "Valooto";
    DataSecurityAppName["Valence"] = "Valence Salesforce Adapter";
    DataSecurityAppName["Validity"] = "Validity";
    DataSecurityAppName["ValueCloud"] = "Value Cloud";
    DataSecurityAppName["Vanta"] = "Vanta";
    DataSecurityAppName["VantaAPI"] = "Vanta API";
    DataSecurityAppName["VantaEventMonitoring"] = "Vanta Event Monitoring";
    DataSecurityAppName["Varonis"] = "Varonis";
    DataSecurityAppName["VaronisCloud"] = "Varonis DA Cloud";
    DataSecurityAppName["Veritas"] = "Veritas Integration";
    DataSecurityAppName["Veza"] = "Veza Salesforce";
    DataSecurityAppName["Vidyard"] = "Vidyard";
    DataSecurityAppName["Vista"] = "Vista";
    DataSecurityAppName["VisualAntidote"] = "VisualAntidote";
    DataSecurityAppName["Vitally"] = "Vitally";
    DataSecurityAppName["VivunInc"] = "Vivun Inc";
    DataSecurityAppName["Vocalo"] = "Vocalo";
    DataSecurityAppName["Vonage"] = "Vonage";
    DataSecurityAppName["VonageAutoDialer"] = "Vonage AutoDialer";
    DataSecurityAppName["VonageIntegrationSuite"] = "Vonage Integration Suite";
    DataSecurityAppName["WalkMe"] = "WalkMe Reports Sync";
    DataSecurityAppName["WaveWeb"] = "Wave Web";
    DataSecurityAppName["WebinfinityPRM"] = "Webinfinity PRM";
    DataSecurityAppName["WFMConnected"] = "WFM Connected App";
    DataSecurityAppName["WhatfixCrawler"] = "Whatfix Crawler App";
    DataSecurityAppName["WingSecurity"] = "Wing Security";
    DataSecurityAppName["Wootric"] = "Wootric";
    DataSecurityAppName["Workato"] = "Workato";
    DataSecurityAppName["WorkatoConnector"] = "Workato connector";
    DataSecurityAppName["Workbench"] = "Workbench";
    DataSecurityAppName["WorkRamp"] = "WorkRamp";
    DataSecurityAppName["WorkRampLegacy"] = "WorkRamp (Legacy)";
    DataSecurityAppName["Xactly"] = "Exactly";
    DataSecurityAppName["Xactly2"] = "Xactly";
    DataSecurityAppName["XactlyExpress"] = "Xactly Express";
    DataSecurityAppName["Xappex"] = "Xappex";
    DataSecurityAppName["XappexConnector"] = "Xappex G-Connector for Salesforce";
    DataSecurityAppName["XappexConnectorLite"] = "Xappex G-Connector for Salesforce Lite";
    DataSecurityAppName["XLConnector"] = "XL-Connector";
    DataSecurityAppName["XLConnector365"] = "XL-Connector 365";
    DataSecurityAppName["XLConnectorTeamPlayer"] = "XL-Connector Team Player";
    DataSecurityAppName["Xplenty"] = "Xplenty";
    DataSecurityAppName["Yaypay"] = "Yaypay Integration";
    DataSecurityAppName["Yesware"] = "Yesware";
    DataSecurityAppName["YoCierge"] = "YoCierge";
    DataSecurityAppName["zAgileConnect"] = "zAgileConnect";
    DataSecurityAppName["Zapier"] = "Zapier";
    DataSecurityAppName["Zendesk"] = "Zendesk";
    DataSecurityAppName["ZendeskIntegration"] = "Salesforce Integration for Zendesk";
    DataSecurityAppName["Zenkraft"] = "Zenkraft";
    DataSecurityAppName["ZenKraftBulk"] = "ZenKraft Bulk";
    DataSecurityAppName["ZigPR"] = "ZigPR";
    DataSecurityAppName["Zoom"] = "Zoom";
    DataSecurityAppName["ZoomContactCenter"] = "Zoom Contact Center Oauth";
    DataSecurityAppName["ZoomIntegrations"] = "Zoom Integrations Oauth";
    DataSecurityAppName["ZoomInfo"] = "ZoomInfo";
    DataSecurityAppName["ZoomInfoIntegration"] = "ZoomInfo Integration";
    DataSecurityAppName["Zuora"] = "Zuora";
    DataSecurityAppName["Zuora360"] = "Zuora 360";
    DataSecurityAppName["ZuoraCRM"] = "Zuora CRM ID Provisioning Service";
    DataSecurityAppName["ZuoraQuotes"] = "Zuora Quotes";
    DataSecurityAppName["Zuoraz360"] = "Zuora z360";
    DataSecurityAppName["Zuorazforce"] = "zuora/zforce/";
    DataSecurityAppName["Zylo"] = "Zylo";
    DataSecurityAppName["ZyloIntegration"] = "Zylo Integration";
    DataSecurityAppName["ZynSync"] = "ZynSync for Salesforce";
})(DataSecurityAppName || (exports.DataSecurityAppName = DataSecurityAppName = {}));
