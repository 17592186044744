"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkFollwCheckbox = void 0;
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const useUpdateFollowing_1 = require("@hooks/followers/useUpdateFollowing");
const react_1 = __importStar(require("react"));
const BulkFollwCheckbox = ({ onChange, sonarObjectIds, value }) => {
    const { followed, unfollowed } = (0, useUpdateFollowing_1.useUpdateFollowing)(sonarObjectIds);
    const allFollowed = unfollowed.length === 0;
    const allUnfollowed = followed.length === 0;
    const checked = value !== null && value !== void 0 ? value : allFollowed;
    const indeterminate = value === undefined && (!sonarObjectIds || (!allFollowed && !allUnfollowed));
    const stateText = checked ? sonar_ts_constants_1.COPY_SPECIFIC.FOLLOWING : `${sonar_ts_constants_1.COPY_SPECIFIC.NOT} ${sonar_ts_constants_1.COPY_SPECIFIC.FOLLOWING}`;
    const handleOnChange = (0, react_1.useCallback)((event) => {
        onChange(event.target.checked);
    }, [onChange]);
    return (react_1.default.createElement("div", { className: 'bulk-follow-checkbox' },
        react_1.default.createElement(antd_1.Checkbox, { checked: checked, indeterminate: indeterminate, onChange: handleOnChange }, indeterminate ? sonar_ts_constants_1.COPY_SPECIFIC.MIXED : stateText)));
};
exports.BulkFollwCheckbox = BulkFollwCheckbox;
