"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedTag = void 0;
require("./RelatedTag.scss");
const antd_1 = require("antd");
const hooks_1 = require("../../hooks");
const react_1 = __importStar(require("react"));
const RelatedTag = ({ displayName, sonarObjectId, sonarObjectType }) => {
    const { openPeriscope } = (0, hooks_1.usePeriscopeActions)();
    const hasSonarObjectId = Number.isInteger(sonarObjectId);
    const handleTagClick = (0, react_1.useCallback)((e) => {
        e.stopPropagation();
        if (hasSonarObjectId && sonarObjectType) {
            openPeriscope({
                id: sonarObjectId,
                name: displayName,
                openedFromIndex: -1,
                type: sonarObjectType
            });
        }
    }, [displayName, hasSonarObjectId, openPeriscope, sonarObjectId, sonarObjectType]);
    const onClick = hasSonarObjectId ? handleTagClick : undefined;
    return (react_1.default.createElement("div", { className: 'related-tag salesforce' },
        react_1.default.createElement(antd_1.Tag, { onClick: onClick }, displayName)));
};
exports.RelatedTag = RelatedTag;
