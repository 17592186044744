"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncView = void 0;
require("./SyncView.scss");
const sonar_core_1 = require("@sonar-software/sonar-core");
const general_1 = require("@ui/general");
const react_router_dom_1 = require("react-router-dom");
const general_2 = require("../../general");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const SyncView = ({ children, pre, title }) => {
    var _a, _b;
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { status, resetSync } = (0, _hooks_1.useSalesforceSync)(true);
    const { updateProgress } = (0, _hooks_1.useOnboardingProgress)();
    const refreshOrgs = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreOrganizationsAll);
    (0, react_1.useEffect)(() => {
        if ((status === null || status === void 0 ? void 0 : status.status) === sonar_ts_constants_1.ImportStatus.Completed) {
            refreshOrgs();
            resetSync();
            updateProgress({
                currentlyRunningSfdcImportId: undefined,
                isSalesforceSynced: true,
                latestSuccessfulSfdcImportId: status.id
            });
            navigate('/', { replace: true });
        }
    }, [navigate, refreshOrgs, resetSync, status === null || status === void 0 ? void 0 : status.id, status === null || status === void 0 ? void 0 : status.status, updateProgress]);
    return (react_1.default.createElement(general_2.GatewayContent, null,
        react_1.default.createElement(general_2.GatewayCard, { className: 'sync-view', pre: pre, title: title },
            children,
            react_1.default.createElement(general_1.LoadingSubmarine, null),
            react_1.default.createElement("p", { className: 'sync-status' }, (_b = (_a = status === null || status === void 0 ? void 0 : status.logs[0]) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : sonar_ts_constants_1.ONBOARDING_SYNC_COPY.CONNECTING_FIRST_TIME),
            react_1.default.createElement("div", { className: 'sub-info' }, sonar_ts_constants_1.ONBOARDING_SYNC_COPY.CLOSE_THIS_TAB))));
};
exports.SyncView = SyncView;
