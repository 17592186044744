"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativesTableActionBar = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./InitiativesTableActionBar.scss");
const modals_1 = require("@ui/modals");
const StatusSelector_1 = require("../StatusSelector");
const general_1 = require("@ui/general");
const UserSelector_1 = require("@ui/UserSelector");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const InitiativesTableActionBar = ({ selectionData, onUpdate, queryParams }) => {
    var _a;
    const { selectAllEnabled, selectedKeys, selectedCount, resetSelection } = selectionData;
    const { id: userId } = (0, _hooks_1.useCurrentUser)();
    const jiraIntegration = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.JiraIntegration);
    const deleteInitiatives = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreInitiatives);
    const deleteBulkInitiatives = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreInitiativesBulk);
    const updateInitiatives = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiatives);
    const updateBulkInitiatives = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiativesBulk);
    const assignedTos = (_a = queryParams.assignedTos) !== null && _a !== void 0 ? _a : [];
    const assignedToMe = assignedTos.length === 1 && assignedTos[0] === userId;
    const handleOnSearch = (0, react_1.useCallback)((value) => {
        onUpdate({ title: value });
    }, [onUpdate]);
    const handleAssignToMeChange = (0, react_1.useCallback)(() => {
        onUpdate({ assignedTos: assignedToMe ? [] : [userId] });
    }, [assignedToMe, onUpdate, userId]);
    const handleBulkChange = (0, react_1.useCallback)((changes) => {
        const initiativeIds = selectedKeys.map((id) => Number(id));
        if (selectAllEnabled) {
            updateBulkInitiatives(Object.assign(Object.assign({}, queryParams), { initiativeIdsToExclude: initiativeIds, patchInitiativeDescription: Object.assign({}, changes) }));
        }
        else {
            updateInitiatives(Object.assign(Object.assign({}, queryParams), { initiativeIds, patchInitiativeDescription: Object.assign({}, changes) }));
        }
        resetSelection();
    }, [queryParams, resetSelection, selectAllEnabled, selectedKeys, updateBulkInitiatives, updateInitiatives]);
    const handleBulkArchive = (0, react_1.useCallback)(() => {
        const initiativeIds = selectedKeys.map((id) => Number(id));
        if (selectAllEnabled) {
            deleteBulkInitiatives(Object.assign(Object.assign({}, queryParams), { initiativeIdsToExclude: initiativeIds }));
        }
        else {
            deleteInitiatives({ initiativeIds });
        }
        resetSelection();
    }, [deleteBulkInitiatives, deleteInitiatives, queryParams, resetSelection, selectAllEnabled, selectedKeys]);
    return (react_1.default.createElement("div", { className: 'initiatives-table-action-bar' },
        react_1.default.createElement(antd_1.Space, { size: Number(css.size16) },
            react_1.default.createElement(general_1.SearchInput, { debounceDelay: sonar_ts_constants_1.INITIATIVES_SEARCH_SETTINGS.SEARCH_DELAY, id: 'initiatives-table-search', onChange: handleOnSearch, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_BY_TITLE }),
            react_1.default.createElement(antd_1.Checkbox, { checked: assignedToMe, id: 'initiative-table-self-assigned-filter', onChange: handleAssignToMeChange }, sonar_ts_constants_1.COPY_SPECIFIC.ASSIGNED_TO_ME)),
        react_1.default.createElement(react_if_1.When, { condition: selectedCount },
            react_1.default.createElement(antd_1.Space, { size: Number(css.size24) },
                react_1.default.createElement(modals_1.ConfirmDeleteButton, { buttonId: 'initiatives-table-archive-button', buttonLabel: sonar_ts_constants_1.COPY_SPECIFIC.ARCHIVE, cancelButtonProps: {
                        id: 'initiatives-table-archive-cancel-button'
                    }, confirmButtonProps: {
                        id: 'initiatives-table-archive-confirm-button',
                        value: sonar_ts_constants_1.COPY_SPECIFIC.ARCHIVE
                    }, onConfirm: handleBulkArchive, title: sonar_ts_constants_1.COPY_SPECIFIC.ARE_YOU_SURE_ARCHIVE_INITIATVES.replace('{0}', String(selectedCount)), type: 'link' }),
                react_1.default.createElement(react_if_1.When, { condition: !jiraIntegration },
                    react_1.default.createElement(StatusSelector_1.InitiativeStatusSelector, { bordered: false, defaultValue: sonar_ts_constants_1.COPY_SPECIFIC.CHANGE_STATUS, id: 'initiatives-table-status-select', onChange: (statusId) => handleBulkChange({ initiativeStatusId: statusId }), showArrow: false })),
                react_1.default.createElement(UserSelector_1.UserSelector, { id: 'initiatives-table-user-select', onChange: (id) => handleBulkChange({ assignedTo: Number(id) }), placeholder: sonar_ts_constants_1.COPY_SPECIFIC.CHANGE_OWNER, selectionRequired: true })))));
};
exports.InitiativesTableActionBar = InitiativesTableActionBar;
