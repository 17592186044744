"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotList = void 0;
require("./PardotList.scss");
const events_1 = require("@lib/events");
const records_1 = require("@ui/records");
const react_1 = __importStar(require("react"));
const PardotList = ({ entity, onListClick }) => {
    const { displayName, sonarObjectType } = entity;
    const handleOnClick = (0, react_1.useMemo)(() => (0, events_1.ifClickHandler)(onListClick, entity), [entity, onListClick]);
    return (react_1.default.createElement("div", { className: 'pardot-list' },
        react_1.default.createElement(records_1.SonarRecord, { name: displayName, onClick: handleOnClick, sonarObjectId: entity.sonarObjectId, sonarObjectType: sonarObjectType })));
};
exports.PardotList = PardotList;
