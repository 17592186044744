"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImpersonatorNotice = void 0;
require("./ImpersonatorNotice.scss");
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const selectors_1 = require("@lib/selectors");
const react_router_1 = require("react-router");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const hooks_1 = require("../hooks");
const impersonateStopSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.authentication.impersonateStop.get);
const ImpersonatorNotice = () => {
    const [search, setSearch] = (0, react_1.useState)('');
    const navigate = (0, react_router_1.useNavigate)();
    const { isImpersonator, impersonatorShard } = (0, hooks_1.useAuthToken)();
    const impersonateStopToken = (0, hooks_1.useSonarSelector)(impersonateStopSelectors.data);
    const getImpersonateStop = (0, hooks_1.useAction)(sonar_admin_1.adminActions.requestGetAdminAuthenticationImpersonateStop);
    const handleOnExit = (0, react_1.useCallback)(() => {
        getImpersonateStop({ shardName: impersonatorShard });
    }, [getImpersonateStop, impersonatorShard]);
    const handleOnSwitch = (0, react_1.useCallback)(() => {
        setSearch(`?${sonar_ts_constants_1.QUERY.TARGET_ROUTE}=${sonar_ts_constants_1.BASE_ROUTES.ADMIN}`);
        handleOnExit();
    }, [handleOnExit]);
    (0, react_1.useEffect)(() => {
        if (impersonateStopToken) {
            navigate({
                pathname: `/token/${impersonateStopToken.token}`,
                search
            }, { replace: true });
        }
    }, [impersonateStopToken, navigate, search]);
    if (!isImpersonator) {
        return null;
    }
    return (react_1.default.createElement(antd_1.Alert, { action: react_1.default.createElement(antd_1.Flex, { gap: 4 },
            react_1.default.createElement(antd_1.Button, { danger: true, onClick: handleOnSwitch, size: 'small' }, sonar_ts_constants_1.COPY_SPECIFIC.SWITCH),
            react_1.default.createElement(antd_1.Button, { danger: true, onClick: handleOnExit, size: 'small' }, sonar_ts_constants_1.COPY_SPECIFIC.EXIT)), className: 'impersonator-notice', message: sonar_ts_constants_1.COPY_SPECIFIC.ADMIN_IMPERSONATOR_MODE, showIcon: true, type: 'error' }));
};
exports.ImpersonatorNotice = ImpersonatorNotice;
