"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scopeOrder = void 0;
exports.useInitiativeScopeCounts = useInitiativeScopeCounts;
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const SearchItems_1 = require("@sidebar/SearchItems");
const useInitiativeReference_1 = require("./useInitiativeReference");
const react_1 = require("react");
const [objectType, fieldType, ...allOtherTypes] = SearchItems_1.targetObjectTypeOrder;
exports.scopeOrder = [fieldType, objectType, sonar_ts_types_1.SonarObjectType.SalesforcePicklistValue, ...allOtherTypes];
function useInitiativeScopeCounts(initiativeId) {
    const counts = (0, useInitiativeReference_1.useInitiativeReference)(initiativeId).scopeCounts;
    const [totalCount, scopeCounts] = (0, react_1.useMemo)(() => {
        var _a;
        let total = 0;
        const typeCounts = [];
        for (const typeEntry of exports.scopeOrder) {
            const count = (_a = counts[typeEntry]) !== null && _a !== void 0 ? _a : 0;
            if (count) {
                total += count;
                typeCounts.push([typeEntry, count]);
            }
        }
        return [total, typeCounts];
    }, [counts]);
    return {
        counts: scopeCounts,
        totalCount
    };
}
