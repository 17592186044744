"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedDataDictionary = void 0;
const icons_1 = require("@ui/icons");
const SharedDataDictionaryTable_1 = require("./SharedDataDictionaryTable");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const SharedDataDictionary = ({ data, loading, viewKey }) => {
    const requestView = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataDictionaryViewsSharedKey);
    const initialParams = (0, react_1.useMemo)(() => ({
        count: 10,
        filterByName: undefined,
        sharedKey: viewKey
    }), [viewKey]);
    const handleUpdate = (0, react_1.useCallback)((_, __, combined) => {
        requestView(combined);
    }, [requestView]);
    const { updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: initialParams,
        onUpdate: handleUpdate
    });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(SharedDataDictionaryTable_1.SharedDataDictionaryTable, { loading: loading, resource: data, updateQuery: updateParams }),
        react_1.default.createElement(icons_1.PoweredBySonar, { className: 'shared-view-footer-logo' })));
};
exports.SharedDataDictionary = SharedDataDictionary;
