"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataDictionarySearch = exports.sonarObjectTypeOrder = exports.SearchOptionGroup = void 0;
require("./DataDictionarySearch.scss");
const applications_1 = require("@ui/applications");
const icons_1 = require("@ui/icons");
const selectors_1 = require("@lib/selectors");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const QuickAccessViewButton_1 = require("./QuickAccessViewButton");
const icons_2 = require("@ant-design/icons");
const SearchTypeLabel_1 = require("./SearchTypeLabel");
const ShowMoreTypeLabel_1 = require("./ShowMoreTypeLabel");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const tags_1 = require("@ui/tags");
const text_1 = require("@ui/text");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
var SearchOptionGroup;
(function (SearchOptionGroup) {
    SearchOptionGroup["Application"] = "application";
    SearchOptionGroup["Compliance"] = "complianceCategory";
    SearchOptionGroup["Object"] = "object";
    SearchOptionGroup["ObjectType"] = "objectType";
    SearchOptionGroup["Tag"] = "tag";
    SearchOptionGroup["View"] = "view";
})(SearchOptionGroup || (exports.SearchOptionGroup = SearchOptionGroup = {}));
exports.sonarObjectTypeOrder = [
    sonar_ts_types_1.SonarObjectType.SalesforceEntity,
    sonar_ts_types_1.SonarObjectType.SalesforceField,
    sonar_ts_types_1.SonarObjectType.SalesforceWorkflowRule,
    sonar_ts_types_1.SonarObjectType.SalesforceFieldUpdate,
    sonar_ts_types_1.SonarObjectType.SalesforceWorkflowAlert,
    sonar_ts_types_1.SonarObjectType.SalesforceValidationRule,
    sonar_ts_types_1.SonarObjectType.SalesforceProcessBuilder,
    sonar_ts_types_1.SonarObjectType.SalesforceFlow,
    sonar_ts_types_1.SonarObjectType.SalesforceApexClass,
    sonar_ts_types_1.SonarObjectType.SalesforceApexTrigger,
    sonar_ts_types_1.SonarObjectType.SalesforceProfile,
    sonar_ts_types_1.SonarObjectType.SalesforcePageLayout,
    sonar_ts_types_1.SonarObjectType.SalesforceEmailTemplate
];
const getUniqueOption = (option) => {
    var _a, _b;
    return (Object.assign(Object.assign({}, option), { filterString: (_a = option.filterString) !== null && _a !== void 0 ? _a : String(option.label), key: (_b = option.key) !== null && _b !== void 0 ? _b : `${option.group}-${option.value}`, rawValue: option.value, value: `${option.group}-${option.value}` }));
};
const typeOptions = Object.values(exports.sonarObjectTypeOrder).map((type) => getUniqueOption({
    group: SearchOptionGroup.ObjectType,
    label: (0, sonar_ts_lib_1.getSonarObjectTypeLabelPlural)(type),
    value: type
}));
const complianceCategories = Object.entries(sonar_core_1.ComplianceCategory).filter(([, id]) => typeof id === 'number');
function getSortedDataKeys(data) {
    if (!data) {
        return [];
    }
    const keys = Object.keys(data).filter((item) => [exports.sonarObjectTypeOrder[0]].indexOf(item) > -1);
    return keys.sort((a, b) => [exports.sonarObjectTypeOrder[0]].indexOf(a) - [exports.sonarObjectTypeOrder[0]].indexOf(b));
}
const buildOptionsGroup = (group, groupLabel, showMore, allOptions = [], mapOption) => {
    const { show, toggle } = showMore(group);
    const options = (show ? allOptions : allOptions.slice(0, 10))
        .map((option) => getUniqueOption(Object.assign(Object.assign({}, mapOption(option)), { group })));
    const label = typeof groupLabel === 'string' ?
        react_1.default.createElement(ShowMoreTypeLabel_1.ShowMoreTypeLabel, { allowShowMore: !show && allOptions.length > 10, label: groupLabel, onClick: toggle }) : groupLabel;
    if (groupLabel) {
        return {
            label,
            options
        };
    }
    return options;
};
const renderOptions = ({ data: option }) => {
    var _a;
    switch (option.group) {
        case SearchOptionGroup.Application:
            return react_1.default.createElement(applications_1.AppDisplay, { appId: Number(option.rawValue) });
        case SearchOptionGroup.Tag:
            return react_1.default.createElement(tags_1.SonarTagDisplay, { noClick: true, tagId: Number(option.rawValue) });
        case SearchOptionGroup.View:
            return react_1.default.createElement(QuickAccessViewButton_1.QuickAccessViewButton, { viewId: Number(option.rawValue) });
        case SearchOptionGroup.ObjectType:
            return react_1.default.createElement(SearchTypeLabel_1.SearchTypeLabel, { sonarObjectType: option.rawValue });
        case SearchOptionGroup.Compliance:
            return react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: Number(option.rawValue) });
        default:
            return (_a = option.label) !== null && _a !== void 0 ? _a : '';
    }
};
const searchSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataDictionary.search.get);
const DataDictionarySearch = ({ queryParams, updateQuery }) => {
    var _a;
    const [query, setQuery] = (0, react_1.useState)((_a = queryParams.displayName) !== null && _a !== void 0 ? _a : '');
    const [objectTypeFilter, setObjectTypeFilter] = (0, react_1.useState)();
    const [shouldShowMore, setShouldShowMore] = (0, react_1.useState)({});
    const showMore = (0, react_1.useCallback)((group) => ({
        show: Boolean(shouldShowMore[group]),
        toggle: () => setShouldShowMore((prevState) => (Object.assign(Object.assign({}, prevState), { [group]: !prevState[group] })))
    }), [shouldShowMore]);
    const isLoading = (0, _hooks_1.useSonarSelector)(searchSelectors.isRequested);
    const results = (0, _hooks_1.useSonarSelector)(searchSelectors.data);
    const { relatedCounts } = (0, _hooks_1.useLatestImportCounts)(true);
    const views = (0, _hooks_1.useCustomViews)();
    const tags = (0, _hooks_1.useTags)();
    const apps = (0, _hooks_1.useDataSecurityApps)();
    const getSearch = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataDictionarySearch);
    const resetPeriscopes = (0, _hooks_1.usePeriscopeReset)();
    const { resetData, resetSearch } = (0, _hooks_1.useDataDictionaryReset)();
    const handleSearchReset = () => {
        resetSearch();
        setQuery('');
        setObjectTypeFilter(undefined);
        setShouldShowMore({});
    };
    const cleanupState = () => {
        handleSearchReset();
        resetData();
    };
    const handleOnClear = () => {
        cleanupState();
        resetPeriscopes();
        updateQuery({});
    };
    (0, _hooks_1.useOnUnmount)(cleanupState);
    (0, react_1.useEffect)(() => {
        if ((query === null || query === void 0 ? void 0 : query.length) >= 3) {
            getSearch({
                maxResultsPerType: objectTypeFilter ? sonar_ts_constants_1.CATEGORY_SEARCH_LIMIT : sonar_ts_constants_1.QUICK_SEARCH_LIMIT,
                objectTypeFilters: objectTypeFilter ?
                    [(0, sonar_ts_lib_1.getSonarObjectTypeId)(objectTypeFilter)] :
                    undefined,
                queryString: query
            });
        }
    }, [objectTypeFilter, query, getSearch]);
    const handleOnSearch = (value) => {
        const cleanValue = value === null || value === void 0 ? void 0 : value.trim();
        setQuery(cleanValue);
        if (!(cleanValue === null || cleanValue === void 0 ? void 0 : cleanValue.length) || (!objectTypeFilter && results !== null)) {
            resetSearch();
        }
    };
    const handleOnSelect = (_option, item) => {
        var _a, _b;
        resetPeriscopes();
        const { label, group, rawValue } = item;
        if (group === SearchOptionGroup.View) {
            const viewParams = views === null || views === void 0 ? void 0 : views.find((view) => view.id === Number(rawValue));
            const viewDisplayName = (_a = viewParams === null || viewParams === void 0 ? void 0 : viewParams.sonarObjectName) !== null && _a !== void 0 ? _a : (_b = apps === null || apps === void 0 ? void 0 : apps.find((app) => app.applicationId === (viewParams === null || viewParams === void 0 ? void 0 : viewParams.applicationId))) === null || _b === void 0 ? void 0 : _b.name;
            const sonarObjectType = (viewParams === null || viewParams === void 0 ? void 0 : viewParams.sonarObjectTypeId) ?
                (0, sonar_ts_lib_1.getSonarObjectTypeFromId)(viewParams === null || viewParams === void 0 ? void 0 : viewParams.sonarObjectTypeId) : undefined;
            updateQuery({
                applicationId: viewParams === null || viewParams === void 0 ? void 0 : viewParams.applicationId,
                complianceCategory: viewParams === null || viewParams === void 0 ? void 0 : viewParams.complianceCategory,
                displayName: viewDisplayName,
                sonarObjectId: viewParams === null || viewParams === void 0 ? void 0 : viewParams.sonarObjectId,
                sonarObjectType,
                tagId: viewParams === null || viewParams === void 0 ? void 0 : viewParams.tagId,
                viewId: viewParams === null || viewParams === void 0 ? void 0 : viewParams.id,
                viewName: viewParams === null || viewParams === void 0 ? void 0 : viewParams.name
            });
        }
        else {
            updateQuery({
                applicationId: group === SearchOptionGroup.Application ? Number(rawValue) : undefined,
                complianceCategory: group === SearchOptionGroup.Compliance ? Number(rawValue) : undefined,
                displayName: String(label),
                sonarObjectId: group === SearchOptionGroup.Object ? Number(rawValue) : undefined,
                sonarObjectType: group === SearchOptionGroup.ObjectType ? rawValue : undefined,
                tagId: group === SearchOptionGroup.Tag ? Number(rawValue) : undefined,
                viewId: undefined,
                viewName: undefined
            });
        }
    };
    const options = (0, react_1.useMemo)(() => [
        ...typeOptions.map((option) => (Object.assign(Object.assign({}, option), { disabled: !(relatedCounts === null || relatedCounts === void 0 ? void 0 : relatedCounts[option.rawValue]) }))),
        ...Object.keys(results !== null && results !== void 0 ? results : [])
            .filter((sonarObjectType) => getSortedDataKeys(results).includes(sonarObjectType))
            .map((sonarObjectType) => {
            var _a;
            const typeResults = (_a = results === null || results === void 0 ? void 0 : results[sonarObjectType]) !== null && _a !== void 0 ? _a : [];
            const label = react_1.default.createElement(ShowMoreTypeLabel_1.ShowMoreTypeLabel, { allowShowMore: !objectTypeFilter, onClick: () => setObjectTypeFilter(sonarObjectType), sonarObjectType: sonarObjectType });
            return buildOptionsGroup(SearchOptionGroup.Object, label, showMore, typeResults, (result) => ({
                label: result.displayName,
                value: result.sonarObjectId
            }));
        }),
        buildOptionsGroup(SearchOptionGroup.View, sonar_ts_constants_1.COPY_SPECIFIC.SAVED_VIEWS, showMore, views, (view) => ({
            label: view.name,
            value: view.id
        })),
        buildOptionsGroup(SearchOptionGroup.Tag, sonar_ts_constants_1.COPY_SPECIFIC.TAGS, showMore, tags, (tag) => ({
            label: tag.name,
            value: tag.id
        })),
        buildOptionsGroup(SearchOptionGroup.Application, sonar_ts_constants_1.COPY_SPECIFIC.APPLICATIONS, showMore, apps, (app) => ({
            label: app.name,
            value: app.applicationId
        })),
        buildOptionsGroup(SearchOptionGroup.Compliance, sonar_ts_constants_1.COPY_SPECIFIC.COMPLIANCE, showMore, complianceCategories, ([category, id]) => ({
            label: category,
            value: id
        }))
    ], [apps, relatedCounts, objectTypeFilter, results, showMore, tags, views]);
    const renderValue = (0, react_1.useMemo)(() => {
        const { applicationId, complianceCategory, displayName, sonarObjectId, sonarObjectType, tagId } = queryParams !== null && queryParams !== void 0 ? queryParams : {};
        if (sonarObjectType) {
            return getUniqueOption({
                group: SearchOptionGroup.ObjectType,
                label: react_1.default.createElement(SearchTypeLabel_1.SearchTypeLabel, { sonarObjectType: sonarObjectType }),
                value: sonarObjectType
            });
        }
        else if (sonarObjectId && displayName) {
            return getUniqueOption({
                group: SearchOptionGroup.Object,
                label: displayName,
                value: sonarObjectId
            });
        }
        else if (applicationId && displayName) {
            return getUniqueOption({
                group: SearchOptionGroup.Application,
                label: displayName,
                value: applicationId
            });
        }
        else if (tagId) {
            return getUniqueOption({
                group: SearchOptionGroup.Tag,
                label: react_1.default.createElement(tags_1.SonarTagDisplay, { noClick: true, tagId: tagId }),
                value: tagId
            });
        }
        else if (typeof complianceCategory === 'number') {
            let overrideLabel = undefined;
            if (complianceCategory === sonar_core_1.ComplianceCategory.None) {
                overrideLabel = react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.NO_COMPLIANCE_CATEGORIES);
            }
            else if (complianceCategory === sonar_core_1.ComplianceCategory.Any) {
                overrideLabel = react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.ANY_COMPLIANCE_CATEGORY);
            }
            return getUniqueOption({
                group: SearchOptionGroup.Compliance,
                label: overrideLabel !== null && overrideLabel !== void 0 ? overrideLabel : react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: complianceCategory }),
                value: complianceCategory
            });
        }
        return null;
    }, [queryParams]);
    return (react_1.default.createElement(antd_1.Space.Compact, { className: 'data-dictionary-search', size: 'large' },
        react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'data-dictionary-select', filterOption: true, labelInValue: true, listHeight: 720, maxLength: 128, notFoundContent: isLoading ? react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null) : null, onClear: handleOnClear, onDropdownVisibleChange: handleSearchReset, onSearch: handleOnSearch, onSelect: handleOnSelect, optionFilterProp: 'filterString', optionLabelProp: 'label', optionRender: renderOptions, options: options, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_DATA_DICTIONARY, showSearch: true, suffixIcon: null, value: renderValue }),
        react_1.default.createElement(antd_1.Button, { icon: react_1.default.createElement(icons_2.SearchOutlined, null), type: 'primary' })));
};
exports.DataDictionarySearch = DataDictionarySearch;
