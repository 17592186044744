"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineSummary = void 0;
require("./Summary.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const TimelineView_1 = require("./TimelineView");
const selectors_1 = require("./@arch/selectors");
const SonarAlert_1 = require("@ui/SonarAlert");
const SonarCard_1 = require("@ui/general/SonarCard");
const TimelineDatePicker_1 = require("./TimelineDatePicker");
const TimelineSynopsis_1 = require("./TimelineSynopsis");
const UserChangesChart_1 = require("./UserChangesChart");
const GroupedChangesChart_1 = require("./GroupedChangesChart");
const react_1 = __importStar(require("react"));
const TimelineFilters_1 = require("./filters/TimelineFilters");
const _hooks_1 = require("@hooks");
const TimelineSummary = ({ onChange, params }) => {
    const data = (0, _hooks_1.useSonarSelector)(selectors_1.getTimelineSummarySelector.data);
    const [highlightedChangeType, setHighlightedChangeType] = (0, react_1.useState)();
    const [selectedChangeType, setSelectedChangeType] = (0, react_1.useState)();
    const clearSelectedChangeType = (0, react_1.useCallback)(() => {
        setSelectedChangeType(undefined);
    }, []);
    const clearFilters = (0, react_1.useCallback)(() => {
        onChange(Object.assign(Object.assign({}, params), TimelineView_1.defaultTimelineFilterParams));
    }, [onChange, params]);
    const hasFiltersApplied = (0, react_1.useMemo)(() => {
        const { applicationIds, tagIds, objectFilter, modifiedByUsernames, system, objectNameFilter, isFollowing } = params;
        return Boolean(objectNameFilter ||
            !system ||
            (modifiedByUsernames === null || modifiedByUsernames === void 0 ? void 0 : modifiedByUsernames.length) ||
            Object.keys(objectFilter !== null && objectFilter !== void 0 ? objectFilter : {}).length ||
            isFollowing !== undefined ||
            (applicationIds === null || applicationIds === void 0 ? void 0 : applicationIds.length) ||
            (tagIds === null || tagIds === void 0 ? void 0 : tagIds.length));
    }, [params]);
    const extra = react_1.default.createElement(TimelineDatePicker_1.TimelineDatePicker, { endDate: params.end, onChange: onChange, startDate: params.start });
    return (react_1.default.createElement(SonarCard_1.SonarCard, { bordered: false, extra: data ? extra : null, id: 'timeline-summary', loading: !data, noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.TIMELINE_SUMMARY },
        react_1.default.createElement(SonarAlert_1.SonarAlert, { active: hasFiltersApplied, afterClose: clearFilters, closable: true, closeText: sonar_ts_constants_1.COPY_SPECIFIC.CLEAR_FILTERS, message: sonar_ts_constants_1.COPY_SPECIFIC.FILTERS_APPLIED, showIcon: true, type: 'info' }),
        react_1.default.createElement("div", { className: 'summary-sections' },
            react_1.default.createElement(TimelineFilters_1.TimelineFilters, { clearSelectedChangeType: clearSelectedChangeType, highlightedChangeType: highlightedChangeType, onChange: onChange, params: params, selectedChangeType: selectedChangeType }),
            react_1.default.createElement("div", { className: 'charts-and-stats' },
                react_1.default.createElement("div", { className: 'top-section' },
                    react_1.default.createElement(TimelineSynopsis_1.TimelineSynopsis, { hideSystem: !(params === null || params === void 0 ? void 0 : params.system), onChange: onChange, onChangeTypeClicked: setSelectedChangeType, onChangeTypeHovered: setHighlightedChangeType }),
                    react_1.default.createElement(UserChangesChart_1.UserChangesChart, { modifiedBy: params === null || params === void 0 ? void 0 : params.modifiedByUsernames, onChange: onChange })),
                react_1.default.createElement(GroupedChangesChart_1.GroupedChangesChart, { grouping: params.timelineGroupingType, onChange: onChange })))));
};
exports.TimelineSummary = TimelineSummary;
