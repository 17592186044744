"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerView = void 0;
require("./CustomerView.scss");
const notifications_1 = require("@ui/notifications");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const CustomerViewExtra_1 = require("./CustomerViewExtra");
const CustomerViewTitle_1 = require("./CustomerViewTitle");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const ShardCatalogSearch_1 = require("../../ShardCatalogSearch");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const antd_1 = require("antd");
const panels_1 = require("./panels");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const defaultShardCatalogDetailSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.search.shardCatalog.details.default.get);
const getCustomerSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.customers.byId.get);
const patchCustomerSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.customers.byId.patch);
const postLockAllUsersSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.customers.byId.lockAllUsers.post);
const CustomerView = () => {
    const [selectedSearchDetails, setSelectedSearchDetails] = (0, react_1.useState)(undefined);
    const patchCustomerIsReceived = (0, _hooks_1.useSonarSelector)(patchCustomerSelectors.isReceived);
    const defaultShardCatalogDetail = (0, _hooks_1.useSonarSelector)(defaultShardCatalogDetailSelectors.data);
    const getDefaultShardCatalogDetail = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestGetAdminSearchShardCatalogDetailsDefault);
    const customer = (0, _hooks_1.useSonarSelector)(getCustomerSelectors.data);
    const getCustomer = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreCustomersById);
    const resetCustomer = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetGetCoreCustomersById);
    const getCustomerForSearch = (0, react_1.useCallback)((details) => {
        if (details) {
            getCustomer({
                customerId: details.customerId,
                shardName: details.shardName
            });
        }
    }, [getCustomer]);
    const handleSearchDetailsChanged = (0, react_1.useCallback)((details) => {
        var _a;
        resetCustomer();
        setSelectedSearchDetails((_a = details !== null && details !== void 0 ? details : defaultShardCatalogDetail) !== null && _a !== void 0 ? _a : undefined);
        if (details) {
            getCustomerForSearch(details);
        }
    }, [defaultShardCatalogDetail, getCustomerForSearch, resetCustomer]);
    (0, react_1.useEffect)(() => {
        if (!defaultShardCatalogDetail) {
            getDefaultShardCatalogDetail();
        }
    }, [getDefaultShardCatalogDetail, defaultShardCatalogDetail]);
    (0, react_1.useEffect)(() => {
        if (!selectedSearchDetails && defaultShardCatalogDetail) {
            setSelectedSearchDetails(defaultShardCatalogDetail);
            getCustomer(defaultShardCatalogDetail);
        }
    }, [defaultShardCatalogDetail, getCustomer, selectedSearchDetails]);
    (0, react_1.useEffect)(() => {
        if (patchCustomerIsReceived && selectedSearchDetails) {
            getCustomerForSearch(selectedSearchDetails);
        }
    }, [patchCustomerIsReceived, resetCustomer, getCustomerForSearch, selectedSearchDetails]);
    const isLoading = !selectedSearchDetails || !customer;
    const title = react_1.default.createElement(CustomerViewTitle_1.CustomerViewTitle, { customer: customer, shardName: selectedSearchDetails === null || selectedSearchDetails === void 0 ? void 0 : selectedSearchDetails.shardName });
    const extra = react_1.default.createElement(CustomerViewExtra_1.CustomerViewExtra, { customer: customer, shardName: selectedSearchDetails === null || selectedSearchDetails === void 0 ? void 0 : selectedSearchDetails.shardName });
    return (react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: isLoading },
        react_1.default.createElement(ShardCatalogSearch_1.ShardCatalogSearch, { id: 'search-shard-catalog', onDetailsChanged: handleSearchDetailsChanged }),
        react_1.default.createElement(antd_1.Card, { bordered: false, className: 'admin-customer-view-card', extra: extra, title: title },
            react_1.default.createElement(antd_1.Collapse, { defaultActiveKey: ['1', '2', '3'], items: [
                    {
                        children: react_1.default.createElement(panels_1.UsersPanel, { searchDetails: selectedSearchDetails }),
                        key: '1',
                        label: sonar_ts_constants_1.COPY_SPECIFIC.USERS
                    }, {
                        children: react_1.default.createElement(panels_1.SalesforceSetupPanel, { searchDetails: selectedSearchDetails }),
                        key: '2',
                        label: sonar_ts_constants_1.COPY_SPECIFIC.SALESFORCE_SETUP
                    }, {
                        children: react_1.default.createElement(panels_1.ConfigurationPanel, { customer: customer, searchDetails: selectedSearchDetails }),
                        key: '3',
                        label: sonar_ts_constants_1.COPY_SPECIFIC.CONFIGURATION
                    }
                ] })),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.UPDATE_CUSTOMER_FAILED, failed: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.UPDATE_CUSTOMER_FAILED, stateSelector: patchCustomerSelectors.state }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.LOCK_ALL_USERS_FAILED, failed: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.LOCK_ALL_USERS_FAILED, stateSelector: postLockAllUsersSelectors.state })));
};
exports.CustomerView = CustomerView;
