"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditTag = void 0;
const css = __importStar(require("css/exports.module.scss"));
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const popups_1 = require("@lib/popups");
const TagDescriptionInput_1 = require("./TagDescriptionInput");
const TagNameInput_1 = require("./TagNameInput");
const TagOwnersInput_1 = require("./TagOwnersInput");
const _hooks_1 = require("@hooks");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const EditTag = ({ tag }) => {
    const [hexColor, setHexColor] = (0, react_1.useState)(tag.hexColor);
    const requestDelete = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreTagsById);
    const requestPut = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPutCoreTagsById);
    const initialFormValues = (0, react_1.useMemo)(() => {
        const { description, ownerIds, name } = tag;
        return {
            description: description !== null && description !== void 0 ? description : '',
            hexColor,
            name,
            ownerIds: ownerIds.map((id) => String(id))
        };
    }, [hexColor, tag]);
    const handleDelete = (0, react_1.useCallback)(() => {
        requestDelete({
            id: tag.id
        });
    }, [tag.id, requestDelete]);
    const handleSubmit = (0, react_1.useCallback)((values) => {
        requestPut(Object.assign(Object.assign({}, values), { hexColor, id: tag.id, ownerIds: values.ownerIds.map((id) => Number(id)) }));
    }, [hexColor, tag.id, requestPut]);
    return (react_1.default.createElement("div", { className: 'tag', key: tag.id },
        react_1.default.createElement(antd_1.Form, { className: 'edit', initialValues: initialFormValues, name: String(tag.id), onFinish: handleSubmit },
            react_1.default.createElement(antd_1.Row, { gutter: [Number(css.size16), 0] },
                react_1.default.createElement(antd_1.Col, { span: 6 },
                    react_1.default.createElement(TagNameInput_1.TagNameInput, { disabled: tag.autoTagged, getPopupContainer: popups_1.getDirectParent, hexColor: hexColor, name: 'name', onColorClick: setHexColor })),
                react_1.default.createElement(antd_1.Col, { span: 18 },
                    react_1.default.createElement(TagOwnersInput_1.TagOwnersInput, { disabled: tag.autoTagged, name: 'ownerIds' }))),
            react_1.default.createElement(TagDescriptionInput_1.TagDescriptionInput, { disabled: tag.autoTagged, name: 'description' }),
            react_1.default.createElement(react_if_1.When, { condition: !tag.autoTagged },
                react_1.default.createElement(antd_1.Row, { justify: 'space-between' },
                    react_1.default.createElement(antd_1.Button, { className: 'tag-save-button', htmlType: 'submit', type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.SAVE),
                    react_1.default.createElement(antd_1.Button, { className: 'tag-delete-button', danger: true, onClick: handleDelete }, sonar_ts_constants_1.COPY_SPECIFIC.DELETE)))),
        react_1.default.createElement(antd_1.Divider, null)));
};
exports.EditTag = EditTag;
