"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SonarTable = void 0;
const NoData_1 = require("./NoData");
const text_1 = require("./text");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const TableComponent = (_a, ref) => {
    var { columns, locale } = _a, rest = __rest(_a, ["columns", "locale"]);
    const managedColumns = (0, react_1.useMemo)(() => columns === null || columns === void 0 ? void 0 : columns.map((column) => (Object.assign(Object.assign({}, column), { ellipsis: false, render: (value, object, index) => {
            const base = column.render ?
                column.render(value, object, index) :
                object[column.dataIndex];
            return column.ellipsis ?
                react_1.default.createElement(text_1.SonarText, { ellipsis: {
                        tooltip: {
                            children: base,
                            placement: 'bottom',
                            showArrow: true
                        }
                    } }, base) :
                base;
        } }))), [columns]);
    return (react_1.default.createElement(antd_1.Table, Object.assign({ locale: Object.assign({ emptyText: react_1.default.createElement(NoData_1.NoData, null) }, locale) }, rest, { columns: managedColumns, ref: ref })));
};
exports.SonarTable = (0, react_1.forwardRef)(TableComponent);
