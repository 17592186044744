"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OidcProviderLoginButton = void 0;
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_http_1 = require("@sonar-software/sonar-http");
const react_1 = __importStar(require("react"));
function getAuthorizationEndpoint(oidcDomain) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const result = yield (0, sonar_http_1.sonarHttpGet)({
            skipSonarHeaders: true,
            targetUri: `${oidcDomain}/.well-known/openid-configuration`,
            uriType: 'absolute'
        });
        return (_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.authorization_endpoint) !== null && _b !== void 0 ? _b : '';
    });
}
function getFullOidcRedirect(options) {
    const { origin } = window.location;
    const { oidcClientID, url } = options;
    const nonce = (0, sonar_ts_lib_1.getRandomString)(10);
    const state = (0, sonar_ts_lib_1.getRandomString)(10);
    return `${url}
			?client_id=${oidcClientID}
			&nonce=${nonce}
			&redirect_uri=${`${origin}${sonar_ts_constants_1.FULL_ROUTES.INTEGRATIONS_OIDC}`}
			&response_type=id_token
			&response_mode=fragment
			&scope=email openid profile
			&state=${state}`;
}
const OidcProviderNames = {
    azure: 'Azure',
    google: 'Google',
    none: 'None',
    okta: 'Okta',
    onelogin: 'OneLogin'
};
const OidcProviderLoginButton = ({ config }) => {
    const [isProcessing, setIsProcessing] = (0, react_1.useState)(false);
    const handleClick = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsProcessing(true);
        const endpoint = yield getAuthorizationEndpoint(config.oidcDomain);
        const redirectTarget = getFullOidcRedirect({
            oidcClientID: config.oidcClientID,
            url: endpoint
        });
        window.location.replace(redirectTarget);
    }), [config.oidcClientID, config.oidcDomain]);
    return (react_1.default.createElement("div", { id: 'oidc-provider' },
        react_1.default.createElement(antd_1.Button, { loading: isProcessing, onClick: handleClick, type: 'primary' }, OidcProviderNames[config.provider])));
};
exports.OidcProviderLoginButton = OidcProviderLoginButton;
