"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkInput = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const { MAX: titleMax, MESSAGE: titleMessage } = sonar_ts_constants_1.LINK_TITLE;
const { MAX: urlMax, MESSAGE: urlMessage } = sonar_ts_constants_1.LINK_URL;
const LinkInput = ({ disabled }) => React.createElement(React.Fragment, null,
    React.createElement(antd_1.Form.Item, { name: 'title', rules: [
            {
                max: titleMax,
                message: titleMessage
            }
        ] },
        React.createElement(antd_1.Input, { autoFocus: true, disabled: disabled, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.LINK_TITLE, type: 'text' })),
    React.createElement(antd_1.Form.Item, { name: 'url', rules: [
            {
                message: urlMessage,
                required: true
            },
            {
                max: urlMax,
                message: sonar_ts_constants_1.MESSAGES_COPY.MAX_LENGTH_EXCEEDED
            }
        ] },
        React.createElement(antd_1.Input, { disabled: disabled, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.LINK_URL })));
exports.LinkInput = LinkInput;
