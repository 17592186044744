"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScopeTypeSubsection = void 0;
require("./ScopeTypeSubsection.scss");
const BulkScopeActionBar_1 = require("./BulkScopeActionBar");
const icons_1 = require("@ant-design/icons");
const DataDictionaryBulkEditModal_1 = require("@dictionary/DataDictionaryBulkEditModal");
const popups_1 = require("@lib/popups");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const tables_1 = require("@ui/tables");
const text_1 = require("@ui/text");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const KeyPagination_1 = require("@ui/KeyPagination");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function getRowKey(row) {
    return row.sonarObjectId;
}
function sanitizeOrdering(value, type) {
    if (type !== sonar_ts_types_1.SonarObjectType.SalesforceField) {
        return value;
    }
    return value.replace('Name~', 'Label~');
}
function sanitizeFiltering(value, type) {
    if (!value) {
        return value;
    }
    if (type === sonar_ts_types_1.SonarObjectType.SalesforceField) {
        return sanitizeOrdering(value, type);
    }
    return value;
}
const ScopeTypeSubsection = ({ count, initiativeId, overrideAutoCollapse, selectNamespace, type, queryParams }) => {
    var _a, _b;
    const [showEditModal, setShowEditModal] = (0, react_1.useState)(false);
    const [togglePanel, setTogglePanel] = (0, react_1.useState)(true);
    const editButtonRef = (0, react_1.useRef)(null);
    const scrollableListRef = (0, react_1.useRef)(null);
    const selectionDataRef = (0, react_1.useRef)(null);
    const previousParams = (0, _hooks_1.usePrevious)(queryParams, queryParams);
    const previousCount = (0, _hooks_1.usePrevious)(count, count);
    const { isEnabled } = (0, _hooks_1.useFeatureFlags)();
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { update } = (0, _hooks_1.useUpdateSonarObjectReference)();
    const scopedNamespace = `${selectNamespace}-${type}`;
    const { queryParams: internalParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: Object.assign(Object.assign({}, KeyPagination_1.keyPaginationDefaultQueryParams), { count: 50, filters: sanitizeFiltering(queryParams.filters, type), ordering: sanitizeOrdering(queryParams.ordering, type) })
    });
    (0, react_1.useEffect)(() => {
        if ((previousParams === null || previousParams === void 0 ? void 0 : previousParams.filters) !== queryParams.filters) {
            updateParams({
                filters: sanitizeFiltering(queryParams.filters, type),
                key: undefined,
                ordering: sanitizeOrdering(queryParams.ordering, type),
                paginationDirection: sonar_ts_types_1.PaginationDirection.Forward
            });
        }
        else if ((previousParams === null || previousParams === void 0 ? void 0 : previousParams.ordering) !== queryParams.ordering) {
            updateParams({
                ordering: sanitizeOrdering(queryParams.ordering, type)
            });
        }
    }, [previousParams, queryParams, type, updateParams]);
    const { loading, request, resource } = (0, _hooks_1.useApiResource)(`v3/initiatives/${initiativeId}/types/${type}/scope`, internalParams);
    const { schema } = (0, react_1.useMemo)(() => { var _a; return (0, lib_1.parseMetadataFromResponse)((_a = resource === null || resource === void 0 ? void 0 : resource.schema) !== null && _a !== void 0 ? _a : [], isEnabled, '', popups_1.getClosestCardBody); }, [isEnabled, resource]);
    selectionDataRef.current = (0, _hooks_1.useSelectionTable)({
        getRowKey,
        namespace: scopedNamespace,
        queryParams: internalParams,
        resourceIdsUri: `v3/initiatives/${initiativeId}/types/${type}/scope/bulk`,
        results: resource === null || resource === void 0 ? void 0 : resource.results,
        selectionType: type
    });
    (0, react_1.useEffect)(() => {
        var _a;
        if (previousCount !== count) {
            (_a = selectionDataRef.current) === null || _a === void 0 ? void 0 : _a.refreshIds();
            request();
        }
    }, [count, previousCount, request]);
    (0, react_1.useEffect)(() => {
        if (resource && resource.results.length) {
            update(resource.results.map((entry) => {
                var _a;
                return ({
                    context: {
                        definition: entry.definition,
                        notes: entry.notes
                    },
                    salesforce: {
                        complianceCategory: entry.complianceCategory || undefined,
                        dataSensitivityLevelId: entry.dataSensitivityLevelId,
                        description: entry.description,
                        fieldUsageId: entry.fieldUsageId,
                        helpText: entry.helpText
                    },
                    sonarObjectId: entry.sonarObjectId,
                    stakeholders: entry.stakeholderIds,
                    tags: entry.tagIds,
                    users: (_a = entry.ownerIds) === null || _a === void 0 ? void 0 : _a.map((userId) => ({
                        responsibilityType: sonar_core_1.ResponsibilityType.Owner,
                        userId
                    }))
                });
            }));
        }
    }, [resource, update]);
    (0, _hooks_1.useOnUnmount)(() => {
        var _a;
        (_a = selectionDataRef.current) === null || _a === void 0 ? void 0 : _a.resetSelection();
    });
    const handleEditModalOpen = (0, react_1.useCallback)((button) => {
        editButtonRef.current = button;
        setShowEditModal(true);
    }, []);
    const handleEditModalCancel = (0, react_1.useCallback)(() => {
        setShowEditModal(false);
    }, []);
    const handleEditModalSuccess = (0, react_1.useCallback)(() => {
        var _a;
        (_a = selectionDataRef.current) === null || _a === void 0 ? void 0 : _a.resetSelection();
        setShowEditModal(false);
    }, []);
    const handleRowClick = (0, react_1.useCallback)((record) => {
        const { name, sonarObjectId: recordSonarObjectId } = record;
        openPeriscope({
            id: recordSonarObjectId,
            name: name,
            openedFromIndex: -1,
            type: type
        });
    }, [openPeriscope, type]);
    (0, react_1.useEffect)(() => {
        if (resource) {
            setTogglePanel(() => { var _a; return overrideAutoCollapse || ((_a = resource.results) === null || _a === void 0 ? void 0 : _a.length) > 0; });
        }
    }, [resource, overrideAutoCollapse]);
    if (!count) {
        return null;
    }
    const header = react_1.default.createElement(text_1.SonarText, { size: 16, weight: 'semibold' }, (0, sonar_ts_lib_1.getTitleWithCount)((_a = (0, sonar_ts_lib_1.getSonarObjectTypeLabelPlural)(type)) !== null && _a !== void 0 ? _a : '', resource === null || resource === void 0 ? void 0 : resource.results.length));
    const panel = react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(tables_1.SelectionTable, { actionBar: react_1.default.createElement(BulkScopeActionBar_1.BulkScopeActionBar, { initiativeId: initiativeId, selectedObjectType: (0, sonar_ts_lib_1.getSonarObjectTypeLabelPlural)(type), selectNamespace: scopedNamespace }), className: 'data-dictionary-table', columns: schema, dataSource: resource === null || resource === void 0 ? void 0 : resource.results, isActionBarAbove: false, loading: loading, onEditSelection: handleEditModalOpen, onRowClick: handleRowClick, rowKey: getRowKey, scroll: {
                scrollToFirstRowOnChange: true,
                x: 'max-content'
            }, selectionData: selectionDataRef.current, selectionType: (0, sonar_ts_lib_1.getSonarObjectTypeLabelPlural)(type) }),
        react_1.default.createElement(KeyPagination_1.KeyPagination, { autoHide: true, defaultPageSize: Number(internalParams.count), listRef: scrollableListRef, onChange: updateParams, resource: resource }));
    const panels = [{
            children: panel,
            id: `scope-type-subsection-${type}`,
            key: type,
            label: header
        }];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(antd_1.Collapse, { activeKey: togglePanel ? type : '', className: 'scope-type-subsection', expandIcon: ({ isActive }) => react_1.default.createElement(icons_1.CaretRightOutlined, { rotate: isActive ? 90 : 0 }), ghost: true, items: panels, onChange: () => setTogglePanel(!togglePanel) }),
        react_1.default.createElement(DataDictionaryBulkEditModal_1.DataDictionaryBulkEditModal, { onCancel: handleEditModalCancel, onOk: handleEditModalSuccess, open: showEditModal, popoverTarget: editButtonRef, selected: (_b = selectionDataRef.current) === null || _b === void 0 ? void 0 : _b.selectedKeys, sonarObjectType: type })));
};
exports.ScopeTypeSubsection = ScopeTypeSubsection;
