"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManageInitiatives = void 0;
require("./ManageInitiatives.scss");
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const InitiativeSelector_1 = require("./InitiativeSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const ManageInitiatives = ({ sonarObjectId }) => {
    const { request: add } = (0, _hooks_1.useAddToInitiative)();
    const { request: remove } = (0, _hooks_1.useRemoveFromInitiative)();
    const { initiativeIds } = (0, _hooks_1.useSonarObjectReference)(sonarObjectId);
    const followersEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.Followers);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const getInitiativesForSonarObject = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSonarObjectsInitiatives);
    (0, _hooks_1.useOnMount)(() => {
        getInitiativesForSonarObject({ sonarObjectId });
    });
    const onSelect = (0, react_1.useCallback)((initiative) => {
        add({
            initiativeId: initiative.id,
            initiativeTitle: initiative.title,
            sonarObjectIds: [sonarObjectId]
        });
    }, [add, sonarObjectId]);
    const onDeselect = (0, react_1.useCallback)((initiative) => {
        remove({
            initiativeId: initiative.id,
            sonarObjectIds: [sonarObjectId]
        });
    }, [remove, sonarObjectId]);
    return (react_1.default.createElement(InitiativeSelector_1.InitiativeSelector, { className: 'manage-initiatives', onDeselect: onDeselect, onSelect: onSelect, selectedIds: initiativeIds },
        react_1.default.createElement(antd_1.Button, { icon: react_1.default.createElement(icons_1.AimOutlined, null) }, followersEnabled && !isReadOnly ? '' : sonar_ts_constants_1.COPY_SPECIFIC.ADD_TO_INITIATIVE)));
};
exports.ManageInitiatives = ManageInitiatives;
