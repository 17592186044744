"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceOrgSyncSecurityAlertsControls = void 0;
require("./SalesforceSetupPanel.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const _hooks_1 = require("@hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const SalesforceOrgSyncSecurityAlertsControls = ({ organization }) => {
    const startSyncSecurityAlerts = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreSyncSecurityAlerts);
    const handleSyncSecurityAlerts = (0, react_1.useCallback)(() => {
        if (organization) {
            startSyncSecurityAlerts({
                organizationId: organization.id
            });
        }
    }, [startSyncSecurityAlerts, organization]);
    return organization ?
        react_1.default.createElement("div", null,
            react_1.default.createElement(antd_1.Button, { className: 'sync-security-alerts-button', onClick: handleSyncSecurityAlerts }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.SYNC_SECURITY_ALERTS)) :
        null;
};
exports.SalesforceOrgSyncSecurityAlertsControls = SalesforceOrgSyncSecurityAlertsControls;
