"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickAccessApps = void 0;
const applications_1 = require("@ui/applications");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const DataDictionarySearch_1 = require("./DataDictionarySearch");
const _hooks_1 = require("@hooks");
const applicationItemsGroup = {
    key: 'title',
    label: sonar_ts_constants_1.COPY_SPECIFIC.APPLICATIONS,
    type: 'group'
};
const QuickAccessApps = ({ onSelect }) => {
    const [showAllApps, setShowAllApps] = (0, _hooks_1.useBoolean)(false);
    const apps = (0, _hooks_1.useDataSecurityApps)(true);
    const applicationItems = (0, react_1.useMemo)(() => [Object.assign(Object.assign({}, applicationItemsGroup), { children: (showAllApps ? apps : apps.slice(0, 10))
                .map(({ applicationId }, index) => ({
                group: DataDictionarySearch_1.SearchOptionGroup.Application,
                key: String(index),
                label: react_1.default.createElement(applications_1.AppDisplay, { appId: applicationId }),
                value: index
            })) })], [apps, showAllApps]);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(antd_1.Menu, { className: 'quick-access-menu', items: applicationItems, mode: 'inline', onClick: (info) => {
                const { applicationId, name } = apps[Number(info.key)];
                onSelect(applicationId, name);
            } }),
        react_1.default.createElement(react_if_1.When, { condition: apps.length > 10 },
            react_1.default.createElement(antd_1.Button, { className: 'show-all', onClick: setShowAllApps.toggle, type: 'link' }, showAllApps ? sonar_ts_constants_1.COPY_GENERIC.SHOW_LESS : sonar_ts_constants_1.COPY_GENERIC.SHOW_ALL))));
};
exports.QuickAccessApps = QuickAccessApps;
