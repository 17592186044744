"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnauthorizedView = void 0;
const forgot_password_1 = require("./forgot-password");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const general_1 = require("./general");
const invitation_1 = require("./invitation");
const integrations_1 = require("./integrations");
const reset_password_1 = require("./reset-password");
const sign_up_1 = require("./sign-up");
const _hooks_1 = require("@hooks");
const selectors_1 = require("@lib/selectors");
const login_1 = require("./login");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const isLoggingOutSelector = (0, selectors_1.createSonarSelector)((state) => state.application.reset.isLoggingOut);
const isLoggingInSelector = (0, selectors_1.createIsReceivedSelector)((state) => state.security.authentication.login.post);
const UnauthorizedView = () => {
    const isLoggingIn = (0, _hooks_1.useSonarSelector)(isLoggingInSelector);
    const isLoggingOut = (0, _hooks_1.useSonarSelector)(isLoggingOutSelector);
    (0, react_1.useEffect)(() => {
        if (isLoggingOut) {
            window.location.reload();
        }
    }, [isLoggingOut]);
    if (isLoggingIn || isLoggingOut) {
        return react_1.default.createElement(general_1.GatewayContent, null);
    }
    return (react_1.default.createElement(general_1.GatewayContent, null,
        react_1.default.createElement(react_router_dom_1.Routes, null,
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(login_1.LoginView, null), path: '/' }),
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(invitation_1.InvitationView, null), path: sonar_ts_constants_1.FULL_ROUTES.INVITATION }),
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(forgot_password_1.ForgotPasswordView, null), path: sonar_ts_constants_1.FULL_ROUTES.FORGOT_PASSWORD }),
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(login_1.LoginView, null), path: sonar_ts_constants_1.FULL_ROUTES.LOGIN }),
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(reset_password_1.ResetPasswordView, null), path: sonar_ts_constants_1.FULL_ROUTES.RESET_PASSWORD }),
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(sign_up_1.SignupView, null), path: sonar_ts_constants_1.FULL_ROUTES.SIGN_UP }),
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(integrations_1.OIDCIntegration, null), path: sonar_ts_constants_1.FULL_ROUTES.INTEGRATIONS_OIDC }),
            react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(login_1.LoginRedirectRoute, null), path: '*' }))));
};
exports.UnauthorizedView = UnauthorizedView;
