"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldUpdateMetadataTab = void 0;
require("./FieldUpdateMetadataTab.scss");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const fields_1 = require("@periscopes/general/fields");
const text_1 = require("@ui/text");
const general_2 = require("@periscopes/general");
const metadata_1 = require("@periscopes/general/metadata");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const FieldUpdateMetadataTab = ({ index, sonarObjectId }) => {
    var _a, _b;
    const updateReferences = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateSonarObjectReferences);
    const { loading, metadata } = (0, _hooks_1.usePeriscopeMetadata)(sonarObjectId, sonar_ts_types_1.SonarObjectType.SalesforceFieldUpdate);
    const { loading: loadingRefs, resource: refsResource } = (0, _hooks_1.useApiResource)(`v3/salesforce/field-updates/${sonarObjectId}/references`);
    const metadataEntries = (0, react_1.useMemo)(() => {
        if (!metadata) {
            return [];
        }
        const entries = [
            {
                label: (0, sonar_ts_lib_1.capitalize)(sonar_ts_constants_1.COPY_SPECIFIC.OBJECT),
                type: 'string',
                value: metadata.object
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.RE_EVALUATE_AFTER_FIELD_CHANGE,
                type: 'boolean',
                value: metadata.shouldReevaluateOnChange
            }
        ];
        return entries;
    }, [metadata]);
    (0, react_1.useEffect)(() => {
        if (refsResource === null || refsResource === void 0 ? void 0 : refsResource.field) {
            updateReferences([
                {
                    displayName: refsResource.field.displayName,
                    ingestedBy: refsResource.field.ingestedBy,
                    parentDisplayName: refsResource.field.parentDisplayName,
                    parentSonarObjectId: refsResource.field.parentSonarObjectId,
                    populatedBy: refsResource.field.populatedBy,
                    relatedCounts: refsResource.field.relatedCounts,
                    sonarObjectId: refsResource.field.sonarObjectId,
                    sonarObjectType: sonar_ts_types_1.SonarObjectType.SalesforceField,
                    tags: refsResource.field.tagIds
                }
            ]);
        }
    }, [refsResource === null || refsResource === void 0 ? void 0 : refsResource.field, updateReferences]);
    if (loading || loadingRefs) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(general_2.InformationSection, { sonarObjectId: sonarObjectId },
            react_1.default.createElement(text_1.SalesforceDescription, { sonarObjectId: sonarObjectId })),
        react_1.default.createElement(general_2.DetailSection, { className: 'field-update-details', spacing: 'small' },
            react_1.default.createElement(metadata_1.MetadataContainer, { entries: metadataEntries, title: sonar_ts_constants_1.COPY_SPECIFIC.WORKFLOW_DETAIL }),
            react_1.default.createElement(general_2.DetailSubsection, { spacing: 'none', title: sonar_ts_constants_1.COPY_SPECIFIC.FIELD_TO_UPDATE },
                react_1.default.createElement(fields_1.PeriscopeFieldRow, { field: refsResource === null || refsResource === void 0 ? void 0 : refsResource.field, parentIndex: index, useApiNames: false })),
            react_1.default.createElement(general_2.DetailSubsection, { spacing: 'none', title: (refsResource === null || refsResource === void 0 ? void 0 : refsResource.isFormula) ? sonar_ts_constants_1.COPY_SPECIFIC.FORMULA : sonar_ts_constants_1.COPY_SPECIFIC.VALUE },
                react_1.default.createElement(general_1.CodeHighlight, { code: (_a = refsResource === null || refsResource === void 0 ? void 0 : refsResource.value) !== null && _a !== void 0 ? _a : '', codeReferences: (_b = refsResource === null || refsResource === void 0 ? void 0 : refsResource.codeReferences) !== null && _b !== void 0 ? _b : [], index: index })))));
};
exports.FieldUpdateMetadataTab = FieldUpdateMetadataTab;
