"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchablePeriscopeFieldGroupListV3 = void 0;
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const _hooks_1 = require("@hooks");
const FieldSortOptions_1 = require("./FieldSortOptions");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const _1 = require(".");
const KeyPagination_1 = require("@ui/KeyPagination");
const react_1 = __importStar(require("react"));
const dqlTransform = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    capitalizeOrderingParams: false,
    filterParams: {
        filterByTags: { name: 'tagIds' },
        query: {
            name: params.useInternal ? 'InternalName' : 'DisplayName',
            operator: sonar_ts_types_1.DynamicQueryOperator.Like
        }
    },
    orderingParams: {
        name: params.useInternal ? 'InternalName' : 'DisplayName'
    }
});
const SearchablePeriscopeFieldGroupListV3 = (_a) => {
    var { mapGroup, uri, useDql = false } = _a, rest = __rest(_a, ["mapGroup", "uri", "useDql"]);
    const [transformedGroups, setTransformedGroups] = (0, react_1.useState)([]);
    const scrollableListRef = (0, react_1.useRef)(null);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)(Object.assign(Object.assign({ defaultParams: Object.assign(Object.assign({}, KeyPagination_1.keyPaginationDefaultQueryParams), { sortBy: FieldSortOptions_1.v3FieldSortOptions[1].key, sortDirection: FieldSortOptions_1.v3FieldSortOptions[1].direction }), fallbackParams: KeyPagination_1.keyPaginationFallbackQueryParams }, useDql ? { apiTransform: dqlTransform } : {}), { uri }));
    (0, react_1.useEffect)(() => {
        if (resource) {
            setTransformedGroups(mapGroup ? resource === null || resource === void 0 ? void 0 : resource.map(mapGroup) : resource);
        }
    }, [mapGroup, resource]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(_1.SalesforceFieldSearch, { onChange: updateParams, queryParams: queryParams, showInternalNameToggle: useDql }),
        react_1.default.createElement("div", { className: 'searchable-periscope-field-group-list' },
            react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: loading },
                react_1.default.createElement("div", { ref: scrollableListRef }, transformedGroups.map((group) => react_1.default.createElement(_1.PeriscopeFieldGroup, Object.assign({ group: group, key: `${group.name}`, useApiNames: Boolean(queryParams.useInternal) }, rest))))))));
};
exports.SearchablePeriscopeFieldGroupListV3 = SearchablePeriscopeFieldGroupListV3;
