"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarMenu = void 0;
require("./SidebarMenu.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const Search_1 = require("./Search");
const react_router_dom_1 = require("react-router-dom");
const menuitems_1 = require("./menuitems");
const react_1 = __importStar(require("react"));
const menu_1 = require("@ui/menu");
const _hooks_1 = require("@hooks");
function buildSelectInfoLookup(items, parentKeys) {
    var _a;
    let result = {};
    for (let i = 0; i < items.length; i++) {
        const entry = items[i];
        const entryKey = entry.key;
        const pathKeys = [entryKey, ...parentKeys !== null && parentKeys !== void 0 ? parentKeys : []];
        result[entryKey] = pathKeys;
        if ((_a = entry.children) === null || _a === void 0 ? void 0 : _a.length) {
            const nestedResult = buildSelectInfoLookup(entry.children, pathKeys);
            result = Object.assign(Object.assign({}, result), nestedResult);
        }
    }
    return result;
}
const SidebarMenu = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const tagsSubmenu = (0, menuitems_1.useTagSubMenu)();
    const { isClosed, toggle } = (0, _hooks_1.useApplicationSidebar)();
    const isClosedPrevious = (0, _hooks_1.usePrevious)(isClosed, isClosed);
    const selectInfoLookupRef = (0, react_1.useRef)();
    const currentOpenKeysRef = (0, react_1.useRef)([menuitems_1.impactAnalysisSubMenu.key]);
    const openKeysCacheRef = (0, react_1.useRef)(currentOpenKeysRef.current);
    const [selected, setSelected] = (0, react_1.useState)(['-1']);
    const [openKeys, setOpenKeys] = (0, react_1.useState)(currentOpenKeysRef.current);
    const handleTitleClick = (0, react_1.useCallback)((args) => {
        if (!isClosed) {
            return;
        }
        if (!currentOpenKeysRef.current.includes(args.key)) {
            currentOpenKeysRef.current.push(args.key);
        }
        toggle();
    }, [isClosed, toggle]);
    const calculatedMenuItems = (0, react_1.useMemo)(() => {
        var _a, _b, _c;
        const items = [
            menuitems_1.dashboardMenuItem,
            Object.assign(Object.assign({}, menuitems_1.impactAnalysisSubMenu), { children: isClosed ? (_a = menuitems_1.impactAnalysisSubMenu.children) !== null && _a !== void 0 ? _a : [] : [
                    {
                        className: 'sidebar-search-entry',
                        key: 'sidebar-search',
                        label: react_1.default.createElement(Search_1.Search, null)
                    },
                    ...(_b = menuitems_1.impactAnalysisSubMenu.children) !== null && _b !== void 0 ? _b : []
                ], onTitleClick: handleTitleClick }),
            Object.assign(Object.assign({}, menuitems_1.governanceSubMenu), { children: [
                    ...(_c = menuitems_1.governanceSubMenu.children) !== null && _c !== void 0 ? _c : [],
                    tagsSubmenu
                ], onTitleClick: handleTitleClick }),
            Object.assign(Object.assign({}, menuitems_1.applicationManagementSubMenu), { onTitleClick: handleTitleClick }),
            Object.assign(Object.assign({}, menuitems_1.logsSubMenu), { onTitleClick: handleTitleClick })
        ];
        selectInfoLookupRef.current = buildSelectInfoLookup(items);
        return items;
    }, [handleTitleClick, isClosed, tagsSubmenu]);
    const handleOpenChange = (0, react_1.useCallback)((keys) => {
        if (!isClosed) {
            currentOpenKeysRef.current = keys;
        }
        setOpenKeys(keys);
    }, [isClosed]);
    const handleSelectionChange = (0, react_1.useCallback)((info) => {
        if (info.keyPath.length > 1) {
            const parentKeys = info.keyPath.slice(1);
            const sourceKeys = isClosed ? openKeysCacheRef.current : currentOpenKeysRef.current;
            const toOpenKeys = parentKeys.filter((key) => !sourceKeys.includes(key));
            if (info.selectedKeys.includes(sonar_ts_constants_1.FULL_ROUTES.TAGS_MANAGE) && !sourceKeys.includes(sonar_ts_constants_1.FULL_ROUTES.TAGS_MANAGE)) {
                toOpenKeys.push(sonar_ts_constants_1.FULL_ROUTES.TAGS_MANAGE);
            }
            if (toOpenKeys.length) {
                if (isClosed) {
                    openKeysCacheRef.current = [...sourceKeys, ...toOpenKeys];
                }
                if (!isClosed) {
                    handleOpenChange([...currentOpenKeysRef.current, ...toOpenKeys]);
                    setOpenKeys(currentOpenKeysRef.current);
                }
            }
        }
        setSelected(info.selectedKeys);
    }, [handleOpenChange, isClosed]);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        const pathKey = location.pathname;
        if (!selected.includes(pathKey)) {
            handleSelectionChange({
                key: pathKey,
                keyPath: (_b = ((_a = selectInfoLookupRef.current) !== null && _a !== void 0 ? _a : {})[pathKey]) !== null && _b !== void 0 ? _b : [],
                selectedKeys: [pathKey]
            });
        }
    }, [handleSelectionChange, location.pathname, selected]);
    (0, react_1.useEffect)(() => {
        if (isClosed && !isClosedPrevious) {
            openKeysCacheRef.current = currentOpenKeysRef.current;
        }
        if (!isClosed && isClosedPrevious) {
            handleOpenChange(openKeysCacheRef.current);
        }
        if (!isClosed && !currentOpenKeysRef.current.length) {
            handleOpenChange([menuitems_1.impactAnalysisSubMenu.key]);
        }
    }, [handleOpenChange, isClosed, isClosedPrevious]);
    return react_1.default.createElement(menu_1.SonarMenu, { id: 'main-menu', items: calculatedMenuItems, mode: 'inline', onOpenChange: handleOpenChange, onSelect: handleSelectionChange, openKeys: openKeys, selectedKeys: selected, theme: 'dark' });
};
exports.SidebarMenu = SidebarMenu;
