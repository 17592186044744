"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeField = void 0;
const antd_1 = require("antd");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const _1 = require("./");
const events_1 = require("@lib/events");
const react_1 = __importStar(require("react"));
const records_1 = require("@ui/records");
const PeriscopeField = ({ field, onFieldClick, onParentClick, showParent, source, useApiName }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const fieldType = (0, sonar_ts_lib_1.displayFieldType)((_b = (_a = field.type) !== null && _a !== void 0 ? _a : field.rawSalesforceType) !== null && _b !== void 0 ? _b : '');
    const name = useApiName ? field.internalName : field.displayName;
    const parentName = useApiName ? field.parentInternalName : field.parentDisplayName;
    const relatedIntegrations = field.linkedIntegrations;
    const handleFieldClick = (0, react_1.useMemo)(() => (0, events_1.ifClickHandler)(onFieldClick, field), [field, onFieldClick]);
    const handleParentClick = (0, react_1.useMemo)(() => (0, events_1.ifClickHandler)(onParentClick, field), [field, onParentClick]);
    return (react_1.default.createElement(records_1.SonarRecord, { name: name, onClick: handleFieldClick, onClickParent: handleParentClick, parentName: showParent ? parentName : '', relatedOrgs: relatedIntegrations, secondaryInfo: fieldType, sonarObjectId: field.sonarObjectId, source: source },
        react_1.default.createElement(records_1.SonarRecordRow, { condition: Boolean((_c = field.componentTypes) === null || _c === void 0 ? void 0 : _c.length) },
            react_1.default.createElement(antd_1.Col, { span: 24 },
                react_1.default.createElement(_1.FieldNodeList, { label: 'Component Types', nodes: (_e = (_d = field.componentTypes) === null || _d === void 0 ? void 0 : _d.map((componentType) => ({ name: componentType }))) !== null && _e !== void 0 ? _e : [] }))),
        react_1.default.createElement(records_1.SonarRecordRow, { condition: Boolean((_f = field.nodes) === null || _f === void 0 ? void 0 : _f.length) },
            react_1.default.createElement(antd_1.Col, { span: 24 },
                react_1.default.createElement(_1.FieldNodeList, { label: 'Nodes', nodes: (_g = field.nodes) !== null && _g !== void 0 ? _g : [] }))),
        react_1.default.createElement(records_1.SonarRecordRow, null,
            react_1.default.createElement(antd_1.Col, { className: 'align-bottom', flex: 'none' },
                react_1.default.createElement(records_1.SonarRecordReferences, { parentName: field.parentDisplayName, relatedCounts: field.relatedCounts, sonarObjectId: field.sonarObjectId })),
            react_1.default.createElement(antd_1.Col, { flex: 'auto' },
                react_1.default.createElement(records_1.SonarRecordTags, { sonarObjectId: field.sonarObjectId })))));
};
exports.PeriscopeField = PeriscopeField;
