"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuthToken = useAuthToken;
const selectors_1 = require("@lib/selectors");
const jwt_decode_1 = require("jwt-decode");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const useSonarSelector_1 = require("./useSonarSelector");
const unauthed = {
    isAdmin: false,
    isImpersonator: false,
    isLoggedIn: false,
    isReadOnly: true,
    userId: -1
};
const selector = (0, selectors_1.createSonarSelector)((state) => {
    const currenToken = state.application.auth.token;
    if (!currenToken) {
        return unauthed;
    }
    const decodedToken = (0, jwt_decode_1.jwtDecode)(currenToken);
    if (Date.now() > decodedToken.exp * 1000) {
        (0, sonar_ts_lib_1.removeJWTToken)();
        return unauthed;
    }
    const orgId = Number(decodedToken.organizationId);
    return {
        impersonatorShard: decodedToken.impersonatorShard,
        isAdmin: decodedToken.isSuperAdmin,
        isImpersonator: decodedToken.isImpersonator,
        isLoggedIn: true,
        isReadOnly: decodedToken.isReadOnly,
        organizationId: isNaN(orgId) ? undefined : orgId,
        userId: Number(decodedToken.userId)
    };
});
function compare(prev, next) {
    return (prev.impersonatorShard === next.impersonatorShard &&
        prev.userId === next.userId &&
        prev.isAdmin === next.isAdmin &&
        prev.isImpersonator === next.isImpersonator &&
        prev.isLoggedIn === next.isLoggedIn &&
        prev.isReadOnly === next.isReadOnly &&
        prev.organizationId === next.organizationId);
}
function useAuthToken() {
    return (0, useSonarSelector_1.useSonarSelector)(selector, compare);
}
