"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppUserCellEditor = void 0;
require("./AppUserCellEditor.scss");
const app_form_1 = require("../app-form");
const EditBox_1 = require("@ui/EditBox");
const popups_1 = require("@lib/popups");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const AppUserCellEditor = ({ applicationId, user }) => {
    const inputRef = (0, react_1.useRef)(null);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const [newUser, setNewUser] = (0, react_1.useState)(user);
    const { request, reset } = (0, _hooks_1.useUpdateDataSecurityApp)();
    const process = (0, _hooks_1.useAsyncProcessing)({
        resetAction: reset,
        stateSelector: _hooks_1.useUpdateDataSecurityApp.selectors.state
    });
    const save = (0, react_1.useCallback)((value) => {
        process.watchNextCall();
        request({
            applicationId,
            rawSalesforceId: value !== null && value !== void 0 ? value : null
        });
    }, [applicationId, process, request]);
    const handleOnChange = (0, react_1.useCallback)((value) => {
        if (newUser !== value) {
            setNewUser(value !== null && value !== void 0 ? value : '');
            save(value);
        }
    }, [newUser, save]);
    return (react_1.default.createElement(EditBox_1.EditBox, { className: 'app-user-cell-editor', isReadOnly: isReadOnly, isSpreadsheet: true, selectionRef: inputRef, state: process.state },
        react_1.default.createElement(app_form_1.AppUserSelect, { allowClear: true, disableUsed: true, getPopupContainer: popups_1.getClosestTableBody, key: user, onChange: handleOnChange, popupMatchSelectWidth: false, ref: inputRef, value: newUser })));
};
exports.AppUserCellEditor = AppUserCellEditor;
