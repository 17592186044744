"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportSearchableSelectionTableV3 = void 0;
const __1 = require("../");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const ReportRow_1 = require("./ReportRow");
const ReportSearchV3_1 = require("./ReportSearchV3");
const tables_1 = require("@ui/tables");
const ReportSortOptions_1 = require("./ReportSortOptions");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const KeyPagination_1 = require("@ui/KeyPagination");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const dqlTransform = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: {
        componentTypes: {
            name: 'ComponentTypeId',
            operator: sonar_ts_types_1.DynamicQueryOperator.In
        },
        query: {
            name: 'DisplayName',
            operator: sonar_ts_types_1.DynamicQueryOperator.Like
        }
    },
    orderingParams: {
        name: 'DisplayName'
    }
});
const ReportSearchableSelectionTableV3 = ({ parentIndex, resourceIdsUri, uri }) => {
    const scrollableListRef = (0, react_1.useRef)(null);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        apiTransform: dqlTransform,
        defaultParams: Object.assign(Object.assign({}, KeyPagination_1.keyPaginationDefaultQueryParams), { sortBy: ReportSortOptions_1.v3ReportSortOptions[0].key, sortDirection: ReportSortOptions_1.v3ReportSortOptions[0].direction }),
        fallbackParams: KeyPagination_1.keyPaginationFallbackQueryParams,
        uri
    });
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        queryParams,
        resourceIdsUri: resourceIdsUri,
        results: resource === null || resource === void 0 ? void 0 : resource.results,
        selectionType: sonar_ts_types_1.SonarObjectType.SalesforceReport
    });
    const renderReport = (0, react_1.useCallback)((record) => react_1.default.createElement(ReportRow_1.ReportRow, { key: record.sonarObjectId, parentIndex: parentIndex, record: record }), [parentIndex]);
    const results = resource === null || resource === void 0 ? void 0 : resource.results;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ReportSearchV3_1.ReportSearchV3, { onChange: updateParams, queryParams: queryParams }),
        react_1.default.createElement(tables_1.SelectionTable, { dataSource: results, loading: loading, ref: scrollableListRef, renderRow: renderReport, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.REPORTS }),
        react_1.default.createElement(KeyPagination_1.KeyPagination, { defaultPageSize: Number(queryParams.count), listRef: scrollableListRef, onChange: updateParams, resource: resource })));
};
exports.ReportSearchableSelectionTableV3 = ReportSearchableSelectionTableV3;
