"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardView = void 0;
const AccessStatistics_1 = require("@security/access-statistics/AccessStatistics");
const detected_applications_1 = require("@security/detected-applications");
const sonar_core_1 = require("@sonar-software/sonar-core");
const Issues_1 = require("./Issues");
const Notifications_1 = require("./Notifications");
const SalesForceSummary_1 = require("./SalesForceSummary");
const system_overview_1 = require("@security/system-overview");
const uptime_monitors_dashboard_1 = require("@security/uptime-monitors-dashboard");
const _hooks_1 = require("@hooks");
const VerticalColumn_1 = require("@ui/general/VerticalColumn");
const VerticalColumnView_1 = require("@ui/general/VerticalColumnView");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const DashboardView = () => {
    const params = (0, react_router_dom_1.useParams)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const download = (0, _hooks_1.useDownload)();
    const downloadRequest = (0, react_1.useCallback)(() => (0, sonar_core_1.getExport)({ exportId: Number(params.id) }), [params.id]);
    (0, react_1.useEffect)(() => {
        if (params.id) {
            download({
                errorMessage: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_DOWNLOAD_EXPORT,
                request: downloadRequest
            });
            navigate(sonar_ts_constants_1.FULL_ROUTES.DASHBOARD, { replace: true });
        }
    }, [download, downloadRequest, navigate, params.id]);
    return (react_1.default.createElement(VerticalColumnView_1.VerticalColumnView, null,
        react_1.default.createElement(VerticalColumn_1.VerticalColumn, null,
            react_1.default.createElement(uptime_monitors_dashboard_1.UptimeMonitorsDashboard, null),
            react_1.default.createElement(system_overview_1.SystemOverview, null),
            react_1.default.createElement(detected_applications_1.DetectedApplications, null)),
        react_1.default.createElement(VerticalColumn_1.VerticalColumn, null,
            react_1.default.createElement(SalesForceSummary_1.SalesForceSummary, null),
            react_1.default.createElement(Notifications_1.Notifications, null),
            react_1.default.createElement(AccessStatistics_1.AccessStatistics, null),
            react_1.default.createElement(Issues_1.Issues, null))));
};
exports.DashboardView = DashboardView;
