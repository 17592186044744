"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorApplication = void 0;
exports.getApplicationList = getApplicationList;
require("./MonitorApplication.scss");
const applications_1 = require("@ui/applications");
const react_router_dom_1 = require("react-router-dom");
const MonitorMenu_1 = require("./MonitorMenu");
const text_1 = require("@ui/text");
const useDataSecurityMonitors_1 = require("@hooks/data-security/useDataSecurityMonitors");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_if_1 = require("react-if");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function getApplicationList(monitors, applications, type) {
    var _a, _b;
    if (type === undefined) {
        return undefined;
    }
    const monitorsByType = monitors.filter((monitor) => { var _a; return ((_a = monitor.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId) === type; });
    const maxCount = (_b = (_a = MonitorMenu_1.monitorMenuConfigs.find((config) => config.type === type)) === null || _a === void 0 ? void 0 : _a.max) !== null && _b !== void 0 ? _b : 1;
    return applications.reduce((result, application) => (monitorsByType
        .filter((monitor) => monitor.applicationId === application.applicationId)
        .length >= maxCount ?
        [...result] :
        [...result, {
                label: application.name,
                value: application.applicationId
            }]), []);
}
const MonitorApplication = ({ editing, fieldId, hidden = false, monitor, onChange }) => {
    var _a, _b, _c;
    const monitors = (0, useDataSecurityMonitors_1.useDataSecurityMonitors)();
    const applications = (0, _hooks_1.useDataSecurityApps)();
    const previousMonitor = (0, _hooks_1.usePrevious)(monitor);
    const applicationOptions = (0, react_1.useMemo)(() => { var _a; return getApplicationList(monitors, applications, (_a = monitor.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId); }, [monitors, applications, monitor]);
    const defaultApp = (0, react_1.useMemo)(() => {
        var _a;
        if (monitor.applicationId) {
            return monitor.applicationId;
        }
        const appOptions = getApplicationList(monitors, applications, (_a = monitor.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId);
        if ((previousMonitor === null || previousMonitor === void 0 ? void 0 : previousMonitor.applicationId) && (appOptions === null || appOptions === void 0 ? void 0 : appOptions.find((x) => x.value === previousMonitor.applicationId))) {
            return previousMonitor.applicationId;
        }
        else if ((appOptions === null || appOptions === void 0 ? void 0 : appOptions.length) === 1) {
            return appOptions[0].value;
        }
        return undefined;
    }, [monitor, applications, monitors, previousMonitor]);
    const handleOnChange = (0, react_1.useCallback)((newApp) => {
        if (onChange) {
            onChange(newApp);
        }
    }, [onChange]);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if ((((_a = monitor.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId) !== ((_b = previousMonitor === null || previousMonitor === void 0 ? void 0 : previousMonitor.monitorConfig) === null || _b === void 0 ? void 0 : _b.monitorTypeId) ||
            monitor.applicationId !== defaultApp) &&
            !monitor.templateId) {
            handleOnChange(defaultApp);
        }
    }, [defaultApp, previousMonitor, handleOnChange, monitor]);
    if (!((_a = monitor.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId)) {
        return;
    }
    return (react_1.default.createElement(react_if_1.If, { condition: monitor.templateId },
        react_1.default.createElement(react_if_1.Then, null,
            react_1.default.createElement(text_1.SonarText, { className: 'h3' }, sonar_ts_constants_1.COPY_SPECIFIC.IMPACTED_APPLICATIONS),
            react_1.default.createElement(antd_1.Form.Item, { className: 'monitor-application', name: fieldId === undefined ? 'applicationIds' : [fieldId, 'applicationIds'] },
                react_1.default.createElement(antd_1.Space, null,
                    react_1.default.createElement(react_if_1.If, { condition: monitor.applicationIds && ((_b = monitor.applicationIds) === null || _b === void 0 ? void 0 : _b.length) > 0 },
                        react_1.default.createElement(react_if_1.Then, null, (_c = monitor.applicationIds) === null || _c === void 0 ? void 0 : _c.map((appId) => react_1.default.createElement(applications_1.AppDisplay, { appId: appId, key: appId }))),
                        react_1.default.createElement(react_if_1.Else, null,
                            react_1.default.createElement(antd_1.Alert, { message: react_1.default.createElement(antd_1.Space, null,
                                    sonar_ts_constants_1.COPY_SPECIFIC.SETUP_CRITICAL_APPLICATIONS_MESSAGE,
                                    react_1.default.createElement(react_router_dom_1.Link, { to: sonar_ts_constants_1.FULL_ROUTES.DATA_SECURITY_APPLICATION_SETUP },
                                        react_1.default.createElement(antd_1.Button, { size: 'small', type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.SETUP_CRITICAL_APPLICATIONS))), showIcon: true, type: 'info' })))))),
        react_1.default.createElement(react_if_1.Else, null,
            react_1.default.createElement(react_if_1.When, { condition: !hidden },
                react_1.default.createElement(text_1.SonarText, { className: 'h3' }, sonar_ts_constants_1.COPY_SPECIFIC.SELECT_AN_APP)),
            react_1.default.createElement(antd_1.Form.Item, { className: 'monitor-application', hidden: hidden, initialValue: defaultApp, name: fieldId === undefined ? 'applicationId' : [fieldId, 'applicationId'] },
                react_1.default.createElement(antd_1.Select, { disabled: !editing, onChange: handleOnChange, optionLabelProp: 'label', optionRender: (app) => react_1.default.createElement(applications_1.AppDisplay, { appId: Number(app.value) }), options: applicationOptions, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SELECT_AN_APPLICATION, showSearch: true })))));
};
exports.MonitorApplication = MonitorApplication;
