"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchableTimelineList = void 0;
require("./SearchableTimelineList.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const PeriscopeV3PaginatedList_1 = require("../PeriscopeV3PaginatedList");
const TimelineRecord_1 = require("./TimelineRecord");
const TimelineSortOptions_1 = require("./TimelineSortOptions");
const events_1 = require("@lib/events");
const antd_1 = require("antd");
const KeyPagination_1 = require("@ui/KeyPagination");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const TimelineSearch_1 = require("./TimelineSearch");
const SearchableTimelineList = ({ sonarObjectId }) => {
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        defaultParams: Object.assign(Object.assign({}, KeyPagination_1.keyPaginationDefaultQueryParams), { sortBy: TimelineSortOptions_1.timelineSortOptions[0].key, sortDirection: TimelineSortOptions_1.timelineSortOptions[0].direction }),
        fallbackParams: KeyPagination_1.keyPaginationFallbackQueryParams,
        uri: `v3/core/sonar-objects/${sonarObjectId}/timelines`
    });
    const handleExpandAll = (0, react_1.useCallback)(() => {
        (0, events_1.trigger)(`periscope-timeline-${sonarObjectId}:expandAll`);
    }, [sonarObjectId]);
    const handleCollapseAll = (0, react_1.useCallback)(() => {
        (0, events_1.trigger)(`periscope-timeline-${sonarObjectId}:collapseAll`);
    }, [sonarObjectId]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(TimelineSearch_1.TimelineSearch, { onChange: updateParams, queryParams: queryParams, sonarObjectId: sonarObjectId }),
        react_1.default.createElement(antd_1.Row, { className: 'expand-collapse-row' },
            react_1.default.createElement(antd_1.Button, { className: 'expand-all-button', onClick: handleExpandAll, size: 'small', type: 'link' }, sonar_ts_constants_1.COPY_GENERIC.EXPAND_ALL),
            react_1.default.createElement(antd_1.Button, { className: 'collapse-all-button', onClick: handleCollapseAll, size: 'small', type: 'link' }, sonar_ts_constants_1.COPY_GENERIC.COLLAPSE_ALL)),
        react_1.default.createElement(PeriscopeV3PaginatedList_1.PeriscopeV3PaginatedList, { loading: loading, onChange: updateParams, queryParams: queryParams, renderListItem: (item) => react_1.default.createElement(TimelineRecord_1.TimelineRecord, { key: item.change.id, parentSonarObjectId: sonarObjectId, record: item }), resource: resource })));
};
exports.SearchableTimelineList = SearchableTimelineList;
