"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTableEditableCell = exports.patchUserSelectors = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const icons_1 = require("@ant-design/icons");
const text_1 = require("@ui/text");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
exports.patchUserSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.users.byId.patch);
const UserTableEditableCell = (props) => {
    const { editable = true, userProperty, value, userId, shardName } = props;
    const patchUserIsErrored = (0, _hooks_1.useSonarSelector)(exports.patchUserSelectors.isErrored);
    const patchUserIsFailed = (0, _hooks_1.useSonarSelector)(exports.patchUserSelectors.isFailed);
    const patchUserIsRequested = (0, _hooks_1.useSonarSelector)(exports.patchUserSelectors.isRequested);
    const patchUserIsReceived = (0, _hooks_1.useSonarSelector)(exports.patchUserSelectors.isReceived);
    const patchUser = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreUsersById);
    const [isEditing, setIsEditing] = (0, react_1.useState)(false);
    const [isSubmitted, setIsSubmitted] = (0, react_1.useState)(false);
    const [previousValue, setPreviousValue] = (0, react_1.useState)(value);
    const [displayedValue, setDisplayedValue] = (0, react_1.useState)(value);
    const handleEditStarted = (0, react_1.useCallback)(() => {
        if (!patchUserIsRequested) {
            setIsEditing(true);
        }
    }, [patchUserIsRequested]);
    const handleEditCanceled = (0, react_1.useCallback)(() => {
        setIsEditing(false);
    }, []);
    const handleChange = (0, react_1.useCallback)((newValue) => {
        if (newValue && !patchUserIsRequested) {
            setIsEditing(false);
            if (newValue !== displayedValue) {
                setIsSubmitted(true);
                setPreviousValue(displayedValue);
                setDisplayedValue(newValue);
                patchUser({
                    shardName,
                    userId: userId,
                    [userProperty]: newValue
                });
            }
        }
    }, [patchUser, displayedValue, userProperty, userId, shardName, patchUserIsRequested]);
    (0, react_1.useEffect)(() => {
        if (patchUserIsReceived && isSubmitted) {
            setIsSubmitted(false);
        }
    }, [patchUserIsReceived, isSubmitted]);
    (0, react_1.useEffect)(() => {
        if ((patchUserIsErrored || patchUserIsFailed) && isSubmitted) {
            setDisplayedValue(previousValue);
            setIsSubmitted(false);
        }
    }, [patchUserIsErrored, patchUserIsFailed, isSubmitted, previousValue]);
    (0, react_1.useEffect)(() => {
        setDisplayedValue(value);
        setPreviousValue(value);
    }, [value]);
    if (editable) {
        return (react_1.default.createElement("div", { className: 'editable-table-cell' },
            react_1.default.createElement(text_1.SonarText, { editable: {
                    editing: isEditing && !patchUserIsRequested,
                    icon: react_1.default.createElement(icons_1.EditOutlined, null),
                    onCancel: handleEditCanceled,
                    onChange: handleChange,
                    onStart: handleEditStarted
                } }, displayedValue)));
    }
    return react_1.default.createElement("span", null, displayedValue);
};
exports.UserTableEditableCell = UserTableEditableCell;
