"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeInitiatives = void 0;
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const initiatives_1 = require("@ui/initiatives");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const PeriscopeInitiatives = ({ sonarObjectId }) => {
    const { request: add } = (0, _hooks_1.useAddToInitiative)();
    const { request: remove } = (0, _hooks_1.useRemoveFromInitiative)();
    const isInitiativeWorkflowActive = (0, _hooks_1.useInitiativeWorkflow)();
    const currentInitiative = (0, _hooks_1.useCurrentInitiative)();
    const initiativesForSonarObject = (0, _hooks_1.useSonarObjectReference)(sonarObjectId).initiativeIds;
    const hasInitiative = Boolean(currentInitiative && initiativesForSonarObject.includes(currentInitiative.id));
    const handleClick = (0, react_1.useCallback)(() => {
        if (!currentInitiative) {
            return;
        }
        const { id, title } = currentInitiative;
        if (hasInitiative) {
            remove({
                initiativeId: id,
                sonarObjectIds: [sonarObjectId]
            });
        }
        else {
            add({
                initiativeId: id,
                initiativeTitle: title,
                sonarObjectIds: [sonarObjectId]
            });
        }
    }, [add, currentInitiative, hasInitiative, remove, sonarObjectId]);
    if (!isInitiativeWorkflowActive) {
        return react_1.default.createElement(initiatives_1.ManageInitiatives, { sonarObjectId: sonarObjectId });
    }
    return (react_1.default.createElement("div", { className: 'manage-initiatives' },
        react_1.default.createElement(antd_1.Button, { danger: hasInitiative, icon: react_1.default.createElement(icons_1.AimOutlined, null), onClick: handleClick }, hasInitiative ? sonar_ts_constants_1.COPY_SPECIFIC.REMOVE_FROM_INITIATIVE : sonar_ts_constants_1.COPY_SPECIFIC.ADD_TO_INITIATIVE)));
};
exports.PeriscopeInitiatives = PeriscopeInitiatives;
