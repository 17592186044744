"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.JiraCredentialsUpdate = void 0;
require("./JiraCredentialsUpdate.scss");
const icons_1 = require("@ant-design/icons");
const sonar_integrations_1 = require("@sonar-software/sonar-integrations");
const JiraCredentialsForm_1 = require("./JiraCredentialsForm");
const selectors_1 = require("./@arch/selectors");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const JiraCredentialsUpdate = ({ form, onClose }) => {
    const [disabled, setDisabled] = (0, react_1.useState)(true);
    const isIntegrationRequested = (0, _hooks_1.useSonarSelector)(selectors_1.jiraIntegrationSelectors.isRequested);
    const deleteJiraCredentials = (0, _hooks_1.useAction)(sonar_integrations_1.integrationsActions.requestDeleteJiraCredentials);
    const { customerId } = (0, _hooks_1.useCurrentUser)();
    const onSubmit = (0, react_1.useCallback)(() => {
        form.submit();
        onClose();
    }, [form, onClose]);
    const onCancel = (0, react_1.useCallback)(() => {
        form.resetFields();
        setDisabled(true);
        onClose();
    }, [form, onClose]);
    const onDelete = (0, react_1.useCallback)(() => {
        deleteJiraCredentials({ customerId });
        onClose();
    }, [customerId, deleteJiraCredentials, onClose]);
    const onUpdate = (0, react_1.useCallback)(() => {
        setDisabled(false);
    }, []);
    return (react_1.default.createElement("div", { className: 'jira-popover' },
        react_1.default.createElement("button", { className: 'popover-close', type: 'button' },
            react_1.default.createElement(icons_1.CloseOutlined, { onClick: onCancel })),
        react_1.default.createElement("div", { className: 'jira-popover-header' },
            react_1.default.createElement("div", { className: 'h3' }, sonar_ts_constants_1.INTEGRATIONS_COPY.EDIT_JIRA_SETTINGS)),
        react_1.default.createElement("div", { className: 'jira-popover-body' },
            react_1.default.createElement(JiraCredentialsForm_1.JiraCredentialsForm, { form: form, onUpdate: onUpdate })),
        react_1.default.createElement("div", { className: 'jira-popover-footer' },
            react_1.default.createElement(antd_1.Button, { danger: true, onClick: onDelete }, sonar_ts_constants_1.COPY_SPECIFIC.DELETE_INTEGRATION),
            react_1.default.createElement("div", null,
                react_1.default.createElement(antd_1.Button, { onClick: onCancel }, sonar_ts_constants_1.COPY_SPECIFIC.CANCEL),
                react_1.default.createElement(antd_1.Button, { disabled: disabled, id: 'update-jira-integration', loading: isIntegrationRequested, onClick: onSubmit, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.UPDATE)))));
};
exports.JiraCredentialsUpdate = JiraCredentialsUpdate;
