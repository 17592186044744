"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendPasswordResetButton = void 0;
require("./UserControls.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const antd_1 = require("antd");
const text_1 = require("@ui/text");
const selectors_1 = require("@lib/selectors");
const icons_1 = require("@ant-design/icons");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getResetPasswordLinkSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.users.byId.links.passwordReset.get);
const postSendResetPasswordLinkSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.users.byId.links.passwordReset.send.post);
const SendPasswordResetButton = (props) => {
    const { userId, userIsLocked, shardName } = props;
    const resetPasswordLink = (0, _hooks_1.useSonarSelector)(getResetPasswordLinkSelectors.data);
    const getResetPasswordLink = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestGetAdminUsersByIdLinksPasswordReset);
    const handleCopyPasswordResetClicked = (0, react_1.useCallback)(() => {
        getResetPasswordLink({
            shardName,
            userId
        });
    }, [getResetPasswordLink, userId, shardName]);
    const [sendClicked, setSendClicked] = (0, react_1.useState)(false);
    const sendResetPasswordEmailIsRequested = (0, _hooks_1.useSonarSelector)(postSendResetPasswordLinkSelectors.isRequested);
    const sendResetPasswordEmail = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestPostAdminUsersByIdLinksPasswordResetSend);
    const handleSendPasswordResetClicked = (0, react_1.useCallback)(() => {
        sendResetPasswordEmail({
            shardName,
            userId
        });
        setSendClicked(true);
    }, [sendResetPasswordEmail, userId, shardName]);
    (0, react_1.useEffect)(() => {
        if (sendClicked && !sendResetPasswordEmailIsRequested) {
            setSendClicked(false);
        }
    }, [sendResetPasswordEmailIsRequested, setSendClicked, sendClicked]);
    return (react_1.default.createElement(antd_1.Button.Group, null,
        react_1.default.createElement(antd_1.Button, { className: 'send-password-reset-button', disabled: sendClicked || userIsLocked, icon: react_1.default.createElement(icons_1.MailOutlined, null), onClick: handleSendPasswordResetClicked }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.SEND_PASSWORD_RESET),
        react_1.default.createElement(text_1.CopyText, { buttonOnly: true, onClick: handleCopyPasswordResetClicked, text: resetPasswordLink === null || resetPasswordLink === void 0 ? void 0 : resetPasswordLink.link, tooltip: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.COPY_PASSWORD_RESET_LINK })));
};
exports.SendPasswordResetButton = SendPasswordResetButton;
