"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentIntegratedApp = useCurrentIntegratedApp;
const selectors_1 = require("@lib/selectors");
const _hooks_1 = require("@hooks");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.applications.byId.get);
/** Subscribes to the currently loaded {@link DataSecurityApplicationReference}, if any. */
function useCurrentIntegratedApp() {
    return (0, _hooks_1.useSonarSelector)(selectors.data);
}
useCurrentIntegratedApp.selectors = selectors;
