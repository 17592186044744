"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationRuleMetadataTab = void 0;
require("./ValidationRuleMetadataTab.scss");
const general_1 = require("@ui/general");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const general_2 = require("@periscopes/general");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const ValidationRuleMetadataTab = ({ displayName, index, sonarObjectId }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { loading, metadata } = (0, _hooks_1.usePeriscopeMetadata)(sonarObjectId, sonar_ts_types_1.SonarObjectType.SalesforceValidationRule);
    const childName = `.${displayName}`;
    const handleEntityClick = (0, react_1.useCallback)(() => {
        if (!metadata) {
            return;
        }
        openPeriscope({
            id: metadata.parentSonarObjectId,
            name: metadata.parentDisplayName,
            openLeft: true,
            openedFromIndex: index,
            type: sonar_ts_types_1.SonarObjectType.SalesforceEntity
        });
    }, [index, metadata, openPeriscope]);
    if (!metadata || loading) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    return (react_1.default.createElement(general_2.DetailSection, { className: 'validation-rule-metadata' },
        react_1.default.createElement(general_2.DetailSubsection, { title: sonar_ts_constants_1.PERISCOPE_VALIDATION_RULE_COPY.CODE_SNIPPET },
            react_1.default.createElement(general_1.CodeHighlight, { code: metadata.code, codeReferences: metadata.codeReferences, index: index })),
        react_1.default.createElement(general_2.DetailSubsection, { className: 'parent-object', title: sonar_ts_constants_1.PERISCOPE_VALIDATION_RULE_COPY.PARENT_OBJECT },
            react_1.default.createElement("span", { className: 'reference-link', onClick: handleEntityClick },
                react_1.default.createElement("b", null, metadata.parentDisplayName),
                childName))));
};
exports.ValidationRuleMetadataTab = ValidationRuleMetadataTab;
