"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApplicationReferences = useApplicationReferences;
exports.useApplicationReference = useApplicationReference;
const selectors_1 = require("@lib/selectors");
const _hooks_1 = require("@hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const mapSelector = (0, selectors_1.createSonarSelector)((state) => state.core.dataSecurity.applications.byIdMap);
const emptyMap = {};
const empty = {
    activityPeriod: 0,
    integrationUser: '',
    monitors: [],
    name: '',
    stakeholderIds: [],
    status: sonar_core_1.DataSecurityApplicationStatus.Added
};
function useApplicationReferences() {
    return (0, _hooks_1.useSonarSelector)((state) => { var _a; return (_a = mapSelector(state).data) !== null && _a !== void 0 ? _a : emptyMap; });
}
function useApplicationReference(applicationId) {
    var _a;
    return (_a = useApplicationReferences()[applicationId]) !== null && _a !== void 0 ? _a : empty;
}
