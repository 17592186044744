"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sidebar = void 0;
require("./index.scss");
const antd_1 = require("antd");
const OrganizationSwitcher_1 = require("./OrganizationSwitcher");
const SidebarMenu_1 = require("./SidebarMenu");
const SidebarSonarLogo_1 = require("./SidebarSonarLogo");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const { Sider } = antd_1.Layout;
const Sidebar = () => {
    const { isClosed, toggle } = (0, _hooks_1.useApplicationSidebar)();
    const periscopeCount = (0, _hooks_1.usePeriscopeCount)();
    (0, react_1.useEffect)(() => {
        // This addresses various component resizing issues that may occur
        // when the sidepanel is collapsing immediately upon load.
        setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    }, [isClosed]);
    const sliderStyleOptions = periscopeCount > 0 ? { transition: 'none' } : {};
    return (react_1.default.createElement(Sider, { collapsed: isClosed, collapsedWidth: 80, collapsible: true, id: 'sidebar', onCollapse: toggle, style: sliderStyleOptions, width: 310 },
        react_1.default.createElement(OrganizationSwitcher_1.OrganizationSwitcher, { isClosed: isClosed }),
        react_1.default.createElement(SidebarSonarLogo_1.SidebarSonarLogo, { isClosed: isClosed }),
        react_1.default.createElement(SidebarMenu_1.SidebarMenu, null)));
};
exports.Sidebar = Sidebar;
