"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldUsageSelector = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const FieldUsageTag_1 = require("./FieldUsageTag");
const SonarSelector_1 = require("../SonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
function updateFieldUsage(sonarObjectId, fieldUsageId) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const result = yield (0, sonar_core_1.patchSalesforceSonarObject)({
            fieldUsageId,
            sonarObjectId
        });
        return (_a = result.data) !== null && _a !== void 0 ? _a : _hooks_1.genericProcessingFailure;
    });
}
const usageOptions = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.FieldUsageId)
    .map((value) => ({
    label: react_1.default.createElement(FieldUsageTag_1.FieldUsageTag, { fieldUsageId: value }),
    value: value
}));
const FieldUsageSelector = ({ getPopupContainer, isSpreadsheet, sonarObjectId }) => {
    const { salesforce } = (0, _hooks_1.useSonarObjectReference)(sonarObjectId);
    const { fieldUsageId } = salesforce;
    const [value, setValue] = (0, react_1.useState)(fieldUsageId);
    const { update } = (0, _hooks_1.useUpdateSonarObjectReference)();
    const { start, state, stop } = (0, _hooks_1.useProcessing)();
    const { changes } = (0, _hooks_1.useOptimisticUpdate)(sonarObjectId);
    const previousFieldUsage = (0, _hooks_1.usePrevious)(fieldUsageId);
    (0, react_1.useEffect)(() => {
        if (fieldUsageId !== previousFieldUsage) {
            setValue(fieldUsageId);
        }
    }, [fieldUsageId, previousFieldUsage, value]);
    const handleOnChange = (0, react_1.useCallback)((change) => __awaiter(void 0, void 0, void 0, function* () {
        const newValue = change !== null && change !== void 0 ? change : sonar_core_1.FieldUsageId.None;
        if (fieldUsageId === newValue) {
            return;
        }
        const fallbackValue = value;
        setValue(newValue);
        start();
        const updateResult = yield updateFieldUsage(sonarObjectId, newValue);
        if (updateResult === null || updateResult === void 0 ? void 0 : updateResult.success) {
            update({
                salesforce: { fieldUsageId: newValue },
                sonarObjectId
            });
        }
        else {
            setValue(fallbackValue);
        }
        stop(updateResult);
    }), [fieldUsageId, sonarObjectId, start, stop, update, value]);
    return (react_1.default.createElement(SonarSelector_1.SonarSelector, { allowClear: fieldUsageId !== sonar_core_1.FieldUsageId.None, boxless: !isSpreadsheet, className: 'salesforce-field-usage-selector', defaultValue: fieldUsageId, getPopupContainer: getPopupContainer, isSalesforce: true, isSpreadsheet: isSpreadsheet, labelRender: (option) => react_1.default.createElement(FieldUsageTag_1.FieldUsageTag, { fieldUsageId: option.value }), onChange: handleOnChange, options: usageOptions, placeholder: react_1.default.createElement(FieldUsageTag_1.FieldUsageTag, { fieldUsageId: sonar_core_1.FieldUsageId.None }), readOnlyDisplay: react_1.default.createElement(FieldUsageTag_1.FieldUsageTag, { fieldUsageId: fieldUsageId }), showArrow: true, showSearch: false, state: changes.fieldUsageId ? { isProcessing: true } : state, value: value }));
};
exports.FieldUsageSelector = FieldUsageSelector;
