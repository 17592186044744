"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeEvents = void 0;
require("./ChangeEvents.scss");
const ApiRequests_1 = require("@security/ApiRequests");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const MonitoredObjects_1 = require("./MonitoredObjects");
const general_1 = require("@ui/general");
const react_if_1 = require("react-if");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const monitoredObjectSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.monitoredObjects.get);
const usageSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.usage.get);
const ChangeEvents = ({ isEnabled, isLoading }) => {
    const { data: monitoredObjects, isRequested: isMonitoredObjectsRequested } = (0, _hooks_1.useAsyncState)(monitoredObjectSelectors);
    const { data: usage, isRequested: isUsageRequested } = (0, _hooks_1.useAsyncState)(usageSelectors);
    const hasData = Boolean(monitoredObjects === null || monitoredObjects === void 0 ? void 0 : monitoredObjects.totalCount);
    const loadObjects = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityMonitoredObjects);
    const loadUsage = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityUsage);
    const handleRefresh = (0, react_1.useCallback)(() => {
        loadObjects();
        loadUsage();
    }, [loadObjects, loadUsage]);
    (0, _hooks_1.useOnMount)(() => {
        loadObjects();
        loadUsage();
    });
    return (react_1.default.createElement("div", { className: hasData ? 'monitored-objects' : '' },
        react_1.default.createElement(MonitoredObjects_1.MonitoredObjects, { isEnabled: Boolean(isEnabled), loading: isMonitoredObjectsRequested || isLoading, monitoredObjects: monitoredObjects, onRefresh: handleRefresh, refreshing: isMonitoredObjectsRequested, salesforceUrl: usage === null || usage === void 0 ? void 0 : usage.sourceUrl }),
        react_1.default.createElement(react_if_1.When, { condition: hasData && isEnabled },
            react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: isUsageRequested },
                react_1.default.createElement("div", { className: 'data-security-api-requests' },
                    (usage === null || usage === void 0 ? void 0 : usage.dailyDeliveredPlatformEvents) &&
                        react_1.default.createElement(ApiRequests_1.ApiRequests, { header: sonar_ts_constants_1.CHANGE_EVENT_PREFERENCES.CHANGE_EVENTS_DAILY, maxCalls: usage.dailyDeliveredPlatformEvents.max, tooltip: react_1.default.createElement(general_1.SanitizedHtml, { as: 'span', html: sonar_ts_constants_1.CHANGE_EVENT_PREFERENCES.TOOLTIP_DAILY }), usedCalls: usage.dailyDeliveredPlatformEvents.max -
                                usage.dailyDeliveredPlatformEvents.remaining }),
                    (usage === null || usage === void 0 ? void 0 : usage.monthlyPlatformEventsUsageEntitlement) &&
                        react_1.default.createElement(ApiRequests_1.ApiRequests, { header: sonar_ts_constants_1.CHANGE_EVENT_PREFERENCES.CHANGE_EVENTS_MONTHLY, maxCalls: usage.monthlyPlatformEventsUsageEntitlement.max, tooltip: react_1.default.createElement(general_1.SanitizedHtml, { as: 'span', html: sonar_ts_constants_1.CHANGE_EVENT_PREFERENCES.TOOLTIP_MONTHLY }), usedCalls: usage.monthlyPlatformEventsUsageEntitlement.max -
                                usage.monthlyPlatformEventsUsageEntitlement.remaining }))))));
};
exports.ChangeEvents = ChangeEvents;
