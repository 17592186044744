"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeStatusSelector = void 0;
const confetti_1 = require("@lib/confetti");
const selectors_1 = require("@lib/selectors");
const popups_1 = require("@lib/popups");
const InitiativeStatusDisplay_1 = require("./InitiativeStatusDisplay");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_if_1 = require("react-if");
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const _hooks_1 = require("@hooks");
let mounted = false;
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.statuses.get);
const InitiativeStatusSelector = (_a) => {
    var { defaultValue, disabled, id, loading, noConfetti, onChange, showArrow, value } = _a, rest = __rest(_a, ["defaultValue", "disabled", "id", "loading", "noConfetti", "onChange", "showArrow", "value"]);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const statuses = (0, _hooks_1.useSonarSelector)(selectors.data);
    const loadStatuses = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreInitiativesStatuses);
    (0, _hooks_1.useOnMount)(() => {
        if (!statuses && !mounted) {
            mounted = true;
            loadStatuses();
        }
    });
    const statusHandlers = (0, react_1.useMemo)(() => {
        if (!statuses) {
            return [];
        }
        return statuses.map((status) => {
            const { id: statusId } = status;
            return {
                label: react_1.default.createElement(InitiativeStatusDisplay_1.InitiativeStatusDisplay, { statusId: statusId }),
                value: String(statusId)
            };
        });
    }, [statuses]);
    const handleChange = (0, react_1.useCallback)((statusId) => {
        const newStatusId = Number(statusId);
        if (onChange) {
            onChange(newStatusId);
        }
        if (newStatusId === sonar_core_1.InitiativeStatus.Finished && !noConfetti) {
            (0, confetti_1.celebrate)();
        }
    }, [noConfetti, onChange]);
    if (!statuses || loading) {
        return (react_1.default.createElement(antd_1.Select, { disabled: true, options: statusHandlers, value: value ? String(value) : undefined }));
    }
    const defaultValueNumber = Number(defaultValue) || Number(value);
    const displayValue = value !== null && value !== void 0 ? value : (isNaN(defaultValueNumber) ? defaultValue : defaultValueNumber);
    const readOnlyLabel = isNaN(defaultValueNumber) ?
        displayValue :
        react_1.default.createElement(InitiativeStatusDisplay_1.InitiativeStatusDisplay, { statusId: defaultValueNumber });
    return (react_1.default.createElement(react_if_1.If, { condition: !isReadOnly },
        react_1.default.createElement(react_if_1.Then, null,
            react_1.default.createElement(antd_1.Select, Object.assign({ defaultValue: defaultValue || String(value), disabled: disabled, getPopupContainer: popups_1.getDirectParent, id: id, onChange: handleChange, options: statusHandlers, suffixIcon: showArrow ? undefined : null, value: value ? String(value) : undefined }, rest))),
        react_1.default.createElement(react_if_1.Else, null, readOnlyLabel)));
};
exports.InitiativeStatusSelector = InitiativeStatusSelector;
