"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppTableBulkEditModal = void 0;
const notifications_1 = require("@ui/notifications");
const BulkEditModal_1 = require("@ui/modals/bulk/BulkEditModal");
const BulkEditModalSection_1 = require("@ui/modals/bulk/BulkEditModalSection");
const ClassificationBulkSelector_1 = require("@ui/tags/ClassificationBulkSelector");
const selectors_1 = require("@lib/selectors");
const lodash_1 = require("lodash");
const StakeholderSelector_1 = require("@ui/StakeholderSelector");
const UserSelector_1 = require("@ui/UserSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const asArray = (ids) => (Array.isArray(ids) ? ids : [ids]);
const bulkPatchSelector = (0, selectors_1.createSonarSelector)((state) => state.core.dataSecurity.applications.bulk.patch);
const AppTableBulkEditModal = ({ popoverTarget, selectableApps, selectionData, typeLabel }) => {
    const [ownerId, setOwnerId] = (0, react_1.useState)();
    const [stakeholderIds, setStakeholderIds] = (0, react_1.useState)();
    const [stakeholderGroupId, setStakeholderGroupId] = (0, react_1.useState)();
    const [classification, setClassification] = (0, react_1.useState)();
    const bulkPatchApplications = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreDataSecurityApplicationsBulk);
    const reset = () => {
        setOwnerId(undefined);
        setStakeholderIds(undefined);
        setStakeholderGroupId(undefined);
        setClassification(undefined);
        selectionData.resetSelection();
    };
    const disabled = !ownerId &&
        !stakeholderIds &&
        !stakeholderGroupId &&
        !classification;
    const handleOnOk = () => {
        bulkPatchApplications(Object.assign(Object.assign(Object.assign(Object.assign({ applicationIds: selectionData.selectAllEnabled ?
                (0, lodash_1.difference)(selectableApps.map((app) => app.applicationId), selectionData.selectedKeys) :
                selectionData.selectedKeys }, classification ? {
            classification: classification === 'null' ? null : classification
        } : {}), ownerId ? { ownerId } : {}), stakeholderGroupId ? { stakeholderGroupId: Number(stakeholderGroupId) } : {}), stakeholderIds ? { stakeholderIds: asArray(stakeholderIds) } : {}));
        reset();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(BulkEditModal_1.BulkEditModal, { className: 'app-table-bulk-edit-modal', disabled: disabled, onCancel: reset, onOk: handleOnOk, selectedCount: selectionData.selectedCount, targetNode: popoverTarget, typeLabel: typeLabel },
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.OWNER },
                react_1.default.createElement(UserSelector_1.UserSelector, { allowClear: true, avatar: true, onChange: setOwnerId, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.CHANGE_OWNER, value: ownerId })),
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.STAKEHOLDERS },
                react_1.default.createElement(StakeholderSelector_1.StakeholderSelector, { avatar: true, direction: 'horizontal', mode: 'multiple', onChange: setStakeholderIds, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.ADD_STAKEHOLDERS, value: stakeholderIds })),
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.GROUP },
                react_1.default.createElement(StakeholderSelector_1.StakeholderSelector, { avatar: true, onChange: setStakeholderGroupId, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.ADD_GROUP, stakeholderType: sonar_core_1.StakeholderType.Team, value: stakeholderGroupId })),
            react_1.default.createElement(BulkEditModalSection_1.BulkEditModalSection, { title: sonar_ts_constants_1.COPY_SPECIFIC.CLASSIFICATION },
                react_1.default.createElement(ClassificationBulkSelector_1.ClassificationBulkSelector, { boxless: false, onChange: setClassification, value: classification }))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_PATCH_APPLICATIONS_BULK, resetRawAction: sonar_core_1.coreActions.resetPatchCoreDataSecurityApplicationsBulk, stateSelector: bulkPatchSelector })));
};
exports.AppTableBulkEditModal = AppTableBulkEditModal;
