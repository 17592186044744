"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddNewUserModal = void 0;
const notifications_1 = require("@ui/notifications");
const sonar_core_1 = require("@sonar-software/sonar-core");
const events_1 = require("@lib/events");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const form_items_1 = require("@ui/form-items");
const modals_1 = require("@ui/modals");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const AddNewUserModal = ({ isOpen, onCancel, popoverPosition, popoverTarget }) => {
    const { errorNotification, successNotification } = (0, _hooks_1.useAppNotifications)();
    const { finished, idle, invite, loading, reset, result } = (0, _hooks_1.useInvite)();
    const reloadUsers = (0, _hooks_1.useAction)(sonar_core_1.coreActions.repeatGetCoreCustomersByIdUsers);
    const { Form, form } = (0, _hooks_1.useForm)({
        required: ['firstName', 'lastName', 'email']
    });
    (0, react_1.useEffect)(() => {
        if (!finished || !result) {
            return;
        }
        if (result === null || result === void 0 ? void 0 : result.invalidUsers.length) {
            errorNotification(sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_USER_INVITE);
        }
        else {
            successNotification(sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_USER_INVITE);
            reloadUsers();
            form.reset();
        }
    }, [errorNotification, finished, form, reloadUsers, result, successNotification]);
    const handleOnFinish = (0, react_1.useCallback)(() => {
        const { email, firstName, lastName } = form.getFieldsValue();
        invite({
            users: [
                {
                    email: email.trim(),
                    firstName,
                    lastName
                }
            ]
        });
    }, [form, invite]);
    const handleOnValuesChange = (0, react_1.useCallback)(() => {
        if (!idle && !loading) {
            reset();
        }
        form.validate();
    }, [form, idle, loading, reset]);
    const handleCancel = (0, react_1.useCallback)(() => {
        if (onCancel) {
            onCancel();
        }
        form.reset();
    }, [form, onCancel]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(modals_1.PopoverModal, { className: 'add-new-user-modal', confirmLoading: loading, destroyOnClose: true, okButtonProps: {
                disabled: !form.isValid,
                id: 'invite-user-submit-button'
            }, okText: sonar_ts_constants_1.COPY_SPECIFIC.ADD_NEW_USER, onCancel: handleCancel, onOk: handleOnFinish, open: isOpen, popoverPosition: popoverPosition, popoverTarget: popoverTarget, title: sonar_ts_constants_1.COPY_SPECIFIC.ADD_A_NEW_USER },
            react_1.default.createElement(Form, { form: form, layout: 'vertical', onFinish: handleOnFinish, onKeyUp: (0, events_1.handleEnter)(() => form.submit()), onValuesChange: handleOnValuesChange, requiredMark: false },
                react_1.default.createElement(antd_1.Row, { gutter: 16 },
                    react_1.default.createElement(antd_1.Col, null,
                        react_1.default.createElement(form_items_1.FirstName, { autoFocus: true, disabled: loading, id: 'invite-user-first-name', label: sonar_ts_constants_1.COPY_SPECIFIC.FIRST_NAME })),
                    react_1.default.createElement(antd_1.Col, null,
                        react_1.default.createElement(form_items_1.LastName, { disabled: loading, id: 'invite-user-last-name', label: sonar_ts_constants_1.COPY_SPECIFIC.LAST_NAME }))),
                react_1.default.createElement(form_items_1.Email, { disabled: loading, id: 'invite-user-email', label: sonar_ts_constants_1.COPY_SPECIFIC.EMAIL }))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_USER_INVITE, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_USER_INVITE, resetAction: reset, stateSelector: _hooks_1.useInvite.selectors.state })));
};
exports.AddNewUserModal = AddNewUserModal;
