"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeMenuLabel = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const wrapperStart = (name) => `${name} (`;
const wrapperEnd = ')';
const PeriscopeMenuLabel = ({ count, name }) => {
    const [countValue, setCountValue] = (0, react_1.useState)(count);
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    (0, _hooks_1.useOnMount)(() => {
        Promise.resolve(count).then((value) => {
            setCountValue(value);
            setIsLoading(false);
        });
    });
    if ((0, sonar_ts_lib_1.isUndefined)(count)) {
        return name;
    }
    if (isLoading) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            wrapperStart(name),
            react_1.default.createElement(general_1.LoadingDots, null),
            wrapperEnd));
    }
    return `${name} (${countValue !== null && countValue !== void 0 ? countValue : 0})`;
};
exports.PeriscopeMenuLabel = PeriscopeMenuLabel;
