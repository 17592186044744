"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphBehavior = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_router_1 = require("react-router");
const graphin_1 = require("@antv/graphin");
const react_1 = require("react");
const _hooks_1 = require("@hooks");
const GraphBehavior = () => {
    const { graph } = (0, react_1.useContext)(graphin_1.GraphinContext);
    const allowOverview = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataSecurityDashboard);
    const navigate = (0, react_router_1.useNavigate)();
    (0, _hooks_1.useOnMount)(() => {
        graph.on('combo:mouseenter', (evt) => {
            const { item } = evt;
            if (item) {
                graph.setItemState(item, 'active', true);
                item.getNodes().forEach((node) => {
                    node.getEdges().forEach((edge) => {
                        graph.setItemState(edge, 'inactive', false);
                    });
                });
            }
        });
        graph.on('combo:mouseleave', (evt) => {
            const { item } = evt;
            if (item) {
                graph.setItemState(item, 'active', false);
            }
        });
        graph.on('canvas:click', () => {
            graph.getCombos().forEach((combo) => {
                graph.clearItemStates(combo);
            });
            graph.getEdges().forEach((edge) => {
                graph.clearItemStates(edge);
            });
            graph.getNodes().forEach((node) => {
                graph.clearItemStates(node);
            });
        });
        graph.on('node:mouseenter', (evt) => {
            const { item } = evt;
            if (item) {
                graph.getEdges().forEach((edge) => {
                    graph.setItemState(edge, 'inactive', true);
                });
                item.getEdges().forEach((edge) => {
                    graph.setItemState(edge, 'inactive', false);
                });
            }
        });
        if (allowOverview) {
            graph.on('node:click', (evt) => {
                const { item } = evt;
                const applicationId = item === null || item === void 0 ? void 0 : item.getID();
                if (Number(applicationId)) {
                    navigate(`${sonar_ts_constants_1.FULL_ROUTES.DATA_SECURITY}/${applicationId}`);
                }
            });
        }
    });
    return null;
};
exports.GraphBehavior = GraphBehavior;
