"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SonarRecord = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./Record.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const events_1 = require("@lib/events");
const RecordContainer_1 = require("./RecordContainer");
const RecordNameSection_1 = require("./RecordNameSection");
const RecordReferences_1 = require("./RecordReferences");
const RecordRelationships_1 = require("./RecordRelationships");
const RecordRow_1 = require("./RecordRow");
const RecordSecondaryInfo_1 = require("./RecordSecondaryInfo");
const text_1 = require("../text");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const SonarRecord = ({ children, className, isActive, isDeleted, name, onClick, onClickParent, parentName, relatedCounts, relatedOrgs, relatedField, rightEnd, secondaryInfo, sonarObjectId, sonarObjectType, source, versionNumber, showType }) => {
    const handleOnClickParent = (0, react_1.useMemo)(() => (0, events_1.ifClickHandler)(onClickParent), [onClickParent]);
    const showRelatedCounts = sonarObjectType !== sonar_ts_types_1.SonarObjectType.SalesforceEntity;
    return (react_1.default.createElement(RecordContainer_1.SonarRecordContainer, { className: className, onClick: onClick },
        react_1.default.createElement(RecordRow_1.SonarRecordRow, null,
            react_1.default.createElement(antd_1.Col, { className: 'primary-info', flex: 'none' },
                react_1.default.createElement(antd_1.Space, { size: Number(css.size8) },
                    react_1.default.createElement(RecordNameSection_1.SonarRecordNameSection, { isActive: isActive, name: name, onClickParent: handleOnClickParent, parentName: parentName, versionNumber: versionNumber }),
                    react_1.default.createElement(RecordRelationships_1.SonarRecordRelationships, { relatedField: relatedField, relatedOrgs: relatedOrgs, source: source }),
                    react_1.default.createElement(RecordReferences_1.SonarRecordReferences, { parentName: parentName, relatedCounts: showRelatedCounts ? relatedCounts : undefined, sonarObjectId: sonarObjectId !== null && sonarObjectId !== void 0 ? sonarObjectId : 0 }),
                    react_1.default.createElement(react_if_1.When, { condition: isDeleted },
                        react_1.default.createElement(text_1.SonarText, { type: 'danger' }, sonar_ts_constants_1.COPY_SPECIFIC.DELETED)))),
            react_1.default.createElement(RecordSecondaryInfo_1.SonarRecordSecondaryInfo, { info: secondaryInfo, sonarObjectType: showType ? sonarObjectType : undefined }),
            rightEnd),
        children));
};
exports.SonarRecord = SonarRecord;
