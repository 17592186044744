"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldMetadataTab = void 0;
const context_1 = require("@periscopes/general/context");
const FieldInformation_1 = require("./FieldInformation");
const FieldTags_1 = require("./FieldTags");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const react_if_1 = require("react-if");
const fields_1 = require("@periscopes/general/fields");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const FieldMetadataTab = ({ sonarObjectId, index }) => {
    var _a, _b;
    const tagIds = (_b = (_a = (0, _hooks_1.useSonarObjectReference)(sonarObjectId)) === null || _a === void 0 ? void 0 : _a.tagIds) !== null && _b !== void 0 ? _b : [];
    const { update } = (0, _hooks_1.useUpdateSonarObjectReference)();
    const { loading, metadata } = (0, _hooks_1.usePeriscopeMetadata)(sonarObjectId, sonar_ts_types_1.SonarObjectType.SalesforceField);
    (0, react_1.useEffect)(() => {
        if (metadata && sonarObjectId) {
            update({
                internalName: metadata.internalName,
                linkedIntegrations: metadata.linkedIntegrations,
                parentDisplayName: metadata.parentDisplayName,
                parentInternalName: metadata.parentInternalName,
                parentSonarObjectId: metadata.parentSonarObjectId,
                rawSalesforceType: metadata.rawSalesforceType,
                salesforce: {
                    complianceCategory: metadata.complianceCategory,
                    dataSensitivityLevelId: metadata.dataSensitivityLevelId,
                    fieldUsageId: metadata.fieldUsageId,
                    helpText: metadata.helpText,
                    isCustom: metadata.isCustom
                },
                sonarObjectId
            });
            if (metadata.parentSonarObjectId) {
                update({
                    displayName: metadata.parentDisplayName,
                    sonarObjectId: metadata.parentSonarObjectId
                });
            }
        }
    }, [metadata, sonarObjectId, update]);
    if (!metadata || loading) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(FieldInformation_1.FieldInformation, { sonarObjectId: sonarObjectId }),
        react_1.default.createElement(fields_1.FormulaFieldMetaData, { formulaFieldMetaData: metadata.formulaFieldReferences, index: index }),
        react_1.default.createElement(fields_1.LookupFieldMetaData, { index: index, lookupEntities: metadata.lookupEntities }),
        react_1.default.createElement(react_if_1.When, { condition: tagIds.length },
            react_1.default.createElement(FieldTags_1.FieldTags, { index: index, tagIds: tagIds })),
        react_1.default.createElement(context_1.BusinessContext, { sonarObjectId: sonarObjectId })));
};
exports.FieldMetadataTab = FieldMetadataTab;
