"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNameAndLabelConflictMessage = exports.CONFLICT_MESSAGE = exports.DUPLICATE_MESSAGE = exports.MONITOR_VALIDATION_RULES = exports.APP_VALIDATION_RULES = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_http_1 = require("@sonar-software/sonar-http");
const validation_1 = require("@lib/validation");
exports.APP_VALIDATION_RULES = {
    DESCRIPTION: [(0, validation_1.getMaxRule)(sonar_ts_constants_1.COPY_SPECIFIC.DESCRIPTION, 65535)],
    LABEL: [(0, validation_1.getMaxRule)(sonar_ts_constants_1.COPY_SPECIFIC.LABEL, 256)],
    NAME: [
        (0, validation_1.getRequiredRule)(sonar_ts_constants_1.COPY_SPECIFIC.NAME),
        (0, validation_1.getMaxRule)(sonar_ts_constants_1.COPY_SPECIFIC.NAME, 80)
    ]
};
exports.MONITOR_VALIDATION_RULES = {
    NAME: [
        (0, validation_1.getRequiredRule)(sonar_ts_constants_1.COPY_SPECIFIC.NAME),
        (0, validation_1.getMaxRule)(sonar_ts_constants_1.COPY_SPECIFIC.NAME, 512)
    ]
};
exports.DUPLICATE_MESSAGE = 'Application already created.  Add a label for alternate environments like sandbox or production';
exports.CONFLICT_MESSAGE = 'Application with the same name and label already exists';
const setNameAndLabelConflictMessage = (value) => ({
    [sonar_http_1.ErrorOrFailStatusCode.Conflict]: {
        message: value ? exports.CONFLICT_MESSAGE : exports.DUPLICATE_MESSAGE,
        success: false
    }
});
exports.setNameAndLabelConflictMessage = setNameAndLabelConflictMessage;
