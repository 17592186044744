"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitiativeReference = useInitiativeReference;
exports.useInitiativeReferenceSelect = useInitiativeReferenceSelect;
const selectors_1 = require("@lib/selectors");
const useSonarSelector_1 = require("../useSonarSelector");
const mapSelector = (0, selectors_1.createSonarSelector)((state) => state.core.initiatives.byIdMap);
const empty = {
    ownerIds: [],
    scopeCounts: {},
    stakeholders: [],
    tagIds: [],
    tasks: []
};
/**
 * Subscribes to the shared state for a particular initiative.
 * @param initiativeId The id of the initiative to subscribe to.
 * @returns A {@link InitiativeReference} instance for the provided id.
 */
function useInitiativeReference(initiativeId) {
    return (0, useSonarSelector_1.useSonarSelector)((state) => { var _a, _b; return (_b = ((_a = mapSelector(state).data) !== null && _a !== void 0 ? _a : {})[initiativeId]) !== null && _b !== void 0 ? _b : empty; });
}
/**
 * Subscribes to a selection value for a particular initiative shared state.
 * @param initiativeId The id of the initiative to subscribe to.
 * @param select The select method to define the value to subscribe to.
 * @returns The return of the select.
 */
function useInitiativeReferenceSelect(initiativeId, select) {
    return (0, useSonarSelector_1.useSonarSelector)((state) => { var _a, _b; return select((_b = ((_a = mapSelector(state).data) !== null && _a !== void 0 ? _a : {})[initiativeId]) !== null && _b !== void 0 ? _b : empty); });
}
