"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePassword = void 0;
const form_items_1 = require("@ui/form-items");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const CurrentPasswordModal_1 = require("@ui/CurrentPasswordModal");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.users.byId.password.patch);
const ChangePassword = () => {
    const [displayModal, setDisplayModal] = (0, _hooks_1.useBoolean)(false);
    const [form] = antd_1.Form.useForm();
    const { successNotification } = (0, _hooks_1.useAppNotifications)();
    const { isErrored, isFailed, isRequested, isReceived } = (0, _hooks_1.useAsyncState)(selectors);
    const requestPasswordChange = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreUsersByIdPassword);
    const resetRequest = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPatchCoreUsersByIdPassword);
    const handleOk = (0, react_1.useCallback)((currentPassword) => {
        const desiredPassword = form.getFieldValue('confirmPassword');
        requestPasswordChange({
            currentPassword,
            desiredPassword
        });
    }, [form, requestPasswordChange]);
    (0, react_1.useEffect)(() => {
        if (isReceived) {
            resetRequest();
            setDisplayModal.off();
            form.resetFields();
            successNotification(sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_PATCH_USER_CHANGE_PASS);
        }
    }, [form, isReceived, resetRequest, setDisplayModal, successNotification]);
    return (react_1.default.createElement("div", { id: 'change-password' },
        react_1.default.createElement(antd_1.Form, { form: form, name: 'changePassword', onFinish: setDisplayModal.on },
            react_1.default.createElement(form_items_1.ConfirmPassword, { autoComplete: sonar_ts_constants_1.AUTOCOMPLETE.NEW_PASSWORD, disabled: isRequested, id: 'change-password' }),
            react_1.default.createElement(CurrentPasswordModal_1.CurrentPasswordModal, { error: isErrored, errorMessage: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_PATCH_USER_CHANGE_PASS, failed: isFailed, failedMessage: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_PATCH_USER_CHANGE_PASS, onCancel: setDisplayModal.off, onOk: handleOk, open: displayModal }),
            react_1.default.createElement(antd_1.Row, { justify: 'space-between' },
                react_1.default.createElement(antd_1.Button, { disabled: isRequested, htmlType: 'submit', id: 'change-password-submit-button', type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.CHANGE_PASSWORD)))));
};
exports.ChangePassword = ChangePassword;
