"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewPassword = void 0;
require("./NewPassword.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const validators = [
    {
        label: sonar_ts_constants_1.PASSWORD.REQUIREMENT_UPPERCASE,
        validate: (value) => (/[A-Z]/u).test(value)
    },
    {
        label: sonar_ts_constants_1.PASSWORD.REQUIREMENT_LOWERCASE,
        validate: (value) => (/[a-z]/u).test(value)
    },
    {
        label: sonar_ts_constants_1.PASSWORD.REQUIREMENT_NUMERIC,
        validate: (value) => (/.*\d/u).test(value)
    },
    {
        label: sonar_ts_constants_1.PASSWORD.REQUIREMENT_NONALPHANUMERIC,
        validate: (value) => (/.*\W/u).test(value)
    },
    {
        label: sonar_ts_constants_1.PASSWORD.REQUIREMENT_LENGTH,
        validate: (value) => value.length >= 8
    }
];
function getValidatorResults(password) {
    return (react_1.default.createElement(react_1.default.Fragment, null,
        " ",
        validators.map(({ label, validate }) => {
            const isValid = validate(password);
            return (react_1.default.createElement("p", { className: isValid ? 'valid' : '', key: label },
                react_1.default.createElement(antd_1.Checkbox, { checked: isValid }, label)));
        })));
}
const NewPassword = (_a) => {
    var { disabled, id, name = 'password' } = _a, props = __rest(_a, ["disabled", "id", "name"]);
    const [password, setPassword] = (0, react_1.useState)('');
    const handleChange = (0, react_1.useCallback)((e) => {
        var _a, _b;
        const value = (_b = (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.trim()) !== null && _b !== void 0 ? _b : '';
        const formProps = props;
        if (formProps.onChange) {
            e.persist();
            formProps.onChange(e);
        }
        setPassword(value);
    }, [props]);
    const validatorResults = (0, react_1.useMemo)(() => getValidatorResults(password), [password]);
    return (react_1.default.createElement(antd_1.Popover, { content: validatorResults, overlayClassName: 'new-password-validation', placement: 'right', trigger: 'focus' },
        react_1.default.createElement(antd_1.Input.Password, Object.assign({}, props, { disabled: disabled, id: id, name: name, onChange: handleChange, placeholder: sonar_ts_constants_1.PASSWORD.PLACEHOLDER_NEW, type: 'text', value: password }))));
};
exports.NewPassword = NewPassword;
