"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopulationPercentageFilter = void 0;
require("./PopulationPercentage.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const lodash_1 = require("lodash");
const FilterComponent_1 = require("./FilterComponent");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const min = 0;
const max = 100;
const PopulationPercentageFilter = (props) => {
    var _a, _b, _c;
    const { format, id, onChange, value, label } = props;
    const [displayRange, setDisplayRange] = (0, react_1.useState)([(_a = value === null || value === void 0 ? void 0 : value[0]) !== null && _a !== void 0 ? _a : min, (_b = value === null || value === void 0 ? void 0 : value[1]) !== null && _b !== void 0 ? _b : max]);
    const [excludeNulls, setExcludeNulls] = (0, react_1.useState)((_c = value === null || value === void 0 ? void 0 : value[2]) !== null && _c !== void 0 ? _c : false);
    const showClearButton = (value === null || value === void 0 ? void 0 : value[0]) || (value === null || value === void 0 ? void 0 : value[1]);
    const excludeNullsText = (value === null || value === void 0 ? void 0 : value[2]) ? ` ${sonar_ts_constants_1.COPY_SPECIFIC.EXCLUDING_EMPTY}` : '';
    const tagText = `${[value === null || value === void 0 ? void 0 : value[0], value === null || value === void 0 ? void 0 : value[1]].map((num) => `${num}%`).join(' - ')}${excludeNullsText}`;
    (0, react_1.useEffect)(() => {
        if (!value) {
            setDisplayRange([min, max]);
            setExcludeNulls(false);
        }
    }, [value]);
    const debouncedOnChange = (0, react_1.useMemo)(() => (0, lodash_1.debounce)((range, hideNulls) => {
        const [start, end] = range;
        const removeFilter = start === min && end === max && !hideNulls;
        setDisplayRange(range);
        onChange({
            [id]: {
                format,
                value: removeFilter ? null : [start, end, hideNulls]
            }
        });
    }, 300, { leading: false }), [format, id, onChange]);
    const handleChangeRange = (0, react_1.useCallback)((range) => {
        setDisplayRange(range);
        debouncedOnChange(range, excludeNulls);
    }, [debouncedOnChange, excludeNulls]);
    const handleChangeStart = (0, react_1.useCallback)((start) => {
        const [, end] = displayRange;
        setDisplayRange([start, end]);
        debouncedOnChange([start, Math.max(start, end)], excludeNulls);
    }, [debouncedOnChange, displayRange, excludeNulls]);
    const handleChangeEnd = (0, react_1.useCallback)((end) => {
        const [start] = displayRange;
        setDisplayRange([start, end]);
        debouncedOnChange([Math.min(start, end), end], excludeNulls);
    }, [debouncedOnChange, displayRange, excludeNulls]);
    const handleToggleNulls = (0, react_1.useCallback)((toggle) => {
        setExcludeNulls(toggle);
        debouncedOnChange(displayRange, toggle);
    }, [debouncedOnChange, displayRange]);
    return (react_1.default.createElement(FilterComponent_1.FilterComponent, Object.assign({}, props, { className: 'population-percentage-filter', tagProps: {
            label,
            value: showClearButton ? tagText : null
        } }),
        react_1.default.createElement(antd_1.Space, { direction: 'vertical', size: 4 },
            react_1.default.createElement(antd_1.Flex, { align: 'center', gap: 16 },
                react_1.default.createElement(antd_1.InputNumber, { max: max, min: min, onChange: handleChangeStart, value: displayRange[0] }),
                react_1.default.createElement(antd_1.Slider, { max: max, min: min, onChange: handleChangeRange, range: true, value: [displayRange[0], displayRange[1]] }),
                react_1.default.createElement(antd_1.InputNumber, { max: max, min: min, onChange: handleChangeEnd, value: displayRange[1] })),
            react_1.default.createElement(antd_1.Space, { size: 8 },
                react_1.default.createElement(antd_1.Switch, { checked: excludeNulls !== null && excludeNulls !== void 0 ? excludeNulls : value === null || value === void 0 ? void 0 : value[2], onChange: handleToggleNulls }),
                sonar_ts_constants_1.COPY_SPECIFIC.EXCLUDE_EMPTY_VALUES))));
};
exports.PopulationPercentageFilter = PopulationPercentageFilter;
