"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotRule = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./PardotRule.scss");
const events_1 = require("@lib/events");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const records_1 = require("@ui/records");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const { Title } = antd_1.Typography;
const PardotRule = ({ entity, onRuleClick }) => {
    const { description, displayName, sonarObjectId, sonarObjectType } = entity;
    const ruleReferenceType = (0, sonar_ts_lib_1.lowerFirst)(entity.sonarObjectType);
    const clickable = Boolean(sonarObjectType) && Boolean(sonarObjectId);
    const handleOnClick = (0, react_1.useMemo)(() => (0, events_1.ifClickHandler)(onRuleClick, entity), [entity, onRuleClick]);
    const formattedDisplayName = (0, react_1.useMemo)(() => {
        if (!(displayName === null || displayName === void 0 ? void 0 : displayName.includes('\n'))) {
            return displayName;
        }
        return displayName === null || displayName === void 0 ? void 0 : displayName.split('\n').map((entry) => {
            const [title, value] = entry.split(':');
            if (!value) {
                return title.trim();
            }
            const formattedTitle = `${title === null || title === void 0 ? void 0 : title.trim()}: `;
            return (react_1.default.createElement(react_1.default.Fragment, { key: entry },
                react_1.default.createElement("strong", null, formattedTitle),
                value,
                react_1.default.createElement("br", null)));
        });
    }, [displayName]);
    return (react_1.default.createElement("div", { className: 'pardot-rule' },
        react_1.default.createElement(antd_1.Row, { align: 'middle', gutter: Number(css.size4), justify: 'space-between', wrap: false },
            react_1.default.createElement(antd_1.Col, { flex: 'none' },
                react_1.default.createElement(Title, { className: 'rule-label', level: 4 }, description))),
        react_1.default.createElement(records_1.SonarRecord, { name: formattedDisplayName, onClick: clickable ? handleOnClick : undefined, sonarObjectId: sonarObjectId, sonarObjectType: ruleReferenceType })));
};
exports.PardotRule = PardotRule;
