"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotFieldScope = void 0;
const pardot_completion_actions_1 = require("@periscopes/general/pardot-completion-actions");
const pardot_fields_1 = require("@periscopes/general/pardot-fields");
const pardot_rules_1 = require("@periscopes/general/pardot-rules");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const _hooks_1 = require("@hooks");
const _1 = require(".");
const general_1 = require("@periscopes/general");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const PardotFieldScope = ({ data, index }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { sonarObjectId, relatedCounts = {}, tagIds } = data;
    const fieldTagIds = (0, react_1.useMemo)(() => tagIds !== null && tagIds !== void 0 ? tagIds : [], [tagIds]);
    const tagCount = (_a = fieldTagIds === null || fieldTagIds === void 0 ? void 0 : fieldTagIds.length) !== null && _a !== void 0 ? _a : 0;
    const handleParentClick = (0, react_1.useCallback)((field) => {
        const { parentDisplayName, parentSonarObjectId } = field;
        openPeriscope({
            id: parentSonarObjectId,
            name: parentDisplayName,
            openLeft: true,
            openedFromIndex: index,
            type: sonar_ts_types_1.SonarObjectType.PardotEntity
        });
    }, [openPeriscope, index]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(pardot_fields_1.PardotField, { field: data, key: sonarObjectId, onParentClick: handleParentClick, showParent: true }),
        react_1.default.createElement(general_1.PeriscopeMenuTabs, null,
            react_1.default.createElement(general_1.PeriscopeTabArea, { key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.FIELD_METADATA, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.FIELD_METADATA },
                react_1.default.createElement(_1.PardotFieldMetadataTab, { index: index, picklistValueCount: (_b = relatedCounts[sonar_ts_types_1.SonarObjectType.SalesforcePicklistValue]) !== null && _b !== void 0 ? _b : 0, sonarObjectId: sonarObjectId })),
            react_1.default.createElement(general_1.PeriscopeTabArea, { count: (_c = relatedCounts[sonar_ts_types_1.SonarObjectType.SalesforceField]) !== null && _c !== void 0 ? _c : 0, key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.RELATED_TECH, requiresCount: true, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.RELATED_TECH },
                react_1.default.createElement(_1.PardotFieldRelatedTechTab, { index: index, sonarObjectId: sonarObjectId })),
            react_1.default.createElement(general_1.PeriscopeTabArea, { count: (_d = relatedCounts.pardotCompletionActionReference) !== null && _d !== void 0 ? _d : 0, key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.COMPLETION_ACTION_REFERENCES, requiresCount: true, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.COMPLETION_ACTION_REFERENCES },
                react_1.default.createElement(pardot_completion_actions_1.PardotCompletionActionReferencesTab, { index: index, sonarObjectId: sonarObjectId })),
            react_1.default.createElement(general_1.PeriscopeTabArea, { count: (_e = relatedCounts.pardotAutomationRuleReference) !== null && _e !== void 0 ? _e : 0, key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.RULE_REFERENCES, requiresCount: true, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.RULE_REFERENCES },
                react_1.default.createElement(pardot_rules_1.PardotRuleReferencesTab, { index: index, sonarObjectId: sonarObjectId })),
            react_1.default.createElement(general_1.PeriscopeTabArea, { count: (_f = relatedCounts[sonar_ts_types_1.SonarObjectType.PardotForm]) !== null && _f !== void 0 ? _f : 0, key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PARDOT_FORMS, requiresCount: true, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PARDOT_FORMS },
                react_1.default.createElement(_1.PardotFieldFormTab, { index: index, sonarObjectId: sonarObjectId })),
            react_1.default.createElement(general_1.PeriscopeTabArea, { count: (_g = relatedCounts[sonar_ts_types_1.SonarObjectType.PardotEmailTemplate]) !== null && _g !== void 0 ? _g : 0, key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PARDOT_EMAIL_TEMPLATES, requiresCount: true, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PARDOT_EMAIL_TEMPLATES },
                react_1.default.createElement(_1.PardotFieldEmailTemplateTab, { index: index, sonarObjectId: sonarObjectId })),
            react_1.default.createElement(general_1.PeriscopeTabArea, { count: (_h = relatedCounts[sonar_ts_types_1.SonarObjectType.PardotEmail]) !== null && _h !== void 0 ? _h : 0, key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PARDOT_EMAILS, requiresCount: true, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PARDOT_EMAILS },
                react_1.default.createElement(_1.PardotFieldEmailTab, { index: index, sonarObjectId: sonarObjectId })),
            react_1.default.createElement(general_1.PeriscopeTabArea, { count: tagCount, key: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.TAGS, requiresCount: true, tab: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.TAGS },
                react_1.default.createElement(_1.PardotFieldTagsTab, { index: index, tagIds: fieldTagIds })))));
};
exports.PardotFieldScope = PardotFieldScope;
