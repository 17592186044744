"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferenceCountFilter = void 0;
require("./ReferenceCount.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const lodash_1 = require("lodash");
const FilterComponent_1 = require("./FilterComponent");
const _hooks_1 = require("@hooks");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const ReferenceCountFilter = (props) => {
    var _a, _b, _c;
    const { format, id, onChange, value, label } = props;
    const startValue = (value === null || value === void 0 ? void 0 : value[0]) || (value === null || value === void 0 ? void 0 : value[1]) ? 0 : null;
    const endValue = (_a = value === null || value === void 0 ? void 0 : value[1]) !== null && _a !== void 0 ? _a : null;
    const [displayRange, setDisplayRange] = (0, react_1.useState)([startValue, endValue]);
    const showClearButton = (value === null || value === void 0 ? void 0 : value[0]) || (value === null || value === void 0 ? void 0 : value[1]);
    const previousValue = (0, _hooks_1.usePrevious)(value);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if ((value === null || value === void 0 ? void 0 : value[0]) !== (previousValue === null || previousValue === void 0 ? void 0 : previousValue[0]) || (value === null || value === void 0 ? void 0 : value[1]) !== (previousValue === null || previousValue === void 0 ? void 0 : previousValue[1])) {
            const start = (_a = value === null || value === void 0 ? void 0 : value[0]) !== null && _a !== void 0 ? _a : null;
            const end = (_b = value === null || value === void 0 ? void 0 : value[1]) !== null && _b !== void 0 ? _b : null;
            setDisplayRange([start, end]);
        }
    }, [previousValue, value]);
    const debouncedOnChange = (0, react_1.useMemo)(() => (0, lodash_1.debounce)((start, end) => {
        setDisplayRange([start, end]);
        onChange({
            [id]: {
                format,
                value: start || end ? [start, end, true] : null
            }
        });
    }, 300, { leading: false }), [format, id, onChange]);
    const handleChangeStart = (0, react_1.useCallback)((start) => {
        const [, end] = displayRange;
        const validEnd = start && end !== null && start > end ? null : end;
        setDisplayRange([start, end]);
        debouncedOnChange(start, validEnd);
    }, [debouncedOnChange, displayRange]);
    const handleChangeEnd = (0, react_1.useCallback)((end) => {
        const [start] = displayRange;
        const validStart = start && end !== null && start > end ? null : start;
        setDisplayRange([start, end]);
        debouncedOnChange(validStart, end);
    }, [debouncedOnChange, displayRange]);
    const tagLabelStart = (_b = displayRange[0]) !== null && _b !== void 0 ? _b : 0;
    const tagLabelEnd = ((_c = displayRange[1]) !== null && _c !== void 0 ? _c : 0) > 0 ? ` - ${displayRange[1]}` : '+';
    return (react_1.default.createElement(FilterComponent_1.FilterComponent, Object.assign({}, props, { className: 'reference-count-filter', tagProps: {
            label,
            value: showClearButton ? `${tagLabelStart}${tagLabelEnd}` : null
        } }),
        react_1.default.createElement(antd_1.Flex, { align: 'center', gap: 16 },
            sonar_ts_constants_1.COPY_GENERIC.FROM,
            react_1.default.createElement(antd_1.InputNumber, { min: 0, onChange: handleChangeStart, value: displayRange[0] || (displayRange[1] ? 0 : null) }),
            sonar_ts_constants_1.COPY_GENERIC.TO,
            react_1.default.createElement(antd_1.InputNumber, { min: 0, onChange: handleChangeEnd, value: displayRange[1] || null }))));
};
exports.ReferenceCountFilter = ReferenceCountFilter;
