"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetectedApplicationActions = exports.hideApplicationSelectors = exports.unhideApplicationSelectors = void 0;
const xuder_1 = require("@sonar-software/xuder");
const modals_1 = require("@ui/modals");
const selectors_1 = require("@lib/selectors");
const general_1 = require("@ui/general");
const sonar_http_1 = require("@sonar-software/sonar-http");
const react_router_1 = require("react-router");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const detectedStatus = sonar_core_1.DataSecurityApplicationStatus[sonar_core_1.DataSecurityApplicationStatus.Detected].toLowerCase();
const hiddenStatus = sonar_core_1.DataSecurityApplicationStatus[sonar_core_1.DataSecurityApplicationStatus.Deleted].toLowerCase();
exports.unhideApplicationSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.applications.unhide);
exports.hideApplicationSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.applications.hide);
const dropdownButtonProps = {
    buttonsRender: ([leftButton, rightButton]) => [
        react_1.default.createElement("div", { key: 'leftButton' }, leftButton),
        react_1.default.createElement("div", { key: 'rightButton' },
            react_1.default.createElement(general_1.FullUserBoundary, null, rightButton))
    ],
    className: 'investigate-menu',
    placement: 'bottomRight'
};
const DetectedApplicationActions = ({ applicationId, onStatusChange, status }) => {
    const navigate = (0, react_router_1.useNavigate)();
    const allowOverview = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataSecurityDashboard);
    const hideApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreDataSecurityApplications);
    const unhideApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreDataSecurityApplications);
    const resetHideApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetDeleteCoreDataSecurityApplications);
    const resetUnhideApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPatchCoreDataSecurityApplications);
    const unhideProcess = (0, _hooks_1.useAsyncProcessing)({
        resetAction: resetUnhideApplication,
        stateSelector: exports.unhideApplicationSelectors.state,
        statusHandlers: {
            [xuder_1.AsyncStateStatus.Received]: () => onStatusChange(detectedStatus)
        }
    });
    const hideProcess = (0, _hooks_1.useAsyncProcessing)({
        resetAction: resetHideApplication,
        stateSelector: exports.hideApplicationSelectors.state,
        statusHandlers: {
            [xuder_1.AsyncStateStatus.Received]: () => onStatusChange(hiddenStatus)
        }
    });
    const handleUnhide = (0, react_1.useCallback)(() => {
        unhideProcess.watchNextCall();
        unhideApplication({ applicationId });
    }, [applicationId, unhideProcess, unhideApplication]);
    const handleHide = (0, react_1.useCallback)(() => {
        hideProcess.watchNextCall();
        hideApplication({ applicationId });
    }, [applicationId, hideProcess, hideApplication]);
    const isHidden = status === hiddenStatus;
    if (isHidden) {
        return (react_1.default.createElement(general_1.FullUserBoundary, null,
            react_1.default.createElement(antd_1.Button, { onClick: handleUnhide, size: 'small' }, sonar_ts_constants_1.COPY_SPECIFIC.UNHIDE)));
    }
    const investigateButtonProps = {
        children: sonar_ts_constants_1.COPY_SPECIFIC.INVESTIGATE,
        onClick: () => navigate(`${sonar_ts_constants_1.FULL_ROUTES.DATA_SECURITY}/${applicationId}`)
    };
    const monitorEntry = {
        key: 'monitor',
        label: sonar_ts_constants_1.COPY_SPECIFIC.MONITOR_APP,
        onClick: () => navigate(`${sonar_ts_constants_1.FULL_ROUTES.ADD_APP}?${(0, sonar_http_1.getQueryString)({ applicationId })}`)
    };
    const hideEntry = {
        key: 'hide',
        label: react_1.default.createElement(modals_1.ConfirmDelete, { className: 'confirm-hide', confirmButtonProps: {
                value: sonar_ts_constants_1.COPY_SPECIFIC.HIDE
            }, onConfirm: handleHide, title: sonar_ts_constants_1.MESSAGES_COPY.ARE_YOU_SURE_INTEGRATION_APP }, sonar_ts_constants_1.COPY_SPECIFIC.HIDE)
    };
    if (allowOverview) {
        return (react_1.default.createElement(antd_1.Dropdown.Button, Object.assign({}, dropdownButtonProps, investigateButtonProps, { menu: {
                items: [monitorEntry, hideEntry]
            } })));
    }
    return (react_1.default.createElement(antd_1.Dropdown.Button, Object.assign({}, dropdownButtonProps, { menu: {
            items: [hideEntry]
        }, onClick: monitorEntry.onClick }), monitorEntry.label));
};
exports.DetectedApplicationActions = DetectedApplicationActions;
