"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnerFilter = void 0;
const FilterComponent_1 = require("./FilterComponent");
const popups_1 = require("@lib/popups");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const filters_1 = require("@periscopes/general/filters");
const _hooks_1 = require("@hooks");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const OwnerFilter = (props) => {
    const { format, id, onChange, value } = props;
    const users = (0, _hooks_1.useUsers)();
    const options = (0, react_1.useMemo)(() => {
        const userMap = users.map((user) => {
            const { fullName, id: userId } = user;
            return {
                label: fullName,
                value: userId
            };
        });
        userMap.unshift({
            label: sonar_ts_constants_1.COPY_SPECIFIC.NO_OWNER,
            value: -1
        });
        return userMap;
    }, [users]);
    return (react_1.default.createElement(FilterComponent_1.FilterComponent, Object.assign({}, props, { label: sonar_ts_constants_1.COPY_SPECIFIC.OWNER, tagProps: {
            value: options
                .filter((option) => value === null || value === void 0 ? void 0 : value.includes(option.value))
                .map((option) => option.label)
                .join(', ')
        } }),
        react_1.default.createElement(filters_1.OptionFilter, { className: 'filter', getPopupContainer: popups_1.getDirectParent, id: (0, lib_1.getFilterId)(id), maxTagCount: 'responsive', mode: 'multiple', onChange: (ownerIds) => {
                onChange({
                    [id]: {
                        format,
                        value: ownerIds
                    }
                });
            }, options: options, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY_OWNER, showArrow: true, value: value })));
};
exports.OwnerFilter = OwnerFilter;
