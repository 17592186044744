"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.JiraSummary = void 0;
require("./JiraSummary.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("../../@arch/selectors");
const sonar_integrations_1 = require("@sonar-software/sonar-integrations");
const icons_1 = require("@ui/icons");
const JiraIssue_1 = require("./JiraIssue");
const notifications_1 = require("../../notifications");
const text_1 = require("@ui/text");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const JiraSummary = ({ initiativeId }) => {
    const getInitiativesJiraIssues = (0, _hooks_1.useAction)(sonar_integrations_1.integrationsActions.requestGetInitiativeJiraIssues);
    const getJiraSummary = (0, _hooks_1.useAction)(sonar_integrations_1.integrationsActions.requestGetJiraSummary);
    const refreshInitiativeJiraIssues = (0, react_1.useCallback)(() => {
        getInitiativesJiraIssues({
            initiativeId
        });
    }, [getInitiativesJiraIssues, initiativeId]);
    (0, _hooks_1.useOnMount)(() => {
        refreshInitiativeJiraIssues();
        getJiraSummary();
    });
    (0, _hooks_1.useInterval)(refreshInitiativeJiraIssues, 10000);
    const jiraIssueData = (0, _hooks_1.useSonarSelector)(selectors_1.getInitiativeJiraIssuesSelector.data);
    const jiraIssues = jiraIssueData === null || jiraIssueData === void 0 ? void 0 : jiraIssueData.map((jiraIssue) => {
        const { issueName, issueKey, issueStatusId, isSynced, projectKey } = jiraIssue;
        return (react_1.default.createElement(JiraIssue_1.JiraIssue, { initiativeId: initiativeId, issueKey: issueKey, issueName: issueName, isSynced: isSynced, key: issueKey, projectKey: projectKey, statusId: issueStatusId, summary: true }));
    });
    if (!(jiraIssueData === null || jiraIssueData === void 0 ? void 0 : jiraIssueData.length)) {
        return null;
    }
    return (react_1.default.createElement("div", { className: 'jira-issues-summary' },
        react_1.default.createElement(notifications_1.PostJiraIssueSyncNotifications, null),
        react_1.default.createElement("div", { className: 'summary-label' },
            react_1.default.createElement(icons_1.JiraIcon, { className: 'jira-icon' }),
            react_1.default.createElement(text_1.SonarText, { className: 'summary-title', size: 16, strong: true }, sonar_ts_constants_1.COPY_SPECIFIC.JIRA_ISSUES)),
        jiraIssues));
};
exports.JiraSummary = JiraSummary;
