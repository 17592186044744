"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotFieldSelectionTable = void 0;
const __1 = require("../");
const _1 = require("./");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const tables_1 = require("@ui/tables");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const PardotFieldSelectionTable = ({ parentIndex, resourceIdsUri, showParents, uri }) => {
    const { resource, loading } = (0, _hooks_1.useApiResource)(uri);
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        resourceIdsUri,
        results: resource,
        selectionType: sonar_ts_types_1.SonarObjectType.PardotField
    });
    const renderField = (0, react_1.useCallback)((field) => react_1.default.createElement(_1.PardotFieldRow, { field: field, key: field.sonarObjectId, parentIndex: parentIndex, showParents: showParents }), [parentIndex, showParents]);
    return (react_1.default.createElement(tables_1.SelectionTable, { dataSource: resource, forceShowSelection: resourceIdsUri ? undefined : false, loading: loading, renderRow: renderField, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.FIELDS }));
};
exports.PardotFieldSelectionTable = PardotFieldSelectionTable;
