"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceDescription = void 0;
const general_1 = require("@periscopes/general");
const PlaintextCellEditor_1 = require("./PlaintextCellEditor");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const SuggestPlaintextCellEditor_1 = require("./SuggestPlaintextCellEditor");
const react_if_1 = require("react-if");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const _hooks_1 = require("@hooks");
const general_2 = require("@ui/general");
const react_1 = __importStar(require("react"));
const SuggestPlaintextEditor_1 = require("./SuggestPlaintextEditor");
function updateDescription(sonarObjectId, description) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const result = yield (0, sonar_core_1.patchSalesforceSonarObject)({
            description,
            sonarObjectId
        });
        return (_a = result.data) !== null && _a !== void 0 ? _a : _hooks_1.genericProcessingFailure;
    });
}
function isCustomOnlyType(type) {
    return type === sonar_ts_types_1.SonarObjectType.SalesforceEntity;
}
function getReadOnlyMessage(metadata, type) {
    const { isCustom, managedPackage } = metadata !== null && metadata !== void 0 ? metadata : {};
    if (isCustomOnlyType(type) && !isCustom) {
        return sonar_ts_constants_1.COPY_SPECIFIC.UNABLE_TO_UPDATE_STANDARD_SALESFORCE;
    }
    if (managedPackage) {
        return sonar_ts_constants_1.COPY_SPECIFIC.UNABLE_TO_UDPATE_MANAGED_SALESFORCE;
    }
    return undefined;
}
function getMaxLengthForType(type) {
    switch (type) {
        case sonar_ts_types_1.SonarObjectType.SalesforceEntity:
        case sonar_ts_types_1.SonarObjectType.SalesforceField:
            return 1000;
        case sonar_ts_types_1.SonarObjectType.SalesforceEmailTemplate:
            return 4000;
        default:
            return 255;
    }
}
function isSuggestionAllowedForType(type) {
    return type !== sonar_ts_types_1.SonarObjectType.SalesforceValidationRule && type !== sonar_ts_types_1.SonarObjectType.SalesforceFieldUpdate;
}
const SalesforceDescription = ({ isSpreadsheet, sonarObjectId }) => {
    const { reset, start, state, stop } = (0, _hooks_1.useProcessing)();
    const { salesforce, sonarObjectType } = (0, _hooks_1.useSonarObjectReference)(sonarObjectId);
    const { update } = (0, _hooks_1.useUpdateSonarObjectReference)();
    const isEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataDictionary);
    const [processingDone, setProcessingDone] = (0, _hooks_1.useBoolean)(true);
    const autodocType = sonar_core_1.AutodocType.Description;
    const { description, isCustom, managedPackage } = salesforce !== null && salesforce !== void 0 ? salesforce : {};
    const isReadOnly = (isCustomOnlyType(sonarObjectType) && !isCustom) || Boolean(managedPackage);
    const isDisabled = !isEnabled || isReadOnly || state.isProcessing;
    const isSuggestionAllowed = isSuggestionAllowedForType(sonarObjectType);
    const suggestion = (0, _hooks_1.useSuggestionEditor)({
        autodocType,
        isReadOnly,
        sonarObjectId,
        value: description
    });
    (0, react_1.useEffect)(() => {
        if (!state.isProcessing && state.result) {
            setTimeout(setProcessingDone.on, 5000);
        }
        else if (state.isProcessing) {
            setProcessingDone.off();
        }
    }, [setProcessingDone, state.isProcessing, state.result]);
    const handleSave = (0, react_1.useCallback)((value) => __awaiter(void 0, void 0, void 0, function* () {
        if (value === description || (!value && !description)) {
            return;
        }
        start();
        const updateResult = yield updateDescription(sonarObjectId, value);
        if (updateResult === null || updateResult === void 0 ? void 0 : updateResult.success) {
            update({
                salesforce: { description: value },
                sonarObjectId
            });
        }
        stop(updateResult);
    }), [description, sonarObjectId, start, stop, update]);
    const editorProps = {
        allowNewline: true,
        autodocType,
        canBeEmpty: true,
        isDisabled,
        isProcessing: state.isProcessing,
        isReadOnly,
        isSalesforce: true,
        maxLength: getMaxLengthForType(sonarObjectType),
        onSave: handleSave,
        onStartEditing: reset,
        placeholder: isSpreadsheet ? undefined : sonar_ts_constants_1.PLACEHOLDERS_COPY.ADD_A_DESCRIPTION,
        processingResult: state.result,
        saveOnBlur: true,
        sonarObjectId,
        suggestion,
        tooltip: isEnabled ? getReadOnlyMessage(salesforce, sonarObjectType) : undefined,
        value: description
    };
    if (isSpreadsheet) {
        return isSuggestionAllowed ?
            react_1.default.createElement(SuggestPlaintextCellEditor_1.SuggestPlaintextCellEditor, Object.assign({}, editorProps)) :
            react_1.default.createElement(PlaintextCellEditor_1.PlaintextCellEditor, Object.assign({}, editorProps));
    }
    const showSuggestButton = isSuggestionAllowed && processingDone && !suggestion.isEditing && !suggestion.value && !suggestion.loading;
    return (react_1.default.createElement(general_1.DetailSubsection, { title: sonar_ts_constants_1.COPY_SPECIFIC.DESCRIPTION, titleExtra: react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(react_if_1.When, { condition: showSuggestButton },
                react_1.default.createElement(general_2.SuggestionButton, { autodocType: autodocType, onClick: suggestion.request })),
            react_1.default.createElement(general_2.ProcessingIcon, { isProcessing: state.isProcessing, isSalesforce: true, result: state.result })) },
        react_1.default.createElement(SuggestPlaintextEditor_1.SuggestPlaintextEditor, Object.assign({}, editorProps))));
};
exports.SalesforceDescription = SalesforceDescription;
