"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineRecord = void 0;
require("./TimelineRecord.scss");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const lib_1 = require("@timeline/@arch/lib");
const records_1 = require("@ui/records");
const general_1 = require("@ui/general");
const icons_1 = require("@ant-design/icons");
const events_1 = require("@lib/events");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const TimelineRecord = ({ parentSonarObjectId, record }) => {
    var _a, _b;
    const [isExpanded, setIsExpanded] = (0, react_1.useState)(true);
    const isExpandable = Boolean(record.change.changeDetail);
    const handleClick = (0, react_1.useCallback)(() => setIsExpanded((value) => !value), []);
    const handleExpand = (0, react_1.useCallback)(() => setIsExpanded(true), []);
    const handleCollapse = (0, react_1.useCallback)(() => setIsExpanded(false), []);
    (0, _hooks_1.useOnMount)(() => {
        if (!isExpandable) {
            return;
        }
        (0, events_1.on)(`periscope-timeline-${parentSonarObjectId}:expandAll`, handleExpand);
        (0, events_1.on)(`periscope-timeline-${parentSonarObjectId}:collapseAll`, handleCollapse);
    });
    (0, _hooks_1.useOnUnmount)(() => {
        if (!isExpandable) {
            return;
        }
        (0, events_1.off)(`periscope-timeline-${parentSonarObjectId}:expandAll`, handleExpand);
        (0, events_1.off)(`periscope-timeline-${parentSonarObjectId}:collapseAll`, handleCollapse);
    });
    const labelBase = (0, lib_1.getChangeTypeToChangeLabel)(record.change.changeType);
    const label = isExpandable ? `${labelBase} - ${record.change.changeDetail}` : labelBase;
    return (react_1.default.createElement("div", { className: 'timeline-record' },
        react_1.default.createElement(records_1.SonarRecord, { name: react_1.default.createElement(react_1.default.Fragment, null,
                isExpandable ?
                    react_1.default.createElement(icons_1.RobotOutlined, { className: 'timeline-record-icon' }) :
                    react_1.default.createElement(icons_1.FileAddOutlined, { className: 'timeline-record-icon' }),
                react_1.default.createElement("span", { className: 'timeline-record-label' }, label),
                isExpandable &&
                    react_1.default.createElement(general_1.ExpandCarat, { className: 'timeline-record-expand', expanded: isExpanded, onClick: handleClick })), onClick: isExpandable ? handleClick : undefined, secondaryInfo: react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("span", null, record.lastModifiedByName),
                react_1.default.createElement("span", { className: 'timeline-record-timestamp' }, (0, sonar_ts_lib_1.convertISODateToShortDate)(record.change.timestamp))), sonarObjectId: record.sonarObjectId }, isExpandable && isExpanded &&
            react_1.default.createElement("div", { className: 'timeline-record-diff-row' },
                react_1.default.createElement(general_1.CodeComparison, { newCode: (_a = record.change.newValue) !== null && _a !== void 0 ? _a : sonar_ts_constants_1.COPY_SPECIFIC.NONE_PARENTHETICAL, oldCode: (_b = record.change.oldValue) !== null && _b !== void 0 ? _b : sonar_ts_constants_1.COPY_SPECIFIC.NONE_PARENTHETICAL })))));
};
exports.TimelineRecord = TimelineRecord;
