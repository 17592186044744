"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectOrganizationView = void 0;
require("./ConnectOrganizationView.scss");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const icons_1 = require("@ant-design/icons");
const _hooks_1 = require("@hooks");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const general_1 = require("../general");
const general_2 = require("./general");
const react_1 = __importStar(require("react"));
function getRedirectState() {
    const { subdomain } = new sonar_ts_lib_1.DeconstructedURL(window.location.href);
    return `${subdomain};onboarding`;
}
const ConnectOrganizationView = () => {
    const redirectState = (0, react_1.useMemo)(getRedirectState, []);
    const { authRedirect, sandboxAuthRedirect } = (0, _hooks_1.useSalesforceAuth)(sonar_ts_constants_1.FULL_ROUTES.CONFIRM_LINK, redirectState);
    return (react_1.default.createElement(general_1.GatewayContent, null,
        react_1.default.createElement(general_1.GatewayCard, { className: 'connect-org-view', pre: react_1.default.createElement(general_2.OnboardingSteps, { currentStep: general_2.OnboardingStep.Connect }), title: sonar_ts_constants_1.ONBOARDING_CONNECT_COPY.CONNECT_YOUR_ORGANIZATION },
            react_1.default.createElement("p", null, sonar_ts_constants_1.ONBOARDING_CONNECT_COPY.CONNECT_DIALOG),
            react_1.default.createElement("br", null),
            react_1.default.createElement("br", null),
            react_1.default.createElement(antd_1.Row, { justify: 'center' },
                react_1.default.createElement(antd_1.Space, { size: 4 },
                    react_1.default.createElement(antd_1.Button, { id: 'connect-salesforce-sandbox-button', onClick: sandboxAuthRedirect, size: 'large' },
                        react_1.default.createElement(icons_1.LinkOutlined, null),
                        " ",
                        sonar_ts_constants_1.ONBOARDING_CONNECT_COPY.CONNECT_NEW_SANDBOX),
                    react_1.default.createElement(antd_1.Button, { id: 'connect-salesforce-button', onClick: authRedirect, size: 'large', type: 'primary' },
                        react_1.default.createElement(icons_1.LinkOutlined, null),
                        " ",
                        sonar_ts_constants_1.ONBOARDING_CONNECT_COPY.CONNECT_NEW_SALESFORCE))))));
};
exports.ConnectOrganizationView = ConnectOrganizationView;
