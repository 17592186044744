"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceOrganizationSyncConfigurationButton = void 0;
require("./SalesforceOrganizationSyncConfigurationButton.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const notifications_1 = require("@ui/notifications");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const icons_1 = require("@ant-design/icons");
const modals_1 = require("@ui/modals");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getSonarTaskConfigSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.organizations.byId.tasks.types.configs.get);
const patchSonarTaskConfigSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.organizations.byId.tasks.types.configs.patch);
const SalesforceOrganizationSyncConfigurationButton = (props) => {
    const { organizationId, organizationName, shardName } = props;
    const [isModalOpen, setIsModalOpen] = (0, react_1.useState)(false);
    const [isTaskEnabled, setIsTaskEnabled] = (0, react_1.useState)(true);
    const [taskFrequency, setTaskFrequency] = (0, react_1.useState)(0);
    const sonarTaskConfig = (0, _hooks_1.useSonarSelector)(getSonarTaskConfigSelectors.data);
    const sonarTaskConfigRequested = (0, _hooks_1.useSonarSelector)(getSonarTaskConfigSelectors.isRequested);
    const sonarTaskConfigPatching = (0, _hooks_1.useSonarSelector)(patchSonarTaskConfigSelectors.isRequested);
    const getSonarTaskConfig = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSonarTaskConfigsByOrganizationIdAndTaskType);
    const patchSonarTaskConfig = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreSonarTaskConfigsByOrganizationIdAndTaskType);
    const handleOpenConfigureImports = (0, react_1.useCallback)(() => {
        setIsModalOpen(true);
    }, [setIsModalOpen]);
    const handleConfigureImportsOk = (0, react_1.useCallback)(() => {
        if (organizationId) {
            const request = {
                enabled: isTaskEnabled,
                minDaysInterval: taskFrequency,
                organizationId,
                taskType: 'sfdc-import'
            };
            patchSonarTaskConfig(request);
        }
    }, [organizationId, isTaskEnabled, taskFrequency, patchSonarTaskConfig]);
    const handleConfigureImportsCancel = (0, react_1.useCallback)(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);
    const handleFrequencyChanged = (0, react_1.useCallback)((value) => {
        setTaskFrequency(value);
    }, [setTaskFrequency]);
    const handleEnabledChanged = (0, react_1.useCallback)((enabled) => {
        setIsTaskEnabled(enabled);
    }, [setIsTaskEnabled]);
    (0, react_1.useEffect)(() => {
        if (isModalOpen && organizationId) {
            getSonarTaskConfig({
                organizationId,
                shardName,
                taskType: 'sfdc-import'
            });
        }
    }, [getSonarTaskConfig, isModalOpen, organizationId, shardName]);
    (0, react_1.useEffect)(() => {
        if (sonarTaskConfig) {
            setIsTaskEnabled(sonarTaskConfig.enabled);
            setTaskFrequency(sonarTaskConfig.minDaysInterval);
        }
    }, [sonarTaskConfig]);
    (0, react_1.useEffect)(() => {
        if (!sonarTaskConfigPatching) {
            setIsModalOpen(false);
        }
    }, [sonarTaskConfigPatching]);
    if (!organizationId || !organizationName) {
        return null;
    }
    return (react_1.default.createElement("span", null,
        react_1.default.createElement(antd_1.Button, { icon: react_1.default.createElement(icons_1.SettingOutlined, null), onClick: handleOpenConfigureImports }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.CONFIGURE_IMPORTS),
        react_1.default.createElement(modals_1.SonarModal, { className: 'import-frequency-modal', okButtonProps: { disabled: sonarTaskConfigRequested || sonarTaskConfigPatching }, onCancel: handleConfigureImportsCancel, onOk: handleConfigureImportsOk, open: isModalOpen, title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.CONFIGURE_IMPORTS_FOR(organizationName) },
            react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: sonarTaskConfigRequested },
                react_1.default.createElement(antd_1.Form, { colon: false },
                    react_1.default.createElement(antd_1.Form.Item, { label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.FREQUENCY },
                        react_1.default.createElement(antd_1.Select, { defaultValue: 0, dropdownStyle: { zIndex: 2000 }, onChange: handleFrequencyChanged, popupClassName: 'modal-dropdown-list', value: taskFrequency },
                            react_1.default.createElement(antd_1.Select.Option, { value: 0 }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.FREQUENCY_DAILY),
                            react_1.default.createElement(antd_1.Select.Option, { value: 7 }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.FREQUENCY_WEEKLY))),
                    react_1.default.createElement(antd_1.Form.Item, { label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.ENABLED },
                        react_1.default.createElement(antd_1.Switch, { checked: isTaskEnabled, onChange: handleEnabledChanged }))))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.PATCH_TASK_CONFIG_ERRORED, failed: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.PATCH_TASK_CONFIG_FAILED, requested: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.PATCH_TASK_CONFIG_REQUESTED, stateSelector: patchSonarTaskConfigSelectors.state })));
};
exports.SalesforceOrganizationSyncConfigurationButton = SalesforceOrganizationSyncConfigurationButton;
