"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailAlertRecipientField = void 0;
const records_1 = require("@ui/records");
const text_1 = require("@ui/text");
const _hooks_1 = require("@hooks");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const visibleRecipientTypes = {
    [sonar_ts_types_1.EmailAlertRecipientType.AccountTeam]: true,
    [sonar_ts_types_1.EmailAlertRecipientType.CaseTeam]: true,
    [sonar_ts_types_1.EmailAlertRecipientType.Role]: true,
    [sonar_ts_types_1.EmailAlertRecipientType.RoleSubordinates]: true,
    [sonar_ts_types_1.EmailAlertRecipientType.User]: true
};
const showRecipient = (type) => visibleRecipientTypes[type];
const EmailAlertRecipientField = ({ record, parentIndex }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const handleClick = (0, react_1.useCallback)(() => {
        const { displayName, sonarObjectId } = record;
        openPeriscope({
            id: sonarObjectId,
            name: displayName,
            openedFromIndex: parentIndex,
            type: sonar_ts_types_1.SonarObjectType.SalesforceField
        });
    }, [record, openPeriscope, parentIndex]);
    const handleParentClick = (0, react_1.useCallback)(() => {
        const { parentDisplayName, parentSonarObjectId } = record;
        openPeriscope({
            id: parentSonarObjectId,
            name: parentDisplayName,
            openLeft: true,
            openedFromIndex: parentIndex,
            type: sonar_ts_types_1.SonarObjectType.SalesforceEntity
        });
    }, [record, openPeriscope, parentIndex]);
    const name = react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(text_1.SonarText, { weight: 'semibold' }, record.displayName),
        record.recipient && showRecipient(record.recipientType) && `: ${record.recipient}`);
    return (react_1.default.createElement(records_1.SonarRecord, { key: record.sonarObjectId, name: name, onClick: handleClick, onClickParent: handleParentClick, parentName: record.parentDisplayName, sonarObjectId: record.sonarObjectId, sonarObjectType: record.sonarObjectType }));
};
exports.EmailAlertRecipientField = EmailAlertRecipientField;
