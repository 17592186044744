"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowRuleSearchableSelectionTable = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const tables_1 = require("@ui/tables");
const WorkflowRuleRow_1 = require("./WorkflowRuleRow");
const WorkflowRuleSearch_1 = require("./WorkflowRuleSearch");
const __1 = require("../");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const WorkflowRuleSearchableSelectionTable = ({ parentIndex, resourceIdsUri, uri }) => {
    const scrollableListRef = (0, react_1.useRef)(null);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        defaultParams: Object.assign(Object.assign({}, __1.paginationDefaultQueryParams), { hideInactive: true }),
        fallbackParams: __1.paginationFallbackQueryParams,
        uri
    });
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        queryParams,
        resourceIdsUri: resourceIdsUri,
        results: resource === null || resource === void 0 ? void 0 : resource.results,
        selectionType: sonar_ts_types_1.SonarObjectType.SalesforceWorkflowRule
    });
    const renderWorkflowRule = (0, react_1.useCallback)((rule) => react_1.default.createElement(WorkflowRuleRow_1.WorkflowRuleRow, { key: rule.sonarObjectId, parentIndex: parentIndex, rule: rule }), [parentIndex]);
    const results = resource === null || resource === void 0 ? void 0 : resource.results;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(WorkflowRuleSearch_1.WorkflowRuleSearch, { onChange: updateParams, queryParams: queryParams }),
        react_1.default.createElement(tables_1.SelectionTable, { dataSource: results, forceShowSelection: resourceIdsUri ? undefined : false, loading: loading, ref: scrollableListRef, renderRow: renderWorkflowRule, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.WORKFLOW_RULES }),
        react_1.default.createElement(__1.PeriscopePagination, { loading: loading, onChange: updateParams, queryParams: queryParams, scrollRef: scrollableListRef, totalCount: Number(resource === null || resource === void 0 ? void 0 : resource.totalCount) })));
};
exports.WorkflowRuleSearchableSelectionTable = WorkflowRuleSearchableSelectionTable;
