"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrimaryFilter = void 0;
require("./Primary.scss");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const FilterComponent_1 = require("./FilterComponent");
const antd_1 = require("antd");
const lib_1 = require("../@arch/lib");
const filters_1 = require("@periscopes/general/filters");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const PrimaryFilter = (props) => {
    var _a, _b;
    const { format, id, label, metadata, onChange, params, placeholder, value } = props;
    const searchRef = (0, react_1.useRef)(null);
    const internalValue = (_b = (_a = params[metadata !== null && metadata !== void 0 ? metadata : '']) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
    const hasInternal = Boolean(internalValue);
    const [toggledInternal, setToggledInternal] = (0, react_1.useState)(hasInternal && !props.displayAsTag);
    const displayInternal = hasInternal || toggledInternal;
    const displayValue = displayInternal ? internalValue : value;
    (0, react_1.useEffect)(() => {
        if (searchRef.current) {
            searchRef.current.setText(displayValue !== null && displayValue !== void 0 ? displayValue : '');
        }
    }, [displayValue]);
    const baseLabel = (0, sonar_ts_lib_1.capitalize)(label !== null && label !== void 0 ? label : '');
    const adjustedLabel = displayInternal ? baseLabel.replace(sonar_ts_constants_1.COPY_SPECIFIC.NAME, sonar_ts_constants_1.COPY_SPECIFIC.API_NAME) : baseLabel;
    const handleToggle = (0, react_1.useCallback)((change) => {
        var _a;
        onChange({
            Name: {
                format,
                value: change.useInternal ? displayValue : ''
            },
            [id]: {
                format,
                value: change.useInternal ? '' : displayValue
            }
        });
        setToggledInternal((_a = change.useInternal) !== null && _a !== void 0 ? _a : false);
    }, [displayValue, format, id, onChange]);
    return (react_1.default.createElement(FilterComponent_1.FilterComponent, Object.assign({ className: 'primary-filter' }, props, { label: adjustedLabel, value: displayValue }),
        react_1.default.createElement(antd_1.Flex, { align: 'center', gap: 8 },
            react_1.default.createElement(general_1.SearchInput, { className: 'filter', defaultValue: displayValue, id: (0, lib_1.getFilterId)(id), onChange: (query = '') => onChange({
                    [displayInternal ? 'Name' : id]: {
                        format,
                        value: query
                    }
                }), placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(adjustedLabel), ref: searchRef }),
            react_1.default.createElement(filters_1.ApiCodeToggle, { checked: toggledInternal, onChange: handleToggle }))));
};
exports.PrimaryFilter = PrimaryFilter;
