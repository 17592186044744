"use strict";
/* eslint-disable no-bitwise */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplianceCategoryFormSelector = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const lodash_1 = require("lodash");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const filters_1 = require("@periscopes/general/filters");
const react_1 = __importStar(require("react"));
const enumOptions = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.ComplianceCategory);
function getOptions(includeAny) {
    return enumOptions
        .filter((category) => (includeAny ? true : category !== sonar_core_1.ComplianceCategory.Any))
        .map((category) => ({
        label: sonar_core_1.ComplianceCategory[category],
        value: category
    }));
}
function getValues(value) {
    if (value === undefined || value === null) {
        return [];
    }
    const results = [];
    for (const option of enumOptions.filter((category) => category !== sonar_core_1.ComplianceCategory.Any)) {
        if (value & option) {
            results.push(option);
        }
    }
    return results.length ? results : [0];
}
const ComplianceCategoryFormSelector = (_a) => {
    var { includeAny = false, mode, onChange = lodash_1.noop, placeholder, value } = _a, props = __rest(_a, ["includeAny", "mode", "onChange", "placeholder", "value"]);
    const options = (0, react_1.useMemo)(() => getOptions(includeAny), [includeAny]);
    const handleChange = (0, react_1.useCallback)((change) => {
        if (change[change.length - 1] === sonar_core_1.ComplianceCategory.None) {
            onChange(sonar_core_1.ComplianceCategory.None);
        }
        else {
            const multipleValue = change.length ? change.reduce((prev, curr) => curr + prev, 0) : undefined;
            onChange(mode === 'multiple' ? multipleValue : change);
        }
    }, [mode, onChange]);
    return (react_1.default.createElement(filters_1.OptionFilter, Object.assign({}, props, { mode: mode, onChange: handleChange, options: options, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : sonar_ts_constants_1.COPY_GENERIC.SELECT(sonar_ts_constants_1.COPY_SPECIFIC.COMPLIANCE_CATEGORIES), value: getValues(value) })));
};
exports.ComplianceCategoryFormSelector = ComplianceCategoryFormSelector;
