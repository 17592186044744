"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeSidebarStatusSelector = void 0;
const selectors_1 = require("../../@arch/selectors");
const StatusSelector_1 = require("../../StatusSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const InitiativeSidebarStatusSelector = ({ initiativeId, status }) => {
    const jiraEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.JiraIntegration);
    const jiraIssueData = (0, _hooks_1.useSonarSelector)(selectors_1.getInitiativeJiraIssuesSelector.data);
    const jiraStatusSync = (0, react_1.useMemo)(() => jiraEnabled && (jiraIssueData === null || jiraIssueData === void 0 ? void 0 : jiraIssueData.some((issue) => issue.isSynced)), [jiraEnabled, jiraIssueData]);
    const getInitiative = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreInitiativesById);
    const refreshInitiative = (0, react_1.useCallback)(() => {
        if (jiraStatusSync) {
            getInitiative({
                id: initiativeId
            });
        }
    }, [getInitiative, initiativeId, jiraStatusSync]);
    (0, _hooks_1.useInterval)(refreshInitiative, 10000);
    const updateInitiative = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiativesById);
    const handleChangeStatus = (0, react_1.useCallback)((statusId) => {
        updateInitiative({
            initiativeId: initiativeId,
            patchInitiativeDescription: {
                initiativeStatusId: statusId
            }
        });
    }, [initiativeId, updateInitiative]);
    return (react_1.default.createElement(StatusSelector_1.InitiativeStatusSelector, { disabled: jiraStatusSync, id: 'initiative-status-select', onChange: handleChangeStatus, value: status }));
};
exports.InitiativeSidebarStatusSelector = InitiativeSidebarStatusSelector;
