"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginView = void 0;
const general_1 = require("@ui/general");
const selectors_1 = require("@lib/selectors");
const sonar_http_1 = require("@sonar-software/sonar-http");
const general_2 = require("../general");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const OIDCProviders_1 = require("./OIDCProviders");
const sonar_security_1 = require("@sonar-software/sonar-security");
const react_router_dom_1 = require("react-router-dom");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const form_items_1 = require("@ui/form-items");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const loginSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.authentication.login.post);
const LoginView = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { isErrored, isFailed, isReceived, isRequested } = (0, _hooks_1.useAsyncState)(loginSelectors);
    const login = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestPostSecurityAuthorizationLogin);
    const resetLogin = (0, _hooks_1.useAction)(sonar_security_1.securityActions.resetPostSecurityAuthorizationLogin);
    const loading = isReceived || isRequested;
    const isInvalid = isErrored || isFailed;
    const handleOnFinish = (0, react_1.useCallback)((values) => {
        const { email, password } = values;
        login({
            email,
            password
        });
    }, [login]);
    const handleOnValuesChange = (0, react_1.useCallback)(() => {
        if (isInvalid) {
            resetLogin();
        }
    }, [isInvalid, resetLogin]);
    const handleOnForgotPasswordClick = (0, react_1.useCallback)(() => {
        navigate(sonar_ts_constants_1.FULL_ROUTES.FORGOT_PASSWORD);
    }, [navigate]);
    return (react_1.default.createElement(general_2.GatewayCard, { id: 'login', title: sonar_ts_constants_1.COPY_SPECIFIC.LOGIN },
        react_1.default.createElement(antd_1.Form, { onFinish: handleOnFinish, onValuesChange: handleOnValuesChange },
            react_1.default.createElement(general_1.AsyncStateAlert, { byStatusCode: [
                    {
                        options: {
                            description: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_LOGIN_LOCKED,
                            type: 'error'
                        },
                        statusCode: sonar_http_1.ErrorOrFailStatusCode.Locked
                    }
                ], errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_LOGIN, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_LOGIN, stateSelector: loginSelectors.state }),
            react_1.default.createElement(form_items_1.Email, { autoComplete: sonar_ts_constants_1.AUTOCOMPLETE.USERNAME, autoFocus: true, disabled: loading, id: 'login-email', placeholder: sonar_ts_constants_1.COPY_SPECIFIC.EMAIL, size: 'large', skipValidation: true }),
            react_1.default.createElement(form_items_1.Password, { autoComplete: sonar_ts_constants_1.AUTOCOMPLETE.CURRENT_PASSWORD, disabled: loading, id: 'login-password', size: 'large', skipValidation: true }),
            react_1.default.createElement(antd_1.Row, { justify: 'space-between' },
                react_1.default.createElement(antd_1.Button, { disabled: loading, id: 'forgot-password-button', onClick: handleOnForgotPasswordClick, size: 'large' }, sonar_ts_constants_1.COPY_SPECIFIC.FORGOT_PASSWORD),
                react_1.default.createElement(antd_1.Button, { htmlType: 'submit', id: 'login-submit-button', loading: loading, size: 'large', type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.LOGIN))),
        (0, sonar_ts_lib_1.isSubdomain)(window.location.href) && react_1.default.createElement(OIDCProviders_1.OidcProviders, null)));
};
exports.LoginView = LoginView;
