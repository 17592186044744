"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollShadow = void 0;
require("./ScrollShadow.scss");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const ScrollShadow = ({ children, scroller, shadow }) => {
    const containerRef = (0, react_1.useRef)(null);
    (0, _hooks_1.useOnMount)(() => {
        var _a, _b, _c, _d;
        (_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(scroller)) === null || _b === void 0 ? void 0 : _b.addEventListener('scroll', (e) => {
            var _a;
            const shadowTarget = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(shadow);
            if (!shadowTarget) {
                return;
            }
            shadowTarget.classList.toggle('drop-shadow', e.target.scrollTop > 0);
        });
        (_d = (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.querySelector(shadow)) === null || _d === void 0 ? void 0 : _d.classList.add('scroll-shadow');
    });
    if (!children) {
        return null;
    }
    return react_1.default.cloneElement(children, { ref: containerRef });
};
exports.ScrollShadow = ScrollShadow;
