"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.IssuesList = void 0;
require("./IssuesList.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const Issue_1 = require("./Issue");
const NoData_1 = require("@ui/NoData");
const lists_1 = require("@ui/lists");
const antd_1 = require("antd");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const react_redux_1 = require("react-redux");
const selectors_1 = require("@lib/selectors");
const sonar_issues_1 = require("@sonar-software/sonar-issues");
const KeyPagination_1 = require("@ui/KeyPagination");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function getLimitedIssues(paginatedIssues, limit) {
    var _a;
    if (!paginatedIssues || !limit) {
        return paginatedIssues;
    }
    return Object.assign(Object.assign({}, paginatedIssues), { results: (0, sonar_ts_lib_1.take)(paginatedIssues.results, limit), totalCount: ((_a = paginatedIssues.totalCount) !== null && _a !== void 0 ? _a : 0) > limit ? paginatedIssues : paginatedIssues.totalCount });
}
const visiblityTypeMap = {
    [sonar_issues_1.IssueVisibilityType.Archived]: {
        action: sonar_issues_1.issuesActions.requestGetIssuesArchived,
        empty: react_1.default.createElement(NoData_1.NoData, { description: sonar_ts_constants_1.COPY_SPECIFIC.NO_ISSUES_ARCHIVED }),
        selectors: (0, selectors_1.createAsyncSelectors)((state) => state.issues.archived.get)
    },
    [sonar_issues_1.IssueVisibilityType.Snoozed]: {
        action: sonar_issues_1.issuesActions.requestGetIssuesSnoozed,
        empty: react_1.default.createElement(NoData_1.NoData, { description: sonar_ts_constants_1.COPY_SPECIFIC.NO_ISSUES_SNOOZED }),
        selectors: (0, selectors_1.createAsyncSelectors)((state) => state.issues.snoozed.get)
    },
    [sonar_issues_1.IssueVisibilityType.Visible]: {
        action: sonar_issues_1.issuesActions.requestGetIssuesVisible,
        empty: react_1.default.createElement(antd_1.Result, { status: 'success', subTitle: sonar_ts_constants_1.COPY_SPECIFIC.NO_ISSUES_TO_REPORT, title: sonar_ts_constants_1.COPY_SPECIFIC.EVERYTHING_LOOKS_GREAT }),
        selectors: (0, selectors_1.createAsyncSelectors)((state) => state.issues.visible.get)
    }
};
const IssuesList = ({ hideActions, limit, visibility }) => {
    var _a;
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: KeyPagination_1.keyPaginationDefaultQueryParams,
        fallbackParams: KeyPagination_1.keyPaginationFallbackQueryParams
    });
    const typeMap = (_a = visiblityTypeMap[visibility]) !== null && _a !== void 0 ? _a : visiblityTypeMap[sonar_issues_1.IssueVisibilityType.Visible];
    const loadIssues = (0, _hooks_1.useAction)(typeMap.action);
    const isLoading = (0, react_redux_1.useSelector)(typeMap.selectors.isRequested);
    const paginatedIssues = (0, react_redux_1.useSelector)(typeMap.selectors.data);
    const limitedIssues = (0, react_1.useMemo)(() => getLimitedIssues(paginatedIssues, limit), [limit, paginatedIssues]);
    (0, react_1.useEffect)(() => {
        loadIssues(queryParams);
    }, [loadIssues, queryParams]);
    return (react_1.default.createElement(lists_1.PaginatedList, { empty: typeMap.empty, listClassName: 'issues-list', loading: isLoading, onChange: updateParams, queryParams: queryParams, renderListItem: (item) => react_1.default.createElement(Issue_1.Issue, { hideActions: hideActions, issue: item, key: item.id, visibility: visibility }), resource: limitedIssues }));
};
exports.IssuesList = IssuesList;
