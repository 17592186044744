"use strict";
/* eslint-disable @typescript-eslint/no-unused-vars */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorForm = void 0;
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const MonitorMenu_1 = require("./MonitorMenu");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
exports.MonitorForm = (0, react_1.forwardRef)(({ editing, form, hideApplications = false, menuOptions, monitor, onChange, showRecommendedActionTemplates = false }, ref) => {
    var _a;
    const patchMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreDataSecurityMonitorsById);
    const postMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreDataSecurityMonitors);
    const deleteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreDataSecurityMonitorsById);
    const postTemplate = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreDataSecurityActionTemplate);
    const handleValidate = (0, react_1.useCallback)(() => {
        var _a, _b;
        form.validate();
        const currentMonitor = form.getFieldsValue(true);
        if (!currentMonitor || !((_a = currentMonitor.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId)) {
            return false;
        }
        const appRequired = (_b = MonitorMenu_1.monitorMenuConfigs
            .find((config) => { var _a; return config.type === ((_a = currentMonitor.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId); })) === null || _b === void 0 ? void 0 : _b.applicationRequired;
        if (!currentMonitor.templateId) {
            return form.isValid && appRequired ?
                Boolean(currentMonitor.name) && Boolean(currentMonitor.applicationId) :
                Boolean(currentMonitor.name);
        }
        return form.isValid && appRequired ?
            currentMonitor.applicationIds && currentMonitor.applicationIds.length > 0 :
            true;
    }, [form]);
    const saveTemplate = (0, react_1.useCallback)((overwrite) => {
        const { applicationIds, monitorConfig, stakeholderIds, templateId } = form.getFieldsValue(true);
        postTemplate({
            applicationIds,
            monitorConfig: monitorConfig,
            monitorTypeId: monitorConfig.monitorTypeId,
            overwrite: overwrite,
            stakeholderIds,
            templateId
        });
    }, [form, postTemplate]);
    const saveMonitor = (0, react_1.useCallback)(() => {
        const { applicationId, monitorConfig, name, notificationSchedule, stakeholderIds } = form.getFieldsValue(true);
        if (monitor === null || monitor === void 0 ? void 0 : monitor.id) {
            const updateMonitor = {
                id: monitor.id,
                monitorConfig,
                name,
                notificationSchedule,
                stakeholderIds
            };
            patchMonitor(updateMonitor);
        }
        else if ((monitorConfig === null || monitorConfig === void 0 ? void 0 : monitorConfig.monitorTypeId) && name) {
            postMonitor({
                applicationId,
                monitorConfig: monitorConfig,
                monitorTypeId: monitorConfig.monitorTypeId,
                name,
                notificationSchedule,
                stakeholderIds
            });
        }
        form.reset();
    }, [form, monitor, patchMonitor, postMonitor]);
    const handleDelete = (0, react_1.useCallback)(() => {
        if (monitor === null || monitor === void 0 ? void 0 : monitor.id) {
            deleteMonitor({ monitorId: monitor === null || monitor === void 0 ? void 0 : monitor.id });
        }
    }, [deleteMonitor, monitor]);
    const handleOnChange = (0, react_1.useCallback)((newMonitor) => {
        form.setFieldsValue(newMonitor);
        if (onChange) {
            onChange(newMonitor);
        }
        handleValidate();
    }, [form, handleValidate, onChange]);
    const handleValuesChange = (0, react_1.useCallback)(() => {
        handleValidate();
        if (onChange) {
            onChange();
        }
    }, [handleValidate, onChange]);
    (0, react_1.useImperativeHandle)(ref, () => ({
        delete: () => handleDelete(),
        isValid: () => handleValidate(),
        save: () => saveMonitor(),
        saveTemplate: (overwrite) => saveTemplate(overwrite)
    }));
    return (react_1.default.createElement(antd_1.Form, { form: form, layout: 'vertical', name: `monitorForm-${(_a = monitor === null || monitor === void 0 ? void 0 : monitor.id) !== null && _a !== void 0 ? _a : 'new'}`, onValuesChange: handleValuesChange },
        react_1.default.createElement(MonitorMenu_1.MonitorMenu, { editing: editing, hideApplications: hideApplications, menuOptions: menuOptions, monitor: monitor, onChange: handleOnChange, showRecommendedActionTemplates: showRecommendedActionTemplates })));
});
