"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessChangesTable = void 0;
require("./AccessChangesTable.scss");
const AccessChangesExportButton_1 = require("./AccessChangesExportButton");
const selectors_1 = require("@lib/selectors");
const AccessChangesTableColumns_1 = require("./AccessChangesTableColumns");
const general_1 = require("@ui/general");
const react_router_1 = require("react-router");
const AccessChangeTypeTag_1 = require("./AccessChangeTypeTag");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getRowKey = (result) => result.id;
const dataSecurityAlertsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.alerts.get);
const changeTypeOptions = Object.values(AccessChangeTypeTag_1.AccessChangeType)
    .filter((value) => Number.isInteger(value))
    .map((changeType) => ({
    label: react_1.default.createElement(AccessChangeTypeTag_1.AccessChangeTypeTag, { changeType: changeType }),
    value: changeType
}));
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    defaultOperator: sonar_ts_types_1.DynamicQueryOperator.Equals,
    filterParams: {
        appGroup: {
            name: 'stakeholderGroupId',
            value: (value) => (value === null ? undefined : value)
        },
        applicationId: true,
        changeType: { name: 'monitorType' },
        showHidden: {
            name: 'hidden',
            value: (value) => (value ? undefined : false)
        },
        start: {
            name: 'detectedDate',
            rangeEnd: 'end'
        }
    }
});
const AccessChangesTable = ({ applicationId, viewParams }) => {
    const navigate = (0, react_router_1.useNavigate)();
    const allowOverview = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataSecurityDashboard);
    const getDataSecurityAlerts = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityAlerts);
    const hideDataSecurityAlert = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreDataSecurityAlert);
    const [firstLoad, setFirstLoad] = (0, react_1.useState)(true);
    const [dateChanged, setDateChanged] = (0, react_1.useState)(false);
    const previousApplicationId = (0, _hooks_1.usePrevious)(applicationId);
    const applicationIdChanged = applicationId !== previousApplicationId;
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(dataSecurityAlertsSelectors);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            applicationId,
            count: 10,
            showHidden: false,
            sortBy: 'detectedDate',
            sortDirection: 1,
            start: (0, sonar_ts_lib_1.getPastDate)(7)
        },
        onUpdate: (_newParams, _prev, combined) => {
            getDataSecurityAlerts(convertToDql(combined));
        }
    });
    (0, _hooks_1.useOnMount)(() => {
        setFirstLoad(false);
        getDataSecurityAlerts(convertToDql(queryParams));
    });
    (0, react_1.useEffect)(() => {
        if (!applicationId && viewParams.appGroup !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.appGroup)) {
            updateParams({ appGroup: viewParams.appGroup });
        }
    }, [applicationId, queryParams === null || queryParams === void 0 ? void 0 : queryParams.appGroup, updateParams, viewParams.appGroup]);
    (0, react_1.useEffect)(() => {
        if (viewParams.dateRange.end !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.end) || viewParams.dateRange.start !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.start)) {
            setDateChanged(true);
            const { start, end } = viewParams.dateRange;
            updateParams({
                end,
                start
            });
        }
        else if (dateChanged) {
            setDateChanged(false);
        }
    }, [dateChanged, viewParams, queryParams === null || queryParams === void 0 ? void 0 : queryParams.end, queryParams === null || queryParams === void 0 ? void 0 : queryParams.start, updateParams, queryParams === null || queryParams === void 0 ? void 0 : queryParams.appGroup]);
    const handleShowHidden = (0, react_1.useCallback)((e) => {
        updateParams({ showHidden: e.target.checked });
    }, [updateParams]);
    const handleTypeSelect = (0, react_1.useCallback)((changeType) => {
        updateParams({ changeType });
    }, [updateParams]);
    const setHidden = (0, react_1.useCallback)((monitorAlertId, hide) => {
        const pageEmpty = queryParams.key && hide && (data === null || data === void 0 ? void 0 : data.results.length) === 1;
        const onFirstPage = queryParams.key && (data === null || data === void 0 ? void 0 : data.nextKey) && !(data === null || data === void 0 ? void 0 : data.prevKey);
        if (pageEmpty || onFirstPage) {
            updateParams(Object.assign(Object.assign({}, queryParams), { key: '', paginationDirection: sonar_ts_types_1.PaginationDirection.Forward }));
        }
        hideDataSecurityAlert({
            hide,
            monitorAlertId
        });
    }, [data === null || data === void 0 ? void 0 : data.nextKey, data === null || data === void 0 ? void 0 : data.prevKey, data === null || data === void 0 ? void 0 : data.results.length, hideDataSecurityAlert, queryParams, updateParams]);
    const columns = (0, react_1.useMemo)(() => (0, AccessChangesTableColumns_1.getColumns)(!allowOverview || Boolean(applicationId), isReadOnly, navigate, setHidden), [allowOverview, applicationId, isReadOnly, navigate, setHidden]);
    return (react_1.default.createElement(general_1.SonarCard, { className: 'access-changes-table', extra: react_1.default.createElement(AccessChangesExportButton_1.AccessChangesExportButton, { queryParams: convertToDql(queryParams) }), loading: firstLoad || dateChanged || applicationIdChanged, noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.ACCESS_CHANGES_DETECTED, titleClassName: 'h2', useSpinnerLoading: true },
        react_1.default.createElement("div", { className: 'table-filters' },
            react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'change-type-select', onChange: handleTypeSelect, options: changeTypeOptions, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.TYPE }),
            react_1.default.createElement(antd_1.Checkbox, { className: 'show-hidden', onChange: handleShowHidden }, sonar_ts_constants_1.COPY_GENERIC.SHOW_HIDDEN)),
        react_1.default.createElement("div", { className: 'table-container' },
            react_1.default.createElement(PaginatedTable_1.PaginatedTable, { autohidePagination: true, columns: columns, defaultPageSize: 10, id: 'access-changes-table', loading: isRequested, onChange: updateParams, resource: data, rowClassName: (record) => (record.hidden ? 'hidden' : ''), rowKey: getRowKey, showSizeChanger: false, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection }))));
};
exports.AccessChangesTable = AccessChangesTable;
