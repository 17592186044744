"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewAreaSidebar = void 0;
require("./ViewAreaSidebar.scss");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const menu_1 = require("@ui/menu");
const react_router_dom_1 = require("react-router-dom");
const { Sider } = antd_1.Layout;
const ViewAreaSidebar = ({ id, items }) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { pathname } = (0, react_router_dom_1.useLocation)();
    const [selected, setSelected] = (0, react_1.useState)([pathname]);
    (0, react_1.useEffect)(() => {
        setSelected([pathname]);
    }, [pathname]);
    return (react_1.default.createElement("div", { className: 'sidebar-container' },
        react_1.default.createElement(Sider, { className: 'view-area-sidebar', id: id, theme: 'light' },
            react_1.default.createElement(menu_1.SonarMenu, { items: items, onClick: (info) => navigate(info.key), selectedKeys: selected }))));
};
exports.ViewAreaSidebar = ViewAreaSidebar;
