"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StakeholdersTable = void 0;
require("./StakeholdersTable.scss");
const DeleteStakeholderNotifications_1 = require("./DeleteStakeholderNotifications");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const utility_1 = require("@lib/utility");
const tables_1 = require("@ui/tables");
const sonar_core_1 = require("@sonar-software/sonar-core");
const StakeholdersTableColumns_1 = require("./StakeholdersTableColumns");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const columns = [StakeholdersTableColumns_1.nameColumn, StakeholdersTableColumns_1.emailColumn, StakeholdersTableColumns_1.typeColumn, StakeholdersTableColumns_1.countColumn];
const getRowKey = (stakeholder) => stakeholder.stakeholderId;
const StakeholdersTable = () => {
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(_hooks_1.useStakeholders.selectors);
    const loadStakeholders = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreStakeholders);
    const deleteStakeholder = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreStakeholders);
    (0, _hooks_1.useOnMount)(() => {
        loadStakeholders();
    });
    const resource = (0, react_1.useMemo)(() => {
        if (!data) {
            return undefined;
        }
        const stakeholders = data.filter((record) => !record.userId);
        return {
            results: stakeholders,
            totalCount: stakeholders === null || stakeholders === void 0 ? void 0 : stakeholders.length
        };
    }, [data]);
    const handleDelete = (0, react_1.useCallback)((stakeholder) => {
        deleteStakeholder({
            stakeholderIds: [stakeholder.stakeholderId]
        });
    }, [deleteStakeholder]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(tables_1.PaginatedTable, { columns: columns, deletePrompt: sonar_ts_constants_1.MESSAGES_COPY.ARE_YOU_SURE_STAKEHOLDER, headerSize: 'small', id: 'stakeholders-table', loading: isRequested, onChange: utility_1.noop, onDelete: handleDelete, resource: resource, rowKey: getRowKey, scroll: { x: 'max-content' }, sticky: true }),
        react_1.default.createElement(DeleteStakeholderNotifications_1.DeleteStakeholderNotifications, null)));
};
exports.StakeholdersTable = StakeholdersTable;
