"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataDictionary = void 0;
const DataDictionaryTable_1 = require("./DataDictionaryTable");
const selectors_1 = require("./@arch/selectors");
const sonar_http_1 = require("@sonar-software/sonar-http");
const KeyPagination_1 = require("@ui/KeyPagination");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const QuickAccess_1 = require("./search/QuickAccess");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_core_1 = require("@sonar-software/sonar-core");
const search_1 = require("./search");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_if_1 = require("react-if");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const react_router_dom_1 = require("react-router-dom");
const defaultParams = Object.assign(Object.assign({}, KeyPagination_1.keyPaginationDefaultQueryParams), { applicationId: undefined, columns: '', complianceCategory: undefined, displayName: undefined, filters: sonar_ts_constants_1.DEFAULT_FILTER, lastViewId: undefined, ordering: '', sonarObjectId: undefined, sonarObjectType: undefined, tagId: undefined, viewId: undefined, viewName: undefined });
function compareParams(newParams, prev) {
    return (param) => newParams[param] !== undefined && newParams[param] !== prev[param];
}
function paramChanged(newParams, prev, param) {
    return compareParams(newParams, prev)(param);
}
const hasEmptyParams = (newParams, prev) => Object.keys(newParams).length === 0 && Object.keys(prev).length !== 0;
const hasTargetChanged = (newParams, prev) => {
    const changed = compareParams(newParams, prev);
    return changed('sonarObjectId') ||
        changed('sonarObjectType') ||
        changed('applicationId') ||
        changed('tagId') ||
        changed('complianceCategory');
};
const hasFilterChanged = (newParams, prev) => {
    const changed = compareParams(newParams, prev);
    return changed('ordering') || changed('filters');
};
const hasPageSizeChanged = (newParams, prev) => paramChanged(newParams, prev, 'count');
const hasPageChanged = (newParams, prev) => paramChanged(newParams, prev, 'key') || (!newParams.key && Boolean(prev.key));
const hasViewChanged = (newParams, prev) => (Boolean(newParams.viewId) && (!prev.viewId || newParams.viewId !== prev.viewId)) ||
    (Boolean(prev.lastViewId) && !newParams.viewId && !newParams.lastViewId);
const transformParams = (newParams, prev, combined) => {
    var _a;
    if (hasEmptyParams(newParams, prev)) {
        return defaultParams;
    }
    else if (hasTargetChanged(newParams, prev)) {
        const { applicationId, complianceCategory, displayName, sonarObjectId, sonarObjectType, tagId, viewId, viewName } = newParams;
        return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultParams), typeof complianceCategory === 'number' && { complianceCategory }), { displayName }), sonarObjectId && { sonarObjectId }), sonarObjectType && { sonarObjectType }), applicationId && { applicationId }), tagId && { tagId }), viewId && { viewId }), viewName && { viewName });
    }
    else if (hasViewChanged(combined, prev)) {
        return combined;
    }
    else if (hasPageSizeChanged(newParams, prev)) {
        return Object.assign(Object.assign({}, combined), { key: '', paginationDirection: sonar_ts_types_1.PaginationDirection.Forward });
    }
    else if (hasFilterChanged(newParams, prev) && !hasViewChanged(newParams, prev)) {
        return Object.assign(Object.assign({}, combined), { key: '', lastViewId: (_a = prev.viewId) !== null && _a !== void 0 ? _a : prev.lastViewId, paginationDirection: sonar_ts_types_1.PaginationDirection.Forward, viewId: undefined, viewName: prev.viewName });
    }
    return combined;
};
const DataDictionary = () => {
    const { data, isRequested, isReceived } = (0, _hooks_1.useAsyncState)(selectors_1.getDataDictionarySelector);
    const getDataDictionary = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataDictionary);
    const updateView = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateCoreDataDictionaryView);
    const { resetAll } = (0, _hooks_1.useDataDictionaryReset)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const parsedQuery = (0, react_1.useMemo)(() => (0, sonar_ts_lib_1.parseQueryString)(location.search), [location.search]);
    const updateUrl = (0, react_1.useCallback)((queryObject) => {
        navigate(`${sonar_ts_constants_1.FULL_ROUTES.DATA_DICTIONARY}?${(0, sonar_http_1.getQueryString)(queryObject)}`, { replace: true });
    }, [navigate]);
    const callApi = (0, react_1.useCallback)((queryObject) => {
        const { columns, displayName } = queryObject, apiParams = __rest(queryObject, ["columns", "displayName"]);
        switch (true) {
            case Boolean(apiParams.sonarObjectId):
                getDataDictionary(apiParams);
                break;
            case Boolean(apiParams.sonarObjectType):
                getDataDictionary(Object.assign(Object.assign({}, apiParams), { sonarObjectType: (0, sonar_ts_lib_1.capitalize)(apiParams.sonarObjectType) }));
                break;
            case Boolean(apiParams.tagId):
                getDataDictionary(Object.assign(Object.assign({}, apiParams), { tagId: apiParams.tagId }));
                break;
            case Boolean(apiParams.applicationId):
                getDataDictionary(Object.assign(Object.assign({}, apiParams), { applicationId: apiParams.applicationId }));
                break;
            case typeof apiParams.complianceCategory === 'number':
                getDataDictionary(Object.assign(Object.assign({}, apiParams), { complianceCategory: apiParams.complianceCategory }));
                break;
            default:
        }
    }, [getDataDictionary]);
    const initialParams = (0, react_1.useMemo)(() => (Object.assign(Object.assign(Object.assign({}, defaultParams), { filters: '' }), parsedQuery)), [parsedQuery]);
    const appliedChanges = (0, react_1.useRef)(initialParams);
    (0, _hooks_1.useOnMount)(() => {
        if (initialParams.sonarObjectId ||
            initialParams.sonarObjectType ||
            initialParams.applicationId ||
            initialParams.tagId ||
            typeof initialParams.complianceCategory === 'number') {
            callApi(initialParams);
        }
    });
    const onParamsUpdate = (0, react_1.useCallback)((newParams, prev, combined) => {
        const _a = hasEmptyParams(newParams, prev) ?
            {} :
            combined, { viewSkip } = _a, urlParams = __rest(_a, ["viewSkip"]);
        updateUrl(urlParams);
        if (hasEmptyParams(newParams, prev)) {
            resetAll();
        }
        else if (hasTargetChanged(newParams, prev)) {
            resetAll();
            callApi(urlParams);
        }
        else if ((hasViewChanged(combined, prev) && !viewSkip) ||
            hasPageSizeChanged(newParams, prev) ||
            hasPageChanged(newParams, prev) ||
            (hasFilterChanged(newParams, prev) && hasFilterChanged(newParams, appliedChanges.current))) {
            callApi(urlParams);
        }
        appliedChanges.current = urlParams;
    }, [appliedChanges, callApi, resetAll, updateUrl]);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: initialParams,
        onUpdate: onParamsUpdate,
        transform: transformParams
    });
    const previousData = (0, _hooks_1.usePrevious)(data, data);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c, _d, _e;
        if (!data || data === previousData) {
            return;
        }
        const dataFilters = (0, lib_1.getAppliedFilters)(data.appliedOrderingAndFilters);
        const dataOrdering = (0, lib_1.getAppliedOrdering)(data.appliedOrderingAndFilters);
        const dataColumns = atob((_a = data.appliedColumns) !== null && _a !== void 0 ? _a : '');
        const { current } = appliedChanges;
        const filtersApplied = dataFilters === ((_b = current.filters) !== null && _b !== void 0 ? _b : '');
        const orderingApplied = dataOrdering === ((_c = current.ordering) !== null && _c !== void 0 ? _c : '');
        const columnsApplied = !current.viewId || dataColumns === ((_d = current.columns) !== null && _d !== void 0 ? _d : '');
        const notApplied = !filtersApplied || !orderingApplied || !columnsApplied;
        if (notApplied) {
            const update = {
                lastViewId: (_e = current.viewId) !== null && _e !== void 0 ? _e : current.lastViewId,
                viewId: undefined
            };
            if (!filtersApplied) {
                update.filters = dataFilters;
            }
            if (!orderingApplied) {
                update.ordering = dataOrdering;
            }
            if (!columnsApplied) {
                update.columns = dataColumns;
            }
            appliedChanges.current = Object.assign(Object.assign({}, current), update);
            if (current.viewId) {
                updateView({
                    columns: dataColumns,
                    filter: dataFilters,
                    id: current.viewId,
                    ordering: dataOrdering
                });
            }
            updateParams(update);
        }
    }, [data, previousData, updateParams, updateView]);
    const previousUrl = (0, _hooks_1.usePrevious)(location.search);
    (0, react_1.useEffect)(() => {
        const urlChanged = previousUrl && location.search !== previousUrl;
        if (!urlChanged) {
            return;
        }
        const latestParams = (0, sonar_ts_lib_1.parseQueryString)(location.search);
        const { current } = appliedChanges;
        if (hasTargetChanged(latestParams, current)) {
            updateParams(Object.assign(Object.assign({}, defaultParams), updateParams));
            return;
        }
        updateUrl(current);
    }, [location.search, previousUrl, updateParams, updateUrl]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(search_1.DataDictionarySearchBar, { queryParams: queryParams, updateQuery: updateParams }),
        react_1.default.createElement(react_if_1.If, { condition: isRequested || isReceived },
            react_1.default.createElement(react_if_1.Then, null,
                react_1.default.createElement(DataDictionaryTable_1.DataDictionaryTable, { loading: isRequested, queryParams: queryParams, resource: data, updateQuery: updateParams })),
            react_1.default.createElement(react_if_1.Else, null,
                react_1.default.createElement(QuickAccess_1.QuickAccess, { updateQuery: updateParams })))));
};
exports.DataDictionary = DataDictionary;
