"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncProcessing = useAsyncProcessing;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const react_redux_1 = require("react-redux");
const selectors_1 = require("@lib/selectors");
const xuder_1 = require("@sonar-software/xuder");
const _hooks_1 = require("@hooks");
const react_1 = require("react");
const defaultStatusHandlers = {
    errored: { success: false },
    failed: { success: false },
    received: { success: true }
};
function useAsyncProcessing(hookOptions) {
    const process = (0, _hooks_1.useProcessing)();
    const storedOptions = (0, react_1.useRef)(hookOptions);
    storedOptions.current = hookOptions;
    const { resetAction, onReset, watchAllCalls } = storedOptions.current;
    const [watchingState, setWatchingState] = (0, react_1.useState)(watchAllCalls);
    const status = (0, _hooks_1.useSonarSelector)((0, selectors_1.asyncStateStatusSelector)(hookOptions.stateSelector));
    const statusCode = (0, _hooks_1.useSonarSelector)((0, selectors_1.asyncStateStatusCodeSelector)(hookOptions.stateSelector));
    const previousStatus = (0, _hooks_1.usePrevious)(status, status);
    const statusChanged = status !== previousStatus;
    const dispatch = (0, react_redux_1.useDispatch)();
    const watchNextCall = (0, react_1.useCallback)(() => {
        setWatchingState(true);
    }, []);
    const reset = (0, react_1.useCallback)(() => {
        if (resetAction) {
            dispatch(resetAction());
        }
        if (onReset) {
            onReset();
        }
        process.reset();
    }, [dispatch, onReset, process, resetAction]);
    (0, react_1.useEffect)(() => {
        if (!statusChanged) {
            return;
        }
        if ((0, xuder_1.isIdle)(status)) {
            setWatchingState(watchAllCalls);
        }
        if (watchingState && (0, xuder_1.isRequested)(status)) {
            process.start();
        }
        if (!watchingState || (0, xuder_1.isIdle)(status) || (0, xuder_1.isRequested)(status)) {
            return;
        }
        let handled = false;
        const { allowMultipleHandlers, statusHandlers = defaultStatusHandlers, statusCodeHandlers, onStatus, onStatusCode } = storedOptions.current;
        if (statusCode && statusCodeHandlers) {
            const handler = statusCodeHandlers[statusCode];
            if ((0, sonar_ts_lib_1.isFunction)(handler)) {
                handler(statusCode);
            }
            else if (onStatusCode && handler) {
                onStatusCode(statusCode, handler);
            }
            else if (handler) {
                process.stop(handler);
            }
            if (!allowMultipleHandlers && handler) {
                handled = true;
            }
        }
        if (!handled && status) {
            const handler = statusHandlers === null || statusHandlers === void 0 ? void 0 : statusHandlers[status];
            if ((0, sonar_ts_lib_1.isFunction)(handler)) {
                handler(status);
            }
            else if (onStatus && handler) {
                onStatus(status, handler);
            }
            else if (handler) {
                process.stop(handler);
            }
        }
        reset();
    }, [statusChanged, process, reset, status, statusCode, watchAllCalls, watchingState]);
    return Object.assign(Object.assign({}, process), { watchNextCall });
}
