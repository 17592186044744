"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppNameFilter = void 0;
const applications_1 = require("@ui/applications");
const filters_1 = require("@ui/table-modifier/filters");
const lib_1 = require("@ui/table-modifier/@arch/lib");
const filters_2 = require("@periscopes/general/filters");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const lodash_1 = require("lodash");
const AppNameFilter = (props) => {
    const { format, id, label, onChange, value } = props;
    const apps = (0, _hooks_1.useDataSecurityApps)(false, false, false);
    const options = (0, react_1.useMemo)(() => (0, lodash_1.sortBy)((0, lodash_1.uniqBy)(apps.map((app) => ({
        key: app.applicationId,
        label: app.name,
        value: app.name
    })), (app) => app.label), (app) => app.label), [apps]);
    return (react_1.default.createElement(filters_1.FilterComponent, Object.assign({}, props, { tagProps: {
            value: options
                .filter((option) => value === null || value === void 0 ? void 0 : value.includes(option.value))
                .map((option) => option.label)
                .join(', ')
        } }),
        react_1.default.createElement(filters_2.OptionFilter, { className: 'filter', id: (0, lib_1.getFilterId)(id), maxTagCount: 'responsive', mode: 'multiple', onChange: (values) => {
                onChange({
                    [id]: {
                        format,
                        value: values !== null && values !== void 0 ? values : []
                    }
                });
            }, optionRender: (option) => react_1.default.createElement(applications_1.AppDisplay, { appId: option.key, hideLabel: true }), options: options, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(label !== null && label !== void 0 ? label : ''), showArrow: true, value: value })));
};
exports.AppNameFilter = AppNameFilter;
