"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeSource = void 0;
require("./PeriscopeSource.scss");
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const SalesforceLogo_1 = require("./SalesforceLogo");
const SonarLogo_1 = require("./SonarLogo");
const antd_1 = require("antd");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const sourceIcons = {
    [sonar_ts_constants_1.ORGANIZATION_TYPE_LABELS.PARDOT]: SalesforceLogo_1.SalesforceLogo,
    [sonar_ts_constants_1.ORGANIZATION_TYPE_LABELS.SALESFORCE]: SalesforceLogo_1.SalesforceLogo,
    [sonar_ts_constants_1.ORGANIZATION_TYPE_LABELS.SONAR]: SonarLogo_1.SonarLogo
};
const PeriscopeSource = ({ iconColor, id, sonarObjectType, url }) => {
    const logAnalyticEvent = (0, _hooks_1.useAmplitude)();
    const sourceLabel = (0, sonar_ts_lib_1.getSonarObjectTypeSourceLabel)(sonarObjectType);
    const Icon = sourceIcons[sourceLabel];
    const handleClick = (0, react_1.useCallback)((e) => {
        e.stopPropagation();
        logAnalyticEvent({
            data: { objectType: sonarObjectType },
            id: sonar_analytics_1.AMPLITUDE_EVENT.SALESFORCE_URL_CLICKED
        });
    }, [logAnalyticEvent, sonarObjectType]);
    const iconBase = react_1.default.createElement(Icon, { color: iconColor, title: '' });
    const iconDisplay = url ?
        react_1.default.createElement("a", { href: url, id: id, onClick: handleClick, target: '_blank', title: sourceLabel }, iconBase) :
        iconBase;
    return (react_1.default.createElement("div", { className: 'periscope-source' },
        react_1.default.createElement(antd_1.Tooltip, { placement: 'bottom', title: sourceLabel }, iconDisplay)));
};
exports.PeriscopeSource = PeriscopeSource;
