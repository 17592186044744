"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeSummary = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const antd_1 = require("antd");
const _1 = require(".");
const react_1 = __importStar(require("react"));
const InitiativeSummary = ({ initiativeId }) => {
    const getSummary = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreInitiativesSummary);
    (0, react_1.useEffect)(() => {
        if (initiativeId) {
            getSummary({ initiativeId });
        }
    }, [getSummary, initiativeId]);
    return (react_1.default.createElement("div", { id: 'initiative-summary' },
        react_1.default.createElement(_1.InitiativeScopeSummary, { initiativeId: initiativeId }),
        react_1.default.createElement(_1.InitiativeTasksSummary, { initiativeId: initiativeId }),
        react_1.default.createElement(antd_1.Row, { gutter: 32 },
            react_1.default.createElement(antd_1.Col, null,
                react_1.default.createElement(_1.InitiativeOwnersSummary, { initiativeId: initiativeId })),
            react_1.default.createElement(antd_1.Col, null,
                react_1.default.createElement(_1.InitiativeStakeholdersSummary, { initiativeId: initiativeId })))));
};
exports.InitiativeSummary = InitiativeSummary;
