"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotEmailMetadataTab = void 0;
require("./PardotEmailMetadataTab.scss");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const email_templates_1 = require("@periscopes/general/email-templates");
const text_1 = require("@ui/text");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const metadata_1 = require("@periscopes/general/metadata");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function getStateAndDateMetadataEntries(metadata) {
    switch (metadata.state) {
        case sonar_ts_types_1.PeriscopePardotEmailState.Scheduled:
            return [
                {
                    bold: true,
                    label: sonar_ts_constants_1.COPY_SPECIFIC.STATE,
                    type: 'string',
                    value: metadata.state
                },
                {
                    label: sonar_ts_constants_1.COPY_SPECIFIC.SCHEDULED_FOR,
                    type: 'string',
                    value: metadata.scheduledFor ? (0, sonar_ts_lib_1.convertISODateToPardotDate)(metadata.scheduledFor) : null
                }
            ];
        case sonar_ts_types_1.PeriscopePardotEmailState.Sent:
            return [
                {
                    bold: true,
                    label: sonar_ts_constants_1.COPY_SPECIFIC.STATE,
                    type: 'string',
                    value: metadata.state
                },
                {
                    label: sonar_ts_constants_1.COPY_SPECIFIC.SENT_AT,
                    type: 'string',
                    value: metadata.scheduledFor ? (0, sonar_ts_lib_1.convertISODateToPardotDate)(metadata.scheduledFor) : null
                }
            ];
        default:
            return [
                {
                    bold: true,
                    label: sonar_ts_constants_1.COPY_SPECIFIC.STATE,
                    type: 'string',
                    value: metadata.state
                }
            ];
    }
}
const PardotEmailMetadataTab = ({ index, sonarObjectId }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { loading, metadata } = (0, _hooks_1.usePeriscopeMetadata)(sonarObjectId, sonar_ts_types_1.SonarObjectType.PardotEmail);
    const handleEmailTemplateClick = (0, react_1.useCallback)(() => {
        var _a, _b;
        if (!((_a = metadata === null || metadata === void 0 ? void 0 : metadata.emailTemplate) === null || _a === void 0 ? void 0 : _a.sonarObjectId)) {
            return;
        }
        const { emailTemplate } = metadata;
        openPeriscope({
            id: emailTemplate.sonarObjectId,
            name: emailTemplate.displayName,
            openedFromIndex: index,
            type: (_b = emailTemplate.sonarObjectType) !== null && _b !== void 0 ? _b : sonar_ts_types_1.SonarObjectType.PardotEmailTemplate
        });
    }, [index, openPeriscope, metadata]);
    const metadataEntries = (0, react_1.useMemo)(() => {
        if (!metadata) {
            return [];
        }
        const stateAndDateMetadataEntries = getStateAndDateMetadataEntries(metadata);
        const entries = [
            ...stateAndDateMetadataEntries,
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.SENDER,
                type: 'array',
                value: metadata.sender
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.REPLY_TO,
                type: 'array',
                value: metadata.replyTo
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.TYPE,
                type: 'string',
                value: metadata.type
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.CONTENT_TYPE,
                type: 'string',
                value: metadata.contentType
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.SUBJECT,
                type: 'string',
                value: metadata.subject
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.BOUNCE,
                type: 'string',
                value: metadata.bounce
            }
        ];
        return entries;
    }, [metadata]);
    if (loading) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(metadata_1.MetadataContainer, { entries: metadataEntries, title: sonar_ts_constants_1.COPY_SPECIFIC.EMAIL_INFORMATION }),
        (metadata === null || metadata === void 0 ? void 0 : metadata.emailTemplate) &&
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(text_1.SonarText, { className: 'email-template-title' }, sonar_ts_constants_1.COPY_SONAR_OBJECT_BASE.EMAIL_TEMPLATE),
                react_1.default.createElement(email_templates_1.PardotEmailTemplate, { entity: metadata.emailTemplate, onEmailTemplateClick: handleEmailTemplateClick }))));
};
exports.PardotEmailMetadataTab = PardotEmailMetadataTab;
