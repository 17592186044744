"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessLogTable = void 0;
require("./AccessLogTable.scss");
const AccessLogExportButton_1 = require("./AccessLogExportButton");
const ComplianceCategories_1 = require("@ui/icons/ComplianceCategories");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const lodash_1 = require("lodash");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const AccessLogTableColumns_1 = require("./AccessLogTableColumns");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const _hooks_1 = require("@hooks");
const getRowKey = (result) => result.id;
const columns = (0, AccessLogTableColumns_1.getColumns)();
const dataSecurityAccessLogSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.accessLog.get);
const dataSecurityAppUsersSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.appUsers.byAppId.get);
const classificationOptions = [1, 2, 4, 8, 16, 64]
    .map((value) => ({
    label: react_1.default.createElement(ComplianceCategories_1.ComplianceCategories, { categoryFlags: value, direction: 'horizontal' }),
    value
}));
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    defaultOperator: sonar_ts_types_1.DynamicQueryOperator.Equals,
    filterParams: {
        applicationId: true,
        classification: { name: 'complianceCategory' },
        fieldName: { operator: sonar_ts_types_1.DynamicQueryOperator.Like },
        parentObject: { operator: sonar_ts_types_1.DynamicQueryOperator.Like },
        start: {
            name: 'eventDate',
            rangeEnd: 'end'
        },
        username: {
            name: 'userId',
            value: (value) => value || undefined
        }
    }
});
const AccessLogTable = ({ applicationId, dateRange }) => {
    const getDataSecurityAccessLog = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityAccessLog);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(dataSecurityAccessLogSelectors);
    const defaultArgs = { applicationId };
    const { data: appUsers, isRequested: isAppUsersRequested, request } = (0, _hooks_1.useAsyncStateWithPreload)(dataSecurityAppUsersSelectors, sonar_core_1.coreActions.requestGetCoreDataSecurityAppUsersByAppId, true, defaultArgs);
    const [dateChanged, setDateChanged] = (0, react_1.useState)(false);
    const [convertedParams, setConvertedParams] = (0, react_1.useState)({});
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            sortBy: 'eventDate',
            sortDirection: 1,
            start: (0, sonar_ts_lib_1.getPastDate)(7)
        },
        onUpdate: (newParams, prev, combined) => {
            const { key, paginationDirection } = newParams;
            const params = convertToDql(Object.assign(Object.assign({}, combined), { key,
                paginationDirection }));
            setConvertedParams(params);
            getDataSecurityAccessLog(params);
        }
    });
    const appUserOptions = (0, react_1.useMemo)(() => appUsers === null || appUsers === void 0 ? void 0 : appUsers.map((user) => ({
        label: user.username,
        value: user.rawSalesforceId
    })), [appUsers]);
    const debouncedGetAppUsers = (0, react_1.useMemo)(() => (0, lodash_1.debounce)((filter) => request({
        applicationId,
        filter
    }), 300), [applicationId, request]);
    (0, react_1.useEffect)(() => {
        if (applicationId !== queryParams.applicationId) {
            updateParams({ applicationId });
        }
    }, [applicationId, queryParams.applicationId, updateParams]);
    (0, react_1.useEffect)(() => {
        if (dateRange.end !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.end) || dateRange.start !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.start)) {
            setDateChanged(true);
            const { start, end } = dateRange;
            updateParams({
                end,
                start
            });
        }
        else if (dateChanged) {
            setDateChanged(false);
        }
    }, [dateChanged, dateRange, queryParams === null || queryParams === void 0 ? void 0 : queryParams.end, queryParams === null || queryParams === void 0 ? void 0 : queryParams.start, updateParams]);
    const handleFieldNameChange = (0, react_1.useCallback)((fieldName) => {
        updateParams({ fieldName });
    }, [updateParams]);
    const handleUserSelect = (0, react_1.useCallback)((username) => {
        updateParams({ username });
    }, [updateParams]);
    const handleClassificationSelect = (0, react_1.useCallback)((classification) => {
        updateParams({ classification });
    }, [updateParams]);
    const handleParentObjectChange = (0, react_1.useCallback)((parentObject) => {
        updateParams({ parentObject });
    }, [updateParams]);
    return (react_1.default.createElement(general_1.SonarCard, { className: 'access-log-table', extra: react_1.default.createElement(AccessLogExportButton_1.AccessLogExportButton, { queryParams: convertedParams }), loading: dateChanged && isRequested, noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.ACCESS_LOG, titleClassName: 'h2', useSpinnerLoading: true },
        react_1.default.createElement("div", { className: 'table-filters' },
            react_1.default.createElement(general_1.SearchInput, { className: 'field-name-input', onChange: handleFieldNameChange, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.FIELD_NAME }),
            react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'user-select', dropdownRender: (menu) => (isAppUsersRequested ?
                    react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null, menu) :
                    menu), filterOption: false, onChange: handleUserSelect, onSearch: debouncedGetAppUsers, options: appUserOptions, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.USER, showSearch: true }),
            react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'classification-select', onChange: handleClassificationSelect, options: classificationOptions, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.CLASSIFICATION }),
            react_1.default.createElement(general_1.SearchInput, { className: 'parent-object-input', onChange: handleParentObjectChange, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.PARENT_OBJECT })),
        react_1.default.createElement("div", { className: 'table-container' },
            react_1.default.createElement(PaginatedTable_1.PaginatedTable, { autohidePagination: true, columns: columns, id: 'access-log-table', loading: isRequested, onChange: updateParams, resource: data, rowKey: getRowKey, scroll: { x: 'max-content' }, showSizeChanger: false, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection }))));
};
exports.AccessLogTable = AccessLogTable;
