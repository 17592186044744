"use strict";
/* eslint-disable no-bitwise */
Object.defineProperty(exports, "__esModule", { value: true });
exports.aggregateComplianceCategories = aggregateComplianceCategories;
exports.splitComplianceCategories = splitComplianceCategories;
exports.convertArrayToFilterString = convertArrayToFilterString;
exports.getFilterId = getFilterId;
exports.getAppliedFilters = getAppliedFilters;
exports.getAppliedOrdering = getAppliedOrdering;
exports.translateFilterQueryParamsToDynamicQueryLanguage = translateFilterQueryParamsToDynamicQueryLanguage;
exports.translateOrderQueryParamsToDynamicQueryLanguage = translateOrderQueryParamsToDynamicQueryLanguage;
exports.translateFilterAndOrderingToQueryParams = translateFilterAndOrderingToQueryParams;
exports.getColumnsString = getColumnsString;
exports.parseColumnString = parseColumnString;
exports.parseMetadataFromResponse = parseMetadataFromResponse;
const Populated_1 = require("@ui/table-modifier/filters/Populated");
const DataDictionaryTableColumns_1 = require("@dictionary/DataDictionaryTableColumns");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const multiselectEnums = [
    'DataSensitivityLevelId',
    'FieldUsageId',
    'Type'
];
function aggregateComplianceCategories(categories) {
    if (categories.includes(sonar_core_1.ComplianceCategory.None)) {
        return sonar_core_1.ComplianceCategory.None;
    }
    else if (categories.includes(sonar_core_1.ComplianceCategory.Any)) {
        return sonar_core_1.ComplianceCategory.Any;
    }
    let aggregatedValue = 0;
    categories.forEach((category) => {
        aggregatedValue |= category;
    });
    return aggregatedValue;
}
function splitComplianceCategories(aggregateCategory) {
    if (aggregateCategory === sonar_core_1.ComplianceCategory.None) {
        return [sonar_core_1.ComplianceCategory.None];
    }
    else if (aggregateCategory === sonar_core_1.ComplianceCategory.Any) {
        return [sonar_core_1.ComplianceCategory.Any];
    }
    const results = [];
    for (const category in sonar_core_1.ComplianceCategory) {
        const categoryNumber = Number(category);
        if ((aggregateCategory & categoryNumber) !== 0 &&
            categoryNumber !== sonar_core_1.ComplianceCategory.None &&
            categoryNumber !== sonar_core_1.ComplianceCategory.Any) {
            results.push(categoryNumber);
        }
    }
    return results;
}
function convertArrayToFilterString(value, filterKey) {
    const valueArray = value;
    const hasNoSelectionValue = valueArray.includes(-1) || valueArray.includes('null');
    if (hasNoSelectionValue && valueArray.length === 1) {
        return (0, sonar_ts_lib_1.makeDynamicFilterString)(filterKey, '', sonar_ts_types_1.DynamicQueryOperator.IsNull);
    }
    const dynamicQueryOperator = hasNoSelectionValue ? sonar_ts_types_1.DynamicQueryOperator.Inn : sonar_ts_types_1.DynamicQueryOperator.In;
    const cleanedValues = valueArray.filter((rawValue) => rawValue !== -1 && rawValue !== 'null');
    return (0, sonar_ts_lib_1.makeDynamicFilterString)(filterKey, cleanedValues, dynamicQueryOperator);
}
function getFilterId(filterId) {
    return `data-dictionary-${filterId}-filter`;
}
function getAppliedFilters(filters) {
    return filters.substring('Filter = '.length, filters.indexOf('&'));
}
function getAppliedOrdering(ordering) {
    return ordering.substring(ordering.indexOf('&Ordering = ') + '&Ordering = '.length);
}
function translateFilterQueryParamsToDynamicQueryLanguage(filterParams) {
    const filterKeyArray = Object.keys(filterParams);
    if (filterKeyArray.length === 0) {
        return '';
    }
    const filterArray = filterKeyArray.map((filterKey) => {
        const { format, value } = filterParams[filterKey];
        if (value === null || value === undefined) {
            return '';
        }
        switch (format) {
            case sonar_core_1.SchemaFormat.Checkmark:
            case sonar_core_1.SchemaFormat.Enum:
                if (multiselectEnums.includes(filterKey)) {
                    return convertArrayToFilterString(value, filterKey);
                }
                if (filterKey === 'ComplianceCategory') {
                    const operator = value === sonar_core_1.ComplianceCategory.None ?
                        sonar_ts_types_1.DynamicQueryOperator.BitwiseOrWithNull :
                        sonar_ts_types_1.DynamicQueryOperator.BitwiseOr;
                    return (0, sonar_ts_lib_1.makeDynamicFilterString)(filterKey, Number(value), operator);
                }
                return (0, sonar_ts_lib_1.makeDynamicFilterString)(filterKey, value, sonar_ts_types_1.DynamicQueryOperator.Equals);
            case sonar_core_1.SchemaFormat.PopulationPercentage:
                return (0, sonar_ts_lib_1.makeDynamicFilterBetweenString)(filterKey, value);
            case sonar_core_1.SchemaFormat.ReferenceCount:
                return (0, sonar_ts_lib_1.makeDynamicFilterRangeString)(filterKey, value);
            case sonar_core_1.SchemaFormat.DateTime:
                const formattedValues = value.map((dateMoment) => dateMoment === null || dateMoment === void 0 ? void 0 : dateMoment.format(sonar_ts_constants_1.FORMATS.SHORT_DATE));
                return (0, sonar_ts_lib_1.makeDynamicFilterRangeString)(filterKey, formattedValues);
            case sonar_core_1.SchemaFormat.Object:
            case sonar_core_1.SchemaFormat.Owner:
            case sonar_core_1.SchemaFormat.PageLayouts:
            case sonar_core_1.SchemaFormat.Profiles:
            case sonar_core_1.SchemaFormat.SalesforceUser:
            case sonar_core_1.SchemaFormat.Stakeholder:
            case sonar_core_1.SchemaFormat.Tags:
            case sonar_core_1.SchemaFormat.Apps:
                return convertArrayToFilterString(value, filterKey);
            case sonar_core_1.SchemaFormat.Primary:
            case sonar_core_1.SchemaFormat.PrimaryInternal:
            case sonar_core_1.SchemaFormat.Text:
                return (0, sonar_ts_lib_1.makeDynamicFilterString)(filterKey, value, sonar_ts_types_1.DynamicQueryOperator.Like);
            case sonar_core_1.SchemaFormat.Description:
            case sonar_core_1.SchemaFormat.HelpText:
                const selectedValue = value;
                if (selectedValue === Populated_1.PopulatedValues.isNullValue) {
                    return (0, sonar_ts_lib_1.makeDynamicFilterString)(filterKey, '', sonar_ts_types_1.DynamicQueryOperator.IsNull);
                }
                return (0, sonar_ts_lib_1.makeDynamicFilterString)(filterKey, '', sonar_ts_types_1.DynamicQueryOperator.NotNull);
            default:
                return '';
        }
    });
    const filters = filterArray === null || filterArray === void 0 ? void 0 : filterArray.filter((filter) => filter !== '').join(' ');
    return filters;
}
function translateOrderQueryParamsToDynamicQueryLanguage(sortParams) {
    return `${sortParams.sortBy}~${sortParams.sortDirection === sonar_ts_types_1.SonarV3SortDirection.Ascending ? 'a' : 'd'}`;
}
function translateFilterAndOrderingToQueryParams(filters, sortOptions, filterString, orderingString) {
    const record = {};
    const filterLookup = (0, sonar_ts_lib_1.buildDynamicFilterLookupFromString)(filterString);
    record.filterCount = (0, sonar_ts_lib_1.objectKeys)(filterLookup).filter((x) => Boolean(filters.find((y) => y.filterId === x))).length;
    filters === null || filters === void 0 ? void 0 : filters.forEach((filter) => {
        var _a, _b, _c, _d;
        const { filterId, filterFormat } = filter;
        if (!filterString) {
            return;
        }
        const filterObject = {
            format: filterFormat,
            value: undefined
        };
        switch (filterFormat) {
            case sonar_core_1.SchemaFormat.Checkmark:
                filterObject.value = ((_a = (0, sonar_ts_lib_1.getValuesFromDynamicFilterLookup)(filterId, filterLookup)[0]) === null || _a === void 0 ? void 0 : _a.value) || null;
                break;
            case sonar_core_1.SchemaFormat.ReferenceCount:
                filterObject.value = (0, sonar_ts_lib_1.getRangeValuesFromDynamicFilterLookup)(filterId, filterLookup);
                break;
            case sonar_core_1.SchemaFormat.DateTime:
                filterObject.value = (0, sonar_ts_lib_1.getDateRangeValuesFromDynamicFilterLookup)(filterId, filterLookup);
                break;
            case sonar_core_1.SchemaFormat.PopulationPercentage:
                filterObject.value = (0, sonar_ts_lib_1.getMultiSelectValuesFromDynamicFilterLookup)(filterId, filterLookup);
                break;
            case sonar_core_1.SchemaFormat.Enum:
                filterObject.value = multiselectEnums.includes(filterId) ?
                    (0, sonar_ts_lib_1.getMultiSelectValuesFromDynamicFilterLookup)(filterId, filterLookup, false) :
                    (0, sonar_ts_lib_1.getStatusValueFromDynamicFilterLookup)(filterId, filterLookup);
                break;
            case sonar_core_1.SchemaFormat.SalesforceUser:
            case sonar_core_1.SchemaFormat.Object:
                filterObject.value = (0, sonar_ts_lib_1.getMultiSelectValuesFromDynamicFilterLookup)(filterId, filterLookup, true);
                break;
            case sonar_core_1.SchemaFormat.Owner:
            case sonar_core_1.SchemaFormat.PageLayouts:
            case sonar_core_1.SchemaFormat.Profiles:
            case sonar_core_1.SchemaFormat.Stakeholder:
            case sonar_core_1.SchemaFormat.Tags:
            case sonar_core_1.SchemaFormat.Apps:
                filterObject.value = (0, sonar_ts_lib_1.getMultiSelectValuesFromDynamicFilterLookup)(filterId, filterLookup, false);
                break;
            case sonar_core_1.SchemaFormat.Primary:
            case sonar_core_1.SchemaFormat.PrimaryInternal:
            case sonar_core_1.SchemaFormat.Text:
                filterObject.value = (_c = (_b = (0, sonar_ts_lib_1.getValuesFromDynamicFilterLookup)(filterId, filterLookup)[0]) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '';
                break;
            case sonar_core_1.SchemaFormat.Description:
            case sonar_core_1.SchemaFormat.HelpText:
                const operator = (_d = (0, sonar_ts_lib_1.getValuesFromDynamicFilterLookup)(filterId, filterLookup)[0]) === null || _d === void 0 ? void 0 : _d.operator;
                if (operator === sonar_ts_types_1.DynamicQueryOperator.IsNull) {
                    filterObject.value = Populated_1.PopulatedValues.isNullValue;
                }
                else if (operator === sonar_ts_types_1.DynamicQueryOperator.NotNull) {
                    filterObject.value = Populated_1.PopulatedValues.notNullValue;
                }
                break;
            default:
                return;
        }
        record[filterId] = filterObject;
    });
    if (orderingString) {
        const sortTarget = (0, sonar_ts_lib_1.getOrderingFromDynamicOrderString)(orderingString, sortOptions);
        if (sortTarget) {
            record.sortBy = sortTarget.key;
            record.sortDirection = sortTarget.direction;
            record.sortDefault = sortTarget;
        }
    }
    return record;
}
function getColumnsString(columnIds, visibleColumns) {
    return columnIds.map((id) => `${!visibleColumns || visibleColumns[id] ? 1 : 0}${id}`).join('');
}
function parseColumnString(str) {
    const accumlator = {
        ids: [],
        isVisible: {}
    };
    if (!str) {
        return accumlator;
    }
    return str.split(/(?=1|0)/gu).reduce((result, columnStr) => {
        const id = columnStr.slice(1);
        const visible = Number(columnStr[0]) === 1;
        return {
            ids: [...result.ids, id],
            isVisible: Object.assign(Object.assign({}, result.isVisible), { [id]: visible })
        };
    }, accumlator);
}
const isSchemaFormatValid = (0, sonar_ts_lib_1.buildStringEnumValidator)(sonar_core_1.SchemaFormat);
function parseMetadataFromResponse(schema, featureFlagCheck, currentColumns, getPopupContainer) {
    if (!schema) {
        return {
            columns: [],
            filters: [],
            schema: [],
            sortOptions: []
        };
    }
    const columns = schema.reduce((result, column) => {
        const format = column.format.includes(sonar_core_1.SchemaFormat.Enum) ? sonar_core_1.SchemaFormat.Enum : column.format;
        if (format === sonar_core_1.SchemaFormat.None || format.includes('Filter') || !isSchemaFormatValid(format)) {
            return result;
        }
        return [
            ...result,
            {
                id: column.columnId,
                name: column.columnName
            }
        ];
    }, []);
    const { ids: columnOrder, isVisible } = parseColumnString(currentColumns || getColumnsString(columns.map((column) => column.id)));
    const arrangedAndFilteredSchema = columnOrder.reduce((arranged, id) => {
        if (isVisible[id]) {
            const index = schema.findIndex((item) => item.columnId === id);
            if (index >= 0) {
                arranged.push(schema[index]);
            }
        }
        return arranged;
    }, []);
    const columnsFromSchema = (0, DataDictionaryTableColumns_1.translateApiResultsToDataDictionaryColumns)(arrangedAndFilteredSchema !== null && arrangedAndFilteredSchema !== void 0 ? arrangedAndFilteredSchema : [], getPopupContainer);
    const filterableSchema = schema.filter((schemaObject) => schemaObject.filterable);
    const sortableSchema = schema.filter((schemaObject) => schemaObject.sortable);
    const indexOfFirstApplication = filterableSchema.findIndex((x) => x.format === sonar_core_1.SchemaFormat.Apps);
    const uniqueFilterableSchema = filterableSchema.filter((x, index) => x.format !== sonar_core_1.SchemaFormat.Apps || (x.format === sonar_core_1.SchemaFormat.Apps && index !== indexOfFirstApplication));
    const filtersFromSchema = uniqueFilterableSchema.map((schemaObject) => {
        const format = schemaObject.format.includes(sonar_core_1.SchemaFormat.Enum) ?
            sonar_core_1.SchemaFormat.Enum :
            schemaObject.format;
        if (format === sonar_core_1.SchemaFormat.Apps) {
            return {
                filterFormat: format,
                filterId: 'IntegratedAppIds',
                filterLabel: sonar_ts_constants_1.COPY_SPECIFIC.MONITORED_APPLICATIONS
            };
        }
        return {
            filterFormat: format,
            filterId: schemaObject.columnId,
            filterLabel: schemaObject.columnName,
            filterMetadata: schemaObject.metadata
        };
    });
    const defaultAscending = [sonar_core_1.SchemaFormat.Text, sonar_core_1.SchemaFormat.Primary, sonar_core_1.SchemaFormat.PrimaryInternal];
    const sortOptionsFromSchema = sortableSchema.map((schemaObject) => ({
        direction: defaultAscending.includes(schemaObject.format) ?
            sonar_ts_types_1.SonarV3SortDirection.Ascending :
            sonar_ts_types_1.SonarV3SortDirection.Descending,
        key: schemaObject.columnId,
        label: schemaObject.columnName
    }));
    return {
        columns: columns,
        filters: filtersFromSchema,
        schema: columnsFromSchema,
        sortOptions: sortOptionsFromSchema
    };
}
