"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataSensitivitySelector = void 0;
const DataSensitivityLevelTag_1 = require("./DataSensitivityLevelTag");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const SonarSelector_1 = require("../SonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
function updateDataSensitivity(sonarObjectId, dataSensitivityLevelId) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        const result = yield (0, sonar_core_1.patchSalesforceSonarObject)({
            dataSensitivityLevelId,
            sonarObjectId
        });
        return (_a = result.data) !== null && _a !== void 0 ? _a : _hooks_1.genericProcessingFailure;
    });
}
const options = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.DataSensitivityLevelId).map((level) => ({
    label: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevelId: level }),
    optionLabel: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevelId: level }),
    searchValue: sonar_core_1.DATA_SENSITIVITY_LABELS[level],
    value: level
}));
const DataSensitivitySelector = (_a) => {
    var _b, _c;
    var { isSpreadsheet, sonarObjectId } = _a, rest = __rest(_a, ["isSpreadsheet", "sonarObjectId"]);
    const { dataSensitivityLevelId } = (_c = (_b = (0, _hooks_1.useSonarObjectReference)(sonarObjectId)) === null || _b === void 0 ? void 0 : _b.salesforce) !== null && _c !== void 0 ? _c : {};
    const { update } = (0, _hooks_1.useUpdateSonarObjectReference)();
    const { start, state, stop } = (0, _hooks_1.useProcessing)();
    const { changes } = (0, _hooks_1.useOptimisticUpdate)(sonarObjectId);
    const previousDataSensitivityLevel = (0, _hooks_1.usePrevious)(dataSensitivityLevelId);
    const [value, setValue] = (0, react_1.useState)(dataSensitivityLevelId);
    (0, react_1.useEffect)(() => {
        if (dataSensitivityLevelId !== previousDataSensitivityLevel) {
            setValue(dataSensitivityLevelId);
        }
    }, [dataSensitivityLevelId, previousDataSensitivityLevel]);
    const handleChange = (0, react_1.useCallback)((change) => __awaiter(void 0, void 0, void 0, function* () {
        const newValue = change !== null && change !== void 0 ? change : sonar_core_1.DataSensitivityLevelId.None;
        if (newValue === dataSensitivityLevelId) {
            return;
        }
        const fallback = value;
        setValue(newValue);
        start();
        const result = yield updateDataSensitivity(sonarObjectId, newValue);
        if (result.success) {
            update({
                salesforce: { dataSensitivityLevelId: newValue },
                sonarObjectId
            });
        }
        else {
            setValue(fallback);
        }
        stop(result);
    }), [dataSensitivityLevelId, sonarObjectId, start, stop, update, value]);
    return (react_1.default.createElement(SonarSelector_1.SonarSelector, Object.assign({}, rest, { allowClear: dataSensitivityLevelId !== sonar_core_1.DataSensitivityLevelId.None, boxless: !isSpreadsheet, className: 'salesforce-data-sensitivity-selector', defaultValue: dataSensitivityLevelId || sonar_core_1.DataSensitivityLevelId.None, isSalesforce: true, isSpreadsheet: isSpreadsheet, onChange: handleChange, options: options, placeholder: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevelId: sonar_core_1.DataSensitivityLevelId.None }), readOnlyDisplay: react_1.default.createElement(DataSensitivityLevelTag_1.DataSensitivityLevelTag, { dataSensitivityLevelId: dataSensitivityLevelId || sonar_core_1.DataSensitivityLevelId.None }), showArrow: true, showSearch: false, state: changes.dataSensitivityLevelId ? { isProcessing: true } : state, value: state.isProcessing ? value : dataSensitivityLevelId || sonar_core_1.DataSensitivityLevelId.None })));
};
exports.DataSensitivitySelector = DataSensitivitySelector;
