"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceUserSelector = void 0;
require("./SalesforceUserSelector.scss");
const SalesforceUser_1 = require("./SalesforceUser");
const SonarSelector_1 = require("./SonarSelector");
const antd_1 = require("antd");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const SalesforceUserSelector = ({ allowReadonly, avatar, direction = 'vertical', disabled, getPopupContainer, id, isSpreadsheet, onChange, placeholder, value }) => {
    const users = (0, _hooks_1.useSalesforceUsers)();
    const userNames = (0, react_1.useMemo)(() => users === null || users === void 0 ? void 0 : users.reduce((names, user) => {
        names[user.username] = user.name;
        return names;
    }, {}), [users]);
    const selectedUsers = (0, react_1.useMemo)(() => {
        if (value) {
            return Array.isArray(value) ? value : [value];
        }
        return [];
    }, [value]);
    const placeholderNode = (0, react_1.useMemo)(() => react_1.default.createElement(SalesforceUser_1.SalesforceUser, { avatar: avatar, placeholder: placeholder, username: '' }), [avatar, placeholder]);
    const options = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = users === null || users === void 0 ? void 0 : users.filter((user) => user.username)) === null || _a === void 0 ? void 0 : _a.map((user) => ({
            label: react_1.default.createElement(SalesforceUser_1.SalesforceUser, { avatar: avatar, name: user.name, username: user.username }),
            optionLabel: react_1.default.createElement(SalesforceUser_1.SalesforceUser, { avatar: avatar, name: user.name, showTooltip: true, username: user.username }),
            searchValue: user.name,
            value: user.username
        }));
    }, [users, avatar]);
    const readOnlyDisplay = (0, react_1.useMemo)(() => react_1.default.createElement(antd_1.Space, { direction: direction, size: 4, wrap: true }, selectedUsers.map((username) => react_1.default.createElement(SalesforceUser_1.SalesforceUser, { key: username, name: userNames === null || userNames === void 0 ? void 0 : userNames[username], showTooltip: true, username: username }))), [direction, selectedUsers, userNames]);
    const handleOnChange = (0, react_1.useCallback)((values) => {
        if (onChange) {
            onChange(values);
        }
    }, [onChange]);
    return (react_1.default.createElement(SonarSelector_1.SonarSelector, { allowReadonly: allowReadonly, className: 'salesforce-user-selector', disabled: disabled, getPopupContainer: getPopupContainer, id: id, isSpreadsheet: isSpreadsheet, mode: 'multiple', onChange: handleOnChange, options: options, placeholder: placeholderNode, popupClassName: 'salesforce-user-space-conscious-label', readOnlyDisplay: (value === null || value === void 0 ? void 0 : value.length) ? readOnlyDisplay : null, value: selectedUsers }));
};
exports.SalesforceUserSelector = SalesforceUserSelector;
