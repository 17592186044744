"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationSwitcher = void 0;
require("./OrganizationSwitcher.scss");
const icons_1 = require("@ant-design/icons");
const lottie_1 = require("@ui/lottie");
const react_if_1 = require("react-if");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const menu_1 = require("@ui/menu");
const _hooks_1 = require("@hooks");
const react_router_dom_1 = require("react-router-dom");
function displayName(org) {
    return org.isSandbox ? `${org.name} (sandbox)` : org.name;
}
function buildMenuItems(organizations, isClosed, title, selectedKey) {
    var _a, _b;
    const subMenu = {
        children: [],
        key: 'org-submenu',
        label: react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(icons_1.CloudOutlined, null),
            react_1.default.createElement(react_if_1.When, { condition: !isClosed },
                react_1.default.createElement(antd_1.Tooltip, { mouseEnterDelay: 0.5, placement: 'bottom', title: title }, title)))
    };
    for (const org of organizations) {
        const isSelected = org.id === selectedKey;
        const orgDisplayName = displayName(org);
        const showIcon = org.salesforceSyncInProgress && !org.hasSalesforceData;
        (_a = subMenu.children) === null || _a === void 0 ? void 0 : _a.push({
            className: isSelected ? 'selected-org' : '',
            id: `switch-org-${org.id}`,
            key: String(org.id),
            label: react_1.default.createElement(antd_1.Tooltip, { mouseEnterDelay: 0.5, placement: 'right', title: orgDisplayName },
                react_1.default.createElement(antd_1.Space, { className: 'org-entry-wrapper' },
                    react_1.default.createElement("span", { className: 'switcher-org-name' }, orgDisplayName),
                    showIcon && react_1.default.createElement(lottie_1.SalesforceCloudOutline, { className: 'syncing-icon' })))
        });
    }
    (_b = subMenu.children) === null || _b === void 0 ? void 0 : _b.push({
        id: 'update-organizations',
        key: sonar_ts_constants_1.FULL_ROUTES.SETTINGS_ORGANIZATION,
        label: react_1.default.createElement(antd_1.Button, { ghost: true, id: 'update-organizations-button' }, sonar_ts_constants_1.COPY_SPECIFIC.CONFIGURE_ORGANIZATIONS)
    });
    return [subMenu];
}
const OrganizationSwitcher = ({ isClosed }) => {
    var _a;
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const organizations = (0, _hooks_1.useSalesforceOrganizations)();
    const currentOrganization = (0, _hooks_1.useCurrentOrganization)();
    const requestSwitch = (0, _hooks_1.useSwitchOrganization)();
    const selectedKey = (_a = currentOrganization === null || currentOrganization === void 0 ? void 0 : currentOrganization.id) !== null && _a !== void 0 ? _a : -1;
    const title = currentOrganization && displayName(currentOrganization) ?
        displayName(currentOrganization) :
        sonar_ts_constants_1.ORGANIZATION_SWITCHER_COPY.PLACEHOLDER;
    const handleClick = (0, react_1.useCallback)((info) => {
        if (info.key.startsWith('/')) {
            navigate(info.key);
            return;
        }
        const organizationId = Number(info.key);
        if (!isNaN(organizationId) && organizationId !== selectedKey) {
            requestSwitch({ organizationId }, () => {
                const { pathname } = location;
                if (pathname.includes(sonar_ts_constants_1.BASE_ROUTES.PERISCOPE)) {
                    navigate(sonar_ts_constants_1.FULL_ROUTES.DASHBOARD);
                }
                else if (pathname.includes(`${sonar_ts_constants_1.BASE_ROUTES.DATA_DICTIONARY}`)) {
                    navigate(sonar_ts_constants_1.FULL_ROUTES.DATA_DICTIONARY);
                }
            });
        }
    }, [location, navigate, requestSwitch, selectedKey]);
    const menuItems = (0, react_1.useMemo)(() => buildMenuItems(organizations, isClosed, title, selectedKey), [isClosed, organizations, selectedKey, title]);
    return (react_1.default.createElement(menu_1.SonarMenu, { className: 'org-switcher-menu', id: 'org-switcher-menu', inlineIndent: 8, items: menuItems, mode: 'inline', onClick: handleClick, selectedKeys: ['-1'], theme: 'dark' }));
};
exports.OrganizationSwitcher = OrganizationSwitcher;
