"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkSalesforce = void 0;
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const antd_1 = require("antd");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
function getRedirectState() {
    const { subdomain } = new sonar_ts_lib_1.DeconstructedURL(window.location.href);
    return subdomain;
}
const LinkSalesforce = () => {
    const log = (0, _hooks_1.useAmplitude)();
    const { organizationId } = (0, _hooks_1.useAuthToken)();
    const redirectState = (0, react_1.useMemo)(getRedirectState, []);
    const { authRedirect } = (0, _hooks_1.useSalesforceAuth)(sonar_ts_constants_1.FULL_ROUTES.CONFIRM_LINK, redirectState);
    const handleClick = (0, react_1.useCallback)(() => {
        var _a;
        log(sonar_analytics_1.AMPLITUDE_EVENT.NEW_SALESFORCE_ORGANIZATION_CLICKED);
        localStorage.setItem(sonar_ts_types_1.LocalStorageKey.LastUsedOrg, (_a = organizationId === null || organizationId === void 0 ? void 0 : organizationId.toString()) !== null && _a !== void 0 ? _a : '');
        authRedirect();
    }, [authRedirect, log, organizationId]);
    return (react_1.default.createElement(antd_1.Button, { id: 'link-salesforce-org', onClick: handleClick, type: 'primary' }, sonar_ts_constants_1.SETTINGS_ORGANIZATION_COPY.LINK_NEW_SALESFORCE_ORGANIZATION));
};
exports.LinkSalesforce = LinkSalesforce;
