"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppNameInput = exports.defaultAppOptions = void 0;
require("./AppNameInput.scss");
const antd_1 = require("antd");
const filter_1 = require("@lib/filter");
const DataSecurityAppNames_1 = require("@ui/applications/DataSecurityAppNames");
const popups_1 = require("@lib/popups");
const events_1 = require("@lib/events");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
exports.defaultAppOptions = Object.values(DataSecurityAppNames_1.DataSecurityAppName)
    .map((value) => ({
    label: value,
    value
}));
exports.AppNameInput = (0, react_1.forwardRef)(({ defaultOpen, getPopupContainer, onChange, onClose, onOpen, onSelect, value }, ref) => {
    var _a;
    const [searchText, setSearchText] = (0, react_1.useState)('');
    const valueBackupRef = (0, react_1.useRef)(value);
    const [selectedValue, setSelectedValue] = (0, _hooks_1.useAsyncDefaultState)(value, (initalizedValue) => {
        valueBackupRef.current = initalizedValue;
    });
    const [optionMatch] = (0, react_1.useMemo)(() => exports.defaultAppOptions.filter((option) => { var _a; return ((_a = String(option.value)) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === (searchText === null || searchText === void 0 ? void 0 : searchText.toLowerCase()); }), [searchText]);
    const showCustomOption = Boolean(searchText) && !optionMatch;
    const options = (0, react_1.useMemo)(() => {
        const label = `Create New App "${searchText}"`;
        if (!showCustomOption) {
            return exports.defaultAppOptions;
        }
        return [
            ...exports.defaultAppOptions,
            {
                className: 'custom-app-option',
                label,
                value: searchText
            }
        ];
    }, [searchText, showCustomOption]);
    const handleOnChange = (0, react_1.useCallback)((input) => {
        setSearchText('');
        setSelectedValue(input);
        valueBackupRef.current = input;
        if (onChange) {
            onChange(input);
        }
    }, [onChange, setSelectedValue]);
    const handleOnSearch = (0, react_1.useCallback)((input) => {
        setSearchText(input);
    }, []);
    const handleDropdownChange = (0, react_1.useCallback)((open) => {
        if (open) {
            setSelectedValue('');
            if (onOpen) {
                onOpen();
            }
        }
        else if (!open && onClose) {
            onClose();
        }
    }, [onClose, onOpen, setSelectedValue]);
    const handleOnBlur = (0, react_1.useCallback)(() => {
        var _a;
        if (!selectedValue) {
            setSelectedValue((_a = valueBackupRef.current) !== null && _a !== void 0 ? _a : '');
        }
        if (onClose) {
            onClose();
        }
    }, [onClose, selectedValue, setSelectedValue]);
    return (react_1.default.createElement(antd_1.AutoComplete, { className: 'app-name-input', defaultActiveFirstOption: !showCustomOption, defaultOpen: defaultOpen, filterOption: filter_1.caseInsensitiveContainsFilterOption, getPopupContainer: getPopupContainer !== null && getPopupContainer !== void 0 ? getPopupContainer : popups_1.getDirectParent, onBlur: handleOnBlur, onChange: handleOnChange, onDropdownVisibleChange: handleDropdownChange, onFocus: () => setSelectedValue(''), onKeyUp: events_1.haltKeyboardEventPropagation, onSearch: handleOnSearch, onSelect: onSelect, options: options, placeholder: (_a = valueBackupRef.current) !== null && _a !== void 0 ? _a : sonar_ts_constants_1.PLACEHOLDERS_COPY.APP_NAME, popupClassName: 'app-name-input-dropdown', ref: ref, showAction: ['focus', 'click'], value: selectedValue }));
});
