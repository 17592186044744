"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmLinkRouter = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_http_1 = require("@sonar-software/sonar-http");
const general_1 = require("./general");
const react_router_dom_1 = require("react-router-dom");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const ConfirmLinkRouter = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const link = (0, _hooks_1.useSalesforceLink)();
    const { isLoggedIn } = (0, _hooks_1.useAuthToken)();
    const { progress, updateProgress } = (0, _hooks_1.useOnboardingProgress)();
    const [code, setCode] = (0, react_1.useState)('');
    const isCodeUsedRef = (0, react_1.useRef)(false);
    const isOnboardingRef = (0, react_1.useRef)(false);
    const refreshOrgs = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreOrganizationsAll);
    (0, _hooks_1.useOnMount)(() => {
        var _a, _b;
        const currentUrl = new sonar_ts_lib_1.DeconstructedURL(window.location.href);
        const { subdomain: currentSubdomain, searchParams } = currentUrl;
        const [targetSubdomain, onboarding] = ((_a = searchParams.get(sonar_ts_constants_1.QUERY.STATE)) !== null && _a !== void 0 ? _a : '').split(';');
        if (targetSubdomain && targetSubdomain !== currentSubdomain) {
            currentUrl.subdomain = targetSubdomain;
            window.location.replace(currentUrl.toString());
            return;
        }
        const salesforceCode = (_b = searchParams.get(sonar_ts_constants_1.QUERY.CODE)) !== null && _b !== void 0 ? _b : '';
        if (!isLoggedIn || !salesforceCode) {
            window.location.replace('/');
        }
        isOnboardingRef.current = Boolean(onboarding);
        setCode(salesforceCode);
    });
    (0, react_1.useEffect)(() => {
        if (!code || isCodeUsedRef.current) {
            return;
        }
        const isOnboarding = isOnboardingRef.current;
        if (isOnboarding && !progress) {
            return;
        }
        isCodeUsedRef.current = true;
        link({
            code,
            onResponse: (result, statusCode) => {
                const target = isOnboarding ? sonar_ts_constants_1.FULL_ROUTES.ONBOARDING_CONFIRM : sonar_ts_constants_1.FULL_ROUTES.ORGANIZATION_CONFIRM;
                if (result) {
                    refreshOrgs();
                    if (isOnboarding) {
                        updateProgress({
                            isSalesforceConnected: true,
                            isSalesforceSynced: false,
                            organizationId: result.id
                        });
                    }
                    else {
                        updateProgress({
                            isSalesforceConnected: true,
                            isSalesforceSynced: false
                        });
                    }
                    navigate(target, { replace: true });
                }
                else if (statusCode === sonar_http_1.ErrorOrFailStatusCode.Forbidden) {
                    updateProgress({
                        isSalesforceConnected: true,
                        isSalesforceSynced: false,
                        missingSalesforceSyncPermission: true
                    });
                    navigate(target, { replace: true });
                }
                else {
                    window.location.replace('/');
                }
            },
            organizationId: isOnboarding ? progress === null || progress === void 0 ? void 0 : progress.organizationId : undefined
        });
    }, [code, link, navigate, progress, refreshOrgs, updateProgress]);
    return react_1.default.createElement(general_1.GatewayContent, null);
};
exports.ConfirmLinkRouter = ConfirmLinkRouter;
