"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeCard = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./Index.scss");
const comments_1 = require("./comments");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const Description_1 = require("./Description");
const sidebar_1 = require("./sidebar");
const summary_1 = require("./summary");
const TitleBar_1 = require("./TitleBar");
const scopes_1 = require("./scopes");
const tasks_1 = require("./tasks");
const react_router_dom_1 = require("react-router-dom");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const _hooks_1 = require("@hooks");
const InitiativeCard = ({ initiativeId }) => {
    var _a, _b;
    const navigate = (0, react_router_dom_1.useNavigate)();
    const isErrored = (0, _hooks_1.useSonarSelector)(_hooks_1.useCurrentInitiative.selectors.isErrored);
    const isPeriscopeOpen = Boolean((0, _hooks_1.usePeriscopeCount)());
    const initiative = (0, _hooks_1.useCurrentInitiative)();
    const isLoading = (0, _hooks_1.useSonarSelector)(_hooks_1.useCurrentInitiative.selectors.isRequested) && !initiative;
    (0, react_1.useEffect)(() => {
        if (isErrored) {
            navigate(sonar_ts_constants_1.FULL_ROUTES.INITIATIVES, { replace: true });
        }
    }, [isErrored, navigate]);
    return (react_1.default.createElement(general_1.ScrollShadow, { scroller: '.ant-card-body', shadow: '.ant-card-head' },
        react_1.default.createElement(general_1.SonarCard, { loading: isLoading, title: react_1.default.createElement(TitleBar_1.InitiativeTitleBar, { initiativeId: initiativeId, title: initiative === null || initiative === void 0 ? void 0 : initiative.title }) },
            react_1.default.createElement(antd_1.Row, { gutter: Number(css.size32), justify: 'space-between', wrap: true },
                react_1.default.createElement(antd_1.Col, { span: isPeriscopeOpen ? 24 : 20 },
                    react_1.default.createElement(Description_1.InitiativeDescription, { description: initiative === null || initiative === void 0 ? void 0 : initiative.description, initiativeId: initiativeId }),
                    react_1.default.createElement(summary_1.InitiativeSummary, { initiativeId: initiativeId })),
                react_1.default.createElement(antd_1.Col, { span: isPeriscopeOpen ? 0 : 4 },
                    react_1.default.createElement(sidebar_1.InitiativeSidebar, { assignedTo: (_a = initiative === null || initiative === void 0 ? void 0 : initiative.assignedTo) !== null && _a !== void 0 ? _a : 0, createdBy: (_b = initiative === null || initiative === void 0 ? void 0 : initiative.createdBy) !== null && _b !== void 0 ? _b : 0, initiativeId: initiativeId, status: initiative === null || initiative === void 0 ? void 0 : initiative.initiativeStatusId }))),
            react_1.default.createElement(scopes_1.ScopeSection, { initiativeId: initiativeId }),
            react_1.default.createElement(tasks_1.TaskSection, { initiativeId: initiativeId }),
            react_1.default.createElement(comments_1.CommentSection, { initiativeId: initiativeId }))));
};
exports.InitiativeCard = InitiativeCard;
