"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerViewExtraDropdown = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const notifications_1 = require("@ui/notifications");
const selectors_1 = require("@lib/selectors");
const icons_1 = require("@ant-design/icons");
const modals_1 = require("@ui/modals");
const _hooks_1 = require("@hooks");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const reindexCustomerSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.search.shardCatalog.reindexCustomer.post);
const CustomerViewExtraDropdown = (props) => {
    const { customer, shardName } = props;
    const [form] = antd_1.Form.useForm();
    const [isRenameCustomerModalVisible, setIsRenameCustomerModalVisible] = (0, react_1.useState)(false);
    const lockAllCustomers = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestPostAdminCustomersLockAllUsers);
    const handleLockAllUsersClicked = (0, react_1.useCallback)(() => {
        if (customer) {
            lockAllCustomers({
                customerId: customer.id,
                shardName
            });
        }
    }, [lockAllCustomers, customer, shardName]);
    const patchCustomer = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreCustomersById);
    const handleLockCustomerClicked = (0, react_1.useCallback)(() => {
        if (customer) {
            patchCustomer({
                customerId: customer.id,
                isLocked: !customer.isLocked,
                shardName
            });
        }
    }, [patchCustomer, customer, shardName]);
    const handleRenameCustomerClicked = (0, react_1.useCallback)(() => {
        setIsRenameCustomerModalVisible(true);
    }, [setIsRenameCustomerModalVisible]);
    const handleCustomerRenamed = (0, react_1.useCallback)(() => {
        form.validateFields().then((values) => {
            patchCustomer({
                customerId: customer === null || customer === void 0 ? void 0 : customer.id,
                name: values.name,
                shardName
            });
            setIsRenameCustomerModalVisible(false);
        });
    }, [form, setIsRenameCustomerModalVisible, customer, shardName, patchCustomer]);
    const handleCustomerRenameCanceled = (0, react_1.useCallback)(() => {
        setIsRenameCustomerModalVisible(false);
    }, [setIsRenameCustomerModalVisible]);
    const reindexCustomer = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestPostAdminSearchShardCatalogReindexCustomer);
    const handleReindexInShardCatalogClicked = (0, react_1.useCallback)(() => {
        if (customer) {
            reindexCustomer({
                customerId: customer.id,
                shardName
            });
        }
    }, [reindexCustomer, customer, shardName]);
    (0, react_1.useEffect)(() => {
        if (isRenameCustomerModalVisible) {
            form.resetFields();
        }
    }, [form, isRenameCustomerModalVisible]);
    const menuProps = {
        items: [
            {
                key: '1',
                label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.LOCK_ALL_USERS,
                onClick: handleLockAllUsersClicked
            },
            {
                key: '2',
                label: (customer === null || customer === void 0 ? void 0 : customer.isLocked) ? sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.UNLOCK_CUSTOMER : sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.LOCK_CUSTOMER,
                onClick: handleLockCustomerClicked
            },
            {
                key: '3',
                label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.RENAME_CUSTOMER,
                onClick: handleRenameCustomerClicked
            },
            {
                key: '4',
                label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.REINDEX_IN_SHARD_CATALOG,
                onClick: handleReindexInShardCatalogClicked
            }
        ]
    };
    return (react_1.default.createElement("span", null,
        react_1.default.createElement(antd_1.Dropdown, { menu: menuProps, trigger: ['click'] },
            react_1.default.createElement(antd_1.Button, { className: 'customer-view-extra-dropdown-btn' },
                react_1.default.createElement(icons_1.MoreOutlined, null))),
        react_1.default.createElement(modals_1.SonarModal, { onCancel: handleCustomerRenameCanceled, onOk: handleCustomerRenamed, open: isRenameCustomerModalVisible, title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.RENAME_CUSTOMER },
            react_1.default.createElement(antd_1.Form, { className: 'rename-customer-form inline-form-with-overhead-labels', colon: false, form: form, initialValues: {
                    name: customer === null || customer === void 0 ? void 0 : customer.name
                }, layout: 'inline' },
                react_1.default.createElement(antd_1.Form.Item, { label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.NEW_CUSTOMER_NAME, name: 'name', rules: [
                        {
                            message: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.CUSTOMER_NAME_REQUIRED,
                            required: true
                        }
                    ] },
                    react_1.default.createElement(antd_1.Input, null)))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.REINDEX_CUSTOMER_FAILED, failed: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.REINDEX_CUSTOMER_FAILED, requested: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.REINDEX_CUSTOMER_REQUESTED, stateSelector: reindexCustomerSelectors.state })));
};
exports.CustomerViewExtraDropdown = CustomerViewExtraDropdown;
