"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorsTable = void 0;
require("./MonitorsTable.scss");
const applications_1 = require("@ui/applications");
const notifications_1 = require("@ui/notifications");
const MonitorModal_1 = require("./MonitorModal");
const MonitorsTableColumns_1 = require("./MonitorsTableColumns");
const RecommendedActionTemplates_1 = require("./RecommendedActionTemplates");
const text_1 = require("@ui/text");
const SortedBy_1 = require("@ui/table-modifier/SortedBy");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const general_1 = require("@ui/general");
const MonitorMenu_1 = require("./monitor-form/MonitorMenu");
const MonitorTypeTag_1 = require("./MonitorTypeTag");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getRowKey = (result) => result.id;
const sortOptions = [
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'name',
        label: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'monitorTypeId',
        label: sonar_ts_constants_1.COPY_SPECIFIC.TYPE
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'applicationId',
        label: sonar_ts_constants_1.COPY_SPECIFIC.APP
    }
];
const dataSecurityMonitorsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.dataSecurity.monitors.get);
const postMonitorSelectors = (0, selectors_1.createSonarSelector)((state) => state.core.dataSecurity.monitors.post);
const deleteMonitorSelectors = (0, selectors_1.createSonarSelector)((state) => state.core.dataSecurity.monitors.byId.delete);
const subscribeMonitorSelectors = (0, selectors_1.createSonarSelector)((state) => state.core.dataSecurity.monitors.byId.subscribe);
const muteMonitorSelectors = (0, selectors_1.createSonarSelector)((state) => state.core.dataSecurity.monitors.byId.mute);
const monitorTypeOptions = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.DataSecurityMonitorType)
    .filter((type) => MonitorTypeTag_1.monitorParentType[type] === undefined ||
    MonitorTypeTag_1.monitorParentType[type] === type)
    .map((value) => ({
    label: react_1.default.createElement(MonitorTypeTag_1.MonitorTypeTag, { monitorTypeId: value }),
    value
}));
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: {
        applicationId: true,
        deleted: true,
        name: { operator: sonar_ts_types_1.DynamicQueryOperator.Like },
        typeIds: {
            name: 'monitorTypeId',
            operator: sonar_ts_types_1.DynamicQueryOperator.In
        }
    }
});
function getTemplateDetails(templateId) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield (0, sonar_core_1.getRecommendedActionTemplateById)({
            templateId
        });
        return result.data;
    });
}
;
const MonitorsTable = () => {
    const { id, isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const stakeholders = (0, _hooks_1.useStakeholders)();
    const muteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchMuteCoreDataSecurityMonitorsById);
    const subscribeMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostSubscribeCoreDataSecurityMonitorsById);
    const getDataSecurityMonitors = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataSecurityMonitors);
    const deleteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreDataSecurityMonitorsById);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(dataSecurityMonitorsSelectors);
    const [showModal, setShowModal] = (0, _hooks_1.useBoolean)(false);
    const [showPopover, setShowPopover] = (0, _hooks_1.useBoolean)(false);
    const [editMonitor, setEditMonitor] = (0, react_1.useState)(undefined);
    const { isEnabled } = (0, _hooks_1.useFeatureFlags)();
    const userStakeholderId = (0, react_1.useMemo)(() => { var _a; return (_a = stakeholders.find((stakeholder) => stakeholder.userId === id)) === null || _a === void 0 ? void 0 : _a.stakeholderId; }, [id, stakeholders]);
    const menuItemsCount = (0, react_1.useMemo)(() => { var _a, _b; return ((data === null || data === void 0 ? void 0 : data.results) ? (_b = (0, MonitorMenu_1.getMenuItems)((_a = data === null || data === void 0 ? void 0 : data.results) !== null && _a !== void 0 ? _a : [], isEnabled)) === null || _b === void 0 ? void 0 : _b.length : 0); }, [data === null || data === void 0 ? void 0 : data.results, isEnabled]);
    const columns = (0, react_1.useMemo)(() => (0, MonitorsTableColumns_1.getColumns)(isReadOnly, Number(userStakeholderId), (mute, monitorId) => muteMonitor({
        id: monitorId,
        isMuted: mute
    }), (subscribe, monitorId) => subscribeMonitor({
        id: monitorId,
        subscribe: subscribe
    })), [isReadOnly, muteMonitor, subscribeMonitor, userStakeholderId]);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            deleted: false,
            sortBy: sortOptions[1].key,
            sortDirection: sortOptions[0].direction
        },
        onUpdate: (newParams, prev, combined) => {
            var _a;
            const { key, paginationDirection } = newParams;
            const params = convertToDql(Object.assign(Object.assign({}, combined), { key,
                paginationDirection }));
            if ((_a = params.ordering) === null || _a === void 0 ? void 0 : _a.includes('MonitorTypeId')) {
                const [, direction] = params.ordering.split('~');
                params.ordering = `Configurable~${direction} ${params.ordering}`;
            }
            getDataSecurityMonitors(params);
        }
    });
    (0, _hooks_1.useOnMount)(() => {
        updateParams({});
    });
    const handleSortUpdate = (0, react_1.useCallback)((update) => {
        updateParams(update);
    }, [updateParams]);
    const handleNameChange = (0, react_1.useCallback)((name) => {
        updateParams({ name });
    }, [updateParams]);
    const handleTypeSelect = (0, react_1.useCallback)((typeId) => {
        if (typeId === undefined) {
            updateParams({ typeIds: [] });
            return;
        }
        const childTypes = Object.keys(Object.fromEntries(Object.entries(MonitorTypeTag_1.monitorParentType).filter(([, value]) => value === typeId)));
        const typeIds = childTypes.length === 0 ?
            [typeId] :
            childTypes.map(Number);
        updateParams({ typeIds });
    }, [updateParams]);
    const handleAppSelect = (0, react_1.useCallback)((applicationId) => {
        updateParams({ applicationId });
    }, [updateParams]);
    const handleDeleteMonitor = (0, react_1.useCallback)((monitor) => {
        deleteMonitor({ monitorId: monitor.id });
    }, [deleteMonitor]);
    const handleEditMonitor = (0, react_1.useCallback)((monitor) => {
        setEditMonitor(monitor);
        setShowModal.on();
    }, [setShowModal]);
    const handleOpenModal = (0, react_1.useCallback)(() => {
        setEditMonitor(undefined);
        setShowModal.on();
    }, [setShowModal]);
    const handleOnTemplateChange = (0, react_1.useCallback)((templateId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const templateDetails = yield getTemplateDetails(templateId);
        if (!templateDetails) {
            return;
        }
        const newMonitor = {
            applicationIds: (_a = templateDetails.integratedApplicationIds) !== null && _a !== void 0 ? _a : [],
            monitorConfig: Object.assign({}, templateDetails.configuration),
            overrideCount: templateDetails.overrideCount,
            templateId
        };
        setEditMonitor(newMonitor);
        setShowModal.on();
    }), [setShowModal]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(general_1.SonarCard, { className: 'monitors-table-card', extra: react_1.default.createElement(general_1.FullUserBoundary, null,
                react_1.default.createElement(antd_1.Popover, { content: react_1.default.createElement(antd_1.Space, { className: 'upsell-popover', direction: 'vertical', wrap: true },
                        react_1.default.createElement(text_1.SonarText, { weight: 'semibold' }, sonar_ts_constants_1.COPY_SPECIFIC.CREATE_MONITOR_UPSELL_TITLE),
                        react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.CREATE_MONITOR_UPSELL_BODY)), onOpenChange: (value) => {
                        if (menuItemsCount === 0 && value) {
                            setShowPopover.on();
                        }
                        else {
                            setShowPopover.off();
                        }
                    }, open: showPopover, placement: 'bottom', rootClassName: 'monitor-upsell-popover' },
                    react_1.default.createElement(antd_1.Button, { disabled: menuItemsCount === 0, onClick: handleOpenModal, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.CREATE_MONITOR))), noSpacing: true, title: sonar_ts_constants_1.COPY_SPECIFIC.MONITORS },
            react_1.default.createElement(RecommendedActionTemplates_1.RecommendedActionTemplates, { onClick: handleOnTemplateChange }),
            react_1.default.createElement(antd_1.Flex, { align: 'end', className: 'filter-section', justify: 'space-between' },
                react_1.default.createElement("div", { className: 'table-filters' },
                    react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                        react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.NAME),
                        react_1.default.createElement(general_1.SearchInput, { className: 'monitor-name-input', onChange: handleNameChange, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_MONITORS })),
                    react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                        react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.TYPE),
                        react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'type-select', onChange: handleTypeSelect, options: monitorTypeOptions, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(sonar_ts_constants_1.COPY_SPECIFIC.TYPE), showSearch: true })),
                    react_1.default.createElement(antd_1.Space, { direction: 'vertical' },
                        react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.APP),
                        react_1.default.createElement(applications_1.AppSelect, { nameAndLabel: true, onChange: handleAppSelect, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(sonar_ts_constants_1.COPY_SPECIFIC.APP) }))),
                react_1.default.createElement(SortedBy_1.SortedBySelect, { defaultValue: sortOptions[0], onChange: handleSortUpdate, queryParams: queryParams, sortOptions: sortOptions })),
            react_1.default.createElement(PaginatedTable_1.PaginatedTable, { canDeleteRow: (record) => {
                    var _a;
                    return Boolean(MonitorMenu_1.monitorMenuConfigs.find((config) => { var _a; return config.type === ((_a = record.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId); })) && !((_a = MonitorMenu_1.monitorMenuConfigs.find((config) => { var _a; return config.type === ((_a = record.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId); })) === null || _a === void 0 ? void 0 : _a.intrinsic);
                }, canEditRow: (record) => Boolean(MonitorMenu_1.monitorMenuConfigs.find((config) => { var _a; return config.type === ((_a = record.monitorConfig) === null || _a === void 0 ? void 0 : _a.monitorTypeId); })), columns: columns, id: 'monitors-table', loading: isRequested, onChange: updateParams, onDelete: handleDeleteMonitor, onEdit: handleEditMonitor, resource: data, rowKey: getRowKey, scroll: {
                    scrollToFirstRowOnChange: true,
                    x: 'max-content'
                }, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection, sticky: true }),
            react_1.default.createElement(MonitorModal_1.CreateMonitorModal, { monitor: editMonitor, onCancel: setShowModal.off, onChange: setEditMonitor, onSave: setShowModal.off, open: showModal })),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.SETTINGS_DATA_SECURITY_COPY.MONITOR_SAVED, resetRawAction: sonar_core_1.coreActions.resetPostCoreDataSecurityMonitors, stateSelector: postMonitorSelectors }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.SETTINGS_DATA_SECURITY_COPY.MONITOR_DELETED, resetRawAction: sonar_core_1.coreActions.resetDeleteCoreDataSecurityMonitorsById, stateSelector: deleteMonitorSelectors }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.SETTINGS_DATA_SECURITY_COPY.MONITOR_SAVED, resetRawAction: sonar_core_1.coreActions.resetPostSubscribeCoreDataSecurityMonitorsById, stateSelector: subscribeMonitorSelectors }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.SETTINGS_DATA_SECURITY_COPY.MONITOR_SAVED, resetRawAction: sonar_core_1.coreActions.resetPatchMuteCoreDataSecurityMonitorsById, stateSelector: muteMonitorSelectors })));
};
exports.MonitorsTable = MonitorsTable;
