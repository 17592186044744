"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerSettingsManager = void 0;
require("./CustomerSettingsManager.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const patchCustomerSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.customers.byId.patch);
const CustomerSettingsManager = (props) => {
    const { id, customer, searchDetails } = props;
    const formRefSalesforceAccountId = (0, react_1.useRef)(null);
    const formRefTimelineRetentionPeriod = (0, react_1.useRef)(null);
    const patchCustomer = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreCustomersById);
    const isPatchingCustomer = (0, _hooks_1.useSonarSelector)(patchCustomerSelectors.isRequested);
    const handleSubmitSalesforceAccountId = (0, react_1.useCallback)((values) => {
        var _a;
        if (searchDetails) {
            const { customerId, shardName } = searchDetails;
            patchCustomer({
                customerId: customerId,
                salesforceAccountId: (_a = values.salesforceAccountId) !== null && _a !== void 0 ? _a : null,
                shardName: shardName
            });
        }
    }, [patchCustomer, searchDetails]);
    const handleSubmitTimelineRetentionPeriod = (0, react_1.useCallback)((values) => {
        var _a;
        if (searchDetails) {
            const { customerId, shardName } = searchDetails;
            patchCustomer({
                customerId: customerId,
                shardName: shardName,
                timelineRetentionPeriod: (_a = values.timelineRetentionPeriod) !== null && _a !== void 0 ? _a : null
            });
        }
    }, [patchCustomer, searchDetails]);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        if (customer) {
            (_a = formRefSalesforceAccountId.current) === null || _a === void 0 ? void 0 : _a.resetFields();
            (_b = formRefTimelineRetentionPeriod.current) === null || _b === void 0 ? void 0 : _b.resetFields();
        }
    }, [customer]);
    return (react_1.default.createElement("div", { className: 'customer-settings-manager', id: id },
        react_1.default.createElement("div", { className: 'h2' }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.CUSTOMER_SETTINGS),
        react_1.default.createElement(antd_1.Form, { className: 'inline-form-with-overhead-labels', colon: false, initialValues: { salesforceAccountId: customer === null || customer === void 0 ? void 0 : customer.salesforceAccountId }, layout: 'inline', onFinish: handleSubmitSalesforceAccountId, ref: formRefSalesforceAccountId },
            react_1.default.createElement(antd_1.Form.Item, { label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.SALESFORCE_ACCOUNT_ID, name: 'salesforceAccountId', rules: [
                    {
                        message: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.INVALID_SALESFORCE_ACCOUNT_ID,
                        pattern: /(^[a-zA-Z0-9]{15}$)|(^[a-zA-Z0-9]{18}$)/u,
                        type: 'string'
                    }
                ] },
                react_1.default.createElement(antd_1.Input, null)),
            react_1.default.createElement(antd_1.Button, { htmlType: 'submit', loading: isPatchingCustomer, type: 'primary' }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.SUBMIT)),
        react_1.default.createElement(antd_1.Form, { className: 'inline-form-with-overhead-labels', colon: false, initialValues: { timelineRetentionPeriod: customer === null || customer === void 0 ? void 0 : customer.timelineRetentionPeriod }, layout: 'inline', onFinish: handleSubmitTimelineRetentionPeriod, ref: formRefTimelineRetentionPeriod },
            react_1.default.createElement(antd_1.Form.Item, { label: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.TIMELINE_RETENTION_PERIOD, name: 'timelineRetentionPeriod' },
                react_1.default.createElement(antd_1.InputNumber, { max: 1825, min: 0 })),
            react_1.default.createElement(antd_1.Button, { htmlType: 'submit', loading: isPatchingCustomer, type: 'primary' }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.SUBMIT))));
};
exports.CustomerSettingsManager = CustomerSettingsManager;
