"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageTagSwitch = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const PackageTagSwitch = ({ disabled, organizationId, packageTagEnabled }) => {
    const [sentPackageTagPatchRequest, setSentPackageTagPatchRequest] = (0, react_1.useState)(false);
    const [open, setOpen] = (0, _hooks_1.useBoolean)(false);
    const { isErrored, isFailed, request, reset } = (0, _hooks_1.useUpdateOrganization)();
    (0, react_1.useEffect)(() => {
        if (sentPackageTagPatchRequest && (isErrored || isFailed)) {
            setSentPackageTagPatchRequest(false);
            reset();
        }
    }, [isErrored, isFailed, reset, sentPackageTagPatchRequest]);
    const sendRequest = (0, react_1.useCallback)(() => {
        setSentPackageTagPatchRequest(true);
        request({
            organizationId,
            packageTagEnabled: !packageTagEnabled
        });
        setOpen.off();
    }, [organizationId, packageTagEnabled, request, setOpen]);
    const handleToggleClick = (0, react_1.useCallback)(() => {
        if (packageTagEnabled) {
            setOpen.on();
        }
        else {
            sendRequest();
        }
    }, [packageTagEnabled, sendRequest, setOpen]);
    return (react_1.default.createElement(antd_1.Popconfirm, { cancelText: sonar_ts_constants_1.COPY_SPECIFIC.NO, okText: sonar_ts_constants_1.COPY_SPECIFIC.YES, onCancel: setOpen.off, onConfirm: sendRequest, open: open, title: sonar_ts_constants_1.COPY_SPECIFIC.ARE_YOU_SURE_REMOVE_PACKAGE_TAGGING },
        react_1.default.createElement(antd_1.Switch, { checked: packageTagEnabled, disabled: disabled, onClick: handleToggleClick })));
};
exports.PackageTagSwitch = PackageTagSwitch;
