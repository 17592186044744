"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeAutomation = void 0;
const apex_classes_1 = require("../apex-classes");
const apex_triggers_1 = require("../apex-triggers");
const field_updates_1 = require("../field-updates");
const flows_1 = require("../flows");
const formula_fields_1 = require("../formula-fields");
const page_layouts_1 = require("../page-layouts");
const process_builders_1 = require("../process-builders");
const validation_rules_1 = require("../validation-rules");
const workflow_rules_1 = require("../workflow-rules");
const records_1 = require("@ui/records");
const _hooks_1 = require("@hooks");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const renderAutomation = (automation, parentIndex, onClick, showType) => {
    const { sonarObjectType } = automation, periscope = __rest(automation, ["sonarObjectType"]);
    switch (sonarObjectType) {
        case sonar_ts_types_1.SonarObjectType.SalesforceApexClass:
            return (react_1.default.createElement(apex_classes_1.PeriscopeApexClass, { apex: periscope, key: periscope.sonarObjectId, onApexClick: onClick, showType: showType, sonarObjectType: sonarObjectType }));
        case sonar_ts_types_1.SonarObjectType.SalesforceApexTrigger:
            return (react_1.default.createElement(apex_triggers_1.PeriscopeApexTrigger, { apex: periscope, key: periscope.sonarObjectId, onApexClick: onClick, showType: showType, sonarObjectType: sonarObjectType }));
        case sonar_ts_types_1.SonarObjectType.SalesforceField:
            return (react_1.default.createElement(formula_fields_1.PeriscopeFormulaField, { formulaField: periscope, key: periscope.sonarObjectId, onFormulaFieldClick: onClick, showType: showType }));
        case sonar_ts_types_1.SonarObjectType.SalesforceFieldUpdate:
            return (react_1.default.createElement(field_updates_1.PeriscopeFieldUpdate, { key: periscope.sonarObjectId, onClick: onClick, record: periscope, showType: showType }));
        case sonar_ts_types_1.SonarObjectType.SalesforceFlow:
            return (react_1.default.createElement(flows_1.PeriscopeFlow, { flow: periscope, key: periscope.sonarObjectId, onFlowClick: onClick, showType: showType }));
        case sonar_ts_types_1.SonarObjectType.SalesforcePageLayout:
            return (react_1.default.createElement(page_layouts_1.PeriscopePageLayout, { item: periscope, key: periscope.sonarObjectId, onClick: onClick, showType: showType }));
        case sonar_ts_types_1.SonarObjectType.SalesforceProcessBuilder:
            return (react_1.default.createElement(process_builders_1.PeriscopeProcessBuilder, { builder: periscope, key: periscope.sonarObjectId, onBuilderClick: onClick, showType: showType }));
        case sonar_ts_types_1.SonarObjectType.SalesforceValidationRule:
            return (react_1.default.createElement(validation_rules_1.PeriscopeValidationRule, { index: parentIndex, key: periscope.sonarObjectId, onRuleClick: onClick, rule: periscope, showType: showType }));
        case sonar_ts_types_1.SonarObjectType.SalesforceWorkflowRule:
            return (react_1.default.createElement(workflow_rules_1.PeriscopeWorkflowRule, { index: parentIndex, key: periscope.sonarObjectId, onRuleClick: onClick, rule: periscope, showType: showType }));
        default:
            return (react_1.default.createElement(records_1.SonarRecord, { isActive: periscope.isActive, key: periscope.sonarObjectId, name: periscope.displayName, onClick: () => onClick(periscope), showType: showType, sonarObjectId: periscope.sonarObjectId, sonarObjectType: sonarObjectType }));
    }
};
const PeriscopeAutomation = ({ automation, parentIndex, showType }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const handleAutomationClick = (0, react_1.useCallback)((sonarObjectType) => (clickedAutomation) => {
        const { displayName, sonarObjectId } = clickedAutomation;
        openPeriscope({
            id: sonarObjectId,
            name: displayName,
            openedFromIndex: parentIndex,
            type: sonarObjectType
        });
    }, [openPeriscope, parentIndex]);
    const { sonarObjectType } = automation;
    const onClick = handleAutomationClick(sonarObjectType);
    return renderAutomation(automation, parentIndex, onClick, showType);
};
exports.PeriscopeAutomation = PeriscopeAutomation;
