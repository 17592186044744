"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeDescription = void 0;
require("./Description.scss");
const Media_1 = require("./Media");
const general_1 = require("./general");
const JiraSummary_1 = require("./jira/JiraSummary");
const editor_1 = require("@periscopes/general/editor");
const react_if_1 = require("react-if");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const InitiativeDescription = ({ description, initiativeId }) => {
    const patchInitiative = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiativesById);
    const jiraEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.JiraIntegration);
    const handleOnSave = (0, react_1.useCallback)((updatedDescription) => {
        patchInitiative({
            initiativeId: initiativeId,
            patchInitiativeDescription: {
                description: updatedDescription
            }
        });
    }, [initiativeId, patchInitiative]);
    return (react_1.default.createElement(general_1.InitiativeSection, { id: 'initiative-description', title: sonar_ts_constants_1.COPY_SPECIFIC.DESCRIPTION },
        react_1.default.createElement(editor_1.TextEditor, { canBeEmpty: true, content: description !== null && description !== void 0 ? description : '', onSave: handleOnSave, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.ADD_DESCRIPTION, saveOnBlur: true }),
        react_1.default.createElement(Media_1.InitiativeMedia, { initiativeId: initiativeId }),
        react_1.default.createElement(react_if_1.When, { condition: jiraEnabled },
            react_1.default.createElement(JiraSummary_1.JiraSummary, { initiativeId: initiativeId }))));
};
exports.InitiativeDescription = InitiativeDescription;
