"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePeriscopeSummary = usePeriscopeSummary;
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const useAction_1 = require("../useAction");
const useApiResource_1 = require("../useApiResource");
const useTagReference_1 = require("../tags/useTagReference");
const useUpdateSonarObjectReference_1 = require("../references/useUpdateSonarObjectReference");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = require("react");
function usePeriscopeSummary(sonarObjectId, sonarObjectType) {
    const tag = (0, useTagReference_1.useTagReference)(sonarObjectId !== null && sonarObjectId !== void 0 ? sonarObjectId : 0);
    const loadApplications = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestGetCoreSonarObjectsApplications);
    const isTag = sonar_ts_types_1.SonarObjectType.SonarTag === sonarObjectType;
    const targetUri = (0, react_1.useMemo)(() => (0, sonar_ts_lib_1.getPeriscopeSummaryRouteUri)(sonarObjectType, sonarObjectId), [sonarObjectId, sonarObjectType]);
    const { loading, resource } = (0, useApiResource_1.useApiResource)(targetUri);
    const { update } = (0, useUpdateSonarObjectReference_1.useUpdateSonarObjectReference)();
    (0, react_1.useEffect)(() => {
        if (sonarObjectId) {
            loadApplications({ sonarObjectId });
        }
    }, [loadApplications, sonarObjectId]);
    (0, react_1.useEffect)(() => {
        if (resource && sonarObjectId) {
            update({
                displayName: resource.displayName,
                hasFieldSpyMetadata: resource.hasFieldSpyMetadata,
                internalName: resource.internalName,
                isActive: resource.isActive,
                parentDisplayName: resource.parentDisplayName,
                parentSonarObjectId: resource.parentSonarObjectId,
                relatedCounts: resource.relatedCounts,
                salesforce: {
                    description: resource.description,
                    helpText: resource.helpText,
                    isCustom: resource.isCustom,
                    managedPackage: resource.managedPackage
                },
                sonarObjectId,
                sonarObjectType: sonarObjectType,
                tags: resource.tagIds,
                versionNumber: resource.versionNumber
            });
            if (resource.parentSonarObjectId) {
                update({
                    displayName: resource.parentDisplayName,
                    sonarObjectId: resource.parentSonarObjectId
                });
            }
        }
    }, [resource, sonarObjectId, sonarObjectType, update]);
    if (resource && !resource.sonarObjectId && sonarObjectId) {
        resource.sonarObjectId = sonarObjectId;
    }
    if (resource && !(resource === null || resource === void 0 ? void 0 : resource.sonarObjectType) && sonarObjectType) {
        resource.sonarObjectType = sonarObjectType;
    }
    const managedResource = (0, react_1.useMemo)(() => {
        if (isTag) {
            return {
                displayName: tag.name,
                sonarObjectId: sonarObjectId,
                sonarObjectType: sonar_ts_types_1.SonarObjectType.SonarTag
            };
        }
        return resource;
    }, [isTag, resource, sonarObjectId, tag.name]);
    return {
        loading: isTag ? false : loading,
        summary: managedResource
    };
}
