"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFilterComponent = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const filters_1 = require("../filters");
const formatFilterMap = {
    [sonar_core_1.SchemaFormat.Apps]: filters_1.ApplicationFilter,
    [sonar_core_1.SchemaFormat.Checkmark]: filters_1.ActiveFilter,
    [sonar_core_1.SchemaFormat.DateTime]: filters_1.DateFilter,
    [sonar_core_1.SchemaFormat.Description]: filters_1.Populated,
    [sonar_core_1.SchemaFormat.HelpText]: filters_1.Populated,
    [sonar_core_1.SchemaFormat.Object]: filters_1.ObjectFilter,
    [sonar_core_1.SchemaFormat.Owner]: filters_1.OwnerFilter,
    [sonar_core_1.SchemaFormat.PageLayouts]: filters_1.PageLayoutFilter,
    [sonar_core_1.SchemaFormat.PopulationPercentage]: filters_1.PopulationPercentageFilter,
    [sonar_core_1.SchemaFormat.Primary]: filters_1.TextFilter,
    [sonar_core_1.SchemaFormat.Profiles]: filters_1.ProfileFilter,
    [sonar_core_1.SchemaFormat.ReferenceCount]: filters_1.ReferenceCountFilter,
    [sonar_core_1.SchemaFormat.SalesforceUser]: filters_1.SalesforceUserFilter,
    [sonar_core_1.SchemaFormat.Stakeholder]: filters_1.StakeholderFilter,
    [sonar_core_1.SchemaFormat.Tags]: filters_1.TagsFilter,
    [sonar_core_1.SchemaFormat.Text]: filters_1.TextFilter
};
const enumFilterMap = {
    ComplianceCategory: filters_1.ComplianceCategoryFilter,
    DataSensitivityLevelId: filters_1.SensitivityLevelFilter,
    FieldUsageId: filters_1.FieldUsageFilter,
    Status: filters_1.StatusFilter,
    Type: filters_1.FieldTypeFilter
};
const getFilterComponent = (filter) => {
    var _a, _b;
    if (filter.filterFormat === sonar_core_1.SchemaFormat.Enum) {
        return (_a = enumFilterMap[filter.filterId]) !== null && _a !== void 0 ? _a : null;
    }
    if (filter.filterFormat === sonar_core_1.SchemaFormat.Primary && filter.filterMetadata) {
        return filters_1.PrimaryFilter;
    }
    return (_b = formatFilterMap[filter.filterFormat]) !== null && _b !== void 0 ? _b : null;
};
exports.getFilterComponent = getFilterComponent;
